import React, { useCallback, useContext, useMemo } from 'react';
import { MapperStepProps } from '../../../MapperStep';
import styles from './PagedMapperElement.module.css';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MapperElementComponentProps } from '../MapperElementComponent';
import FormContext from '../../../../../Contexts/FormContext';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { MapperElement } from '../../../../../@Types/MapperElement';
import { MobileStepper, makeStyles } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import CustomBtn from '../../../../../Shared/CustomBtn/CustomBtn';
import { FormStyle } from '../../../../../@Types/Form';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useFormContext } from 'react-hook-form';
import { updateNestedValues } from '../../../../../States/SiteSlice';

function PagedMapperElement<Type = any>({
    step,
    num,
    loading,
    element,
    children,
    editable,
    onChange,
    value,
    handleDelete,
    customElementModifiers,
    customElementRender,
}: MapperStepProps<Type> & MapperElementComponentProps<Type>): JSX.Element {
    const deletable = step.deletable ?? true;
    const { size } = useContext(FormContext);
    const { elements, page } = value;
    const dispatch = useAppDispatch();
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const totalElements = elements.filter((elem) => !elem.deleted);
    const position = totalElements.findIndex((e) => e.id == element.id);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const pagingRef = React.useRef<HTMLDivElement>(null);
    const { getValues, trigger, handleSubmit } = useFormContext();
    const nextIndex = useMemo(() => {
        for (let i = page + 1; i < elements.length; i++) {
            const element = elements[i];
            if (!element.deleted) {
                return i;
            }
        }
        return null;
    }, [value]);

    const prevIndex = useMemo(() => {
        for (let i = page - 1; i >= 0; i--) {
            const element = elements[i];
            if (!element.deleted) {
                return i;
            }
        }
        return null;
    }, [value]);

    /** Esto se usa para no tener que escuchar todo el tiempo los errores del formulario */
    const handleErrors = handleSubmit(
        () => {},
        (errors) => {
            console.error('Errors:', errors);
        }
    );

    const handleChangePage = useCallback(
        async (index: number) => {
            const valid = await trigger(undefined, { shouldFocus: true });
            /** Imprimir errores si no es valido */
            if (!valid) {
                handleErrors();
                return;
            }
            const values = getValues();
            const nestedValues: Record<string, any> = {};
            for (const [key, value] of Object.entries(values)) {
                if (key.startsWith(step.id + '-' + element.id)) {
                    nestedValues[key] = value;
                }
            }
            dispatch(
                updateNestedValues({
                    key: step.id + '-' + element.id,
                    values: nestedValues,
                })
            );
            containerRef.current?.scrollIntoView({ behavior: 'smooth' });
            //Updated the nestedValues in the values store.
            onChange({
                elements,
                page: index,
            });

            return;
        },
        [elements]
    );

    const widthStats = useAppSelector((state) => state.widthStats);
    const { calcName } = customElementModifiers ?? {};
    const name = calcName
        ? calcName(element)
        : step.unitLabel
          ? step.unitLabel + ' ' + (num + 1)
          : '';
    const renderElement = (
        renderElementButtons?: (element: MapperElement<any>) => JSX.Element
    ): JSX.Element => {
        return (
            <div
                className={
                    widthStats.isMobile
                        ? styles.mobileContainer
                        : styles.container
                }
                ref={containerRef}
            >
                <div
                    className={styles.contentContainer}
                    style={{
                        width:
                            (size.blockSize + size.spacingSize) *
                            (step.style?.size ?? size.blockNum - 1),
                    }}
                >
                    {name && (
                        <div
                            className={styles.titleLbl}
                            style={{ color: formStyle.textColor }}
                        >
                            {loading ? 'Cargando...' : name + ':'}
                        </div>
                    )}
                    <div className={styles.btnsContainer}>
                        {renderElementButtons?.(element)}
                        {(deletable || !element.isOriginal) && (
                            <div
                                className={
                                    !editable || postview
                                        ? styles.disabledDeleteBtn
                                        : styles.deleteBtn
                                }
                                style={{ color: formStyle.textColor }}
                                onClick={(): void => {
                                    if (editable && !postview) {
                                        handleDelete?.();
                                    }
                                }}
                            >
                                <DeleteRoundedIcon fontSize="inherit" />
                            </div>
                        )}
                    </div>
                    {children}
                    {totalElements.length > 0 && (
                        <div className={styles.pagingContainer} ref={pagingRef}>
                            <CustomStepper
                                totalElements={totalElements.length}
                                position={position}
                                handleNext={
                                    nextIndex !== null
                                        ? (): Promise<void> =>
                                              handleChangePage(nextIndex)
                                        : undefined
                                }
                                handlePrev={
                                    prevIndex !== null
                                        ? (): Promise<void> =>
                                              handleChangePage(prevIndex)
                                        : undefined
                                }
                                width={pagingRef.current?.clientWidth}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerContainer}>
            {customElementRender
                ? customElementRender(element, num, renderElement)
                : renderElement()}
        </div>
    );
}

export default PagedMapperElement;

interface CustomStepperProps {
    totalElements: number;
    position: number;
    handleNext?: () => void;
    handlePrev?: () => void;
    width?: number;
}

const useStepperStyles = (props: FormStyle): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            backgroundColor: 'transparent',
            padding: '10px',
            '& .EF-MuiMobileStepper-dot': {
                width: 11,
                height: 11,
                backgroundColor: props.outlineColor,
            },
            '& .EF-MuiMobileStepper-dotActive': {
                backgroundColor: props.primaryColor,
            },
            '& .EF-MuiLinearProgress-root': {
                height: 8,
                backgroundColor: props.outlineColor,
                borderRadius: 4,
                margin: '0px 8px',
            },
            '& .EF-MuiLinearProgress-barColorPrimary': {
                backgroundColor: props.primaryColor,
            },
        },
    }));

function CustomStepper({
    position,
    handleNext,
    totalElements,
    width,
    handlePrev,
}: CustomStepperProps): JSX.Element {
    const formStyle = useAppSelector((state) => state.global.formStyle);

    const classes = useStepperStyles(formStyle)();
    const fits = useMemo(() => {
        return (width ?? 0) > 16 + totalElements * 15 + 128;
    }, [width]);
    if (!width) return <></>;
    return (
        <MobileStepper
            className={classes.root}
            steps={totalElements}
            position="static"
            variant={fits ? 'dots' : 'progress'}
            activeStep={position}
            nextButton={
                <CustomBtn
                    onClick={handleNext}
                    disabled={!handleNext}
                    backgroundColor={formStyle.primaryColor}
                    color={formStyle.primaryContrastColor}
                    padding="0px"
                    height="30px"
                    width="54px"
                    borderRadius="10px"
                >
                    <KeyboardArrowRight
                        style={{
                            width: 30,
                            height: 30,
                        }}
                    />
                </CustomBtn>
            }
            backButton={
                <CustomBtn
                    onClick={handlePrev}
                    disabled={!handlePrev}
                    backgroundColor={formStyle.primaryColor}
                    color={formStyle.primaryContrastColor}
                    padding="0px"
                    height="30px"
                    width="54px"
                    borderRadius="10px"
                >
                    <KeyboardArrowLeft
                        style={{
                            width: 30,
                            height: 30,
                        }}
                    />
                </CustomBtn>
            }
        />
    );
}
