import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { InputAdornment, MenuItem, Select } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import parse from 'libphonenumber-js/max';
import {
    CountryIso2,
    defaultCountries,
    FlagImage,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone';
import { useAppSelector } from '../../hooks';
import isoCountries from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';
isoCountries.registerLocale(es);
interface StyleProps {
    /** The color of the text */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The backgroundColor of the input */
    backgroundColor?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The padding of the input */
    padding?: string;
    /** The size of the font to display the input in */
    fontSize?: number | string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** Cant edit */
    cantEdit?: boolean;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** The max length of the string */
    maxLength?: number;
}

const useTextfieldStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            borderRadius: props.borderRadius,
            '& input': {
                cursor: props.cantEdit ? 'default' : 'text',
                padding: props.padding,
                fontWeight: props.fontWeight,
                color: props.textColor,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                backgroundColor: props.backgroundColor,
                borderRadius: props.borderRadius,
            },
            '& label': {
                marginTop: '-4px',
                fontSize: props.fontSize,
                color: props.textColor + '8a',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 22px)',
            },
            '& label.EF-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& input + fieldset': {
                borderRadius: props.borderRadius,
            },
            '& .EF-MuiInput-underline:after': {
                borderBottomColor: props.outlineColor,
            },
            '& .EF-MuiOutlinedInput-root': {
                borderRadius: props.borderRadius,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: props.readOnly ? 1 : 2,
                },
                '& .EF-MuiOutlinedInput-notchedOutline': {
                    borderColor: props.outlineColor,
                    '& legend': {
                        fontSize: 'calc(' + props.fontSize + ' * 0.75)',
                    },
                },
            },
            '& .EF-MuiOutlinedInput-root:hover .EF-MuiOutlinedInput-notchedOutline':
                {
                    borderColor: props.cantEdit
                        ? props.outlineColor
                        : props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '& .EF-MuiOutlinedInput-adornedEnd': {
                paddingRight: '7px',
            },
            '& .EF-MuiFormHelperText-root': {
                color: props.helperTextColor,
                position: 'relative',
                marginRight: props.maxLength ? 40 : 14,
            },
            '& .EF-MuiFormHelperText-root.Mui-error': {
                color: props.errorColor,
            },
            '& .EF-MuiInputAdornment-root': {
                paddingTop: 2,
                marginRight: 0,
                '& .EF-MuiSelect-select': {
                    backgroundColor: props.backgroundColor,
                },
                '& input': {
                    padding: 0,
                    margin: 0,
                },
                '& .EF-MuiInput-underline:before': {
                    borderBottom: 'none !important',
                },
                '& .EF-MuiInput-underline:after': {
                    borderBottom: 'none',
                },
            },
        },
    }));

const useCountrySelectStyles = makeStyles(() => ({
    root: {
        padding: 0,
        input: {
            padding: 0,
        },
    },
}));

interface RoundedPhoneInputProps extends StyleProps {
    /** The value of the phoneinput */
    value?: string;
    onChange: (value: string) => void;
    inputRef?: any;
}

function CustomPhoneInput({
    focusColor = '#3d5a7f',
    helperTextColor = '#989898',
    outlineColor = '#0000003b',
    backgroundColor = 'white',
    textColor = '#293241',
    readOnly = false,
    borderRadius = 10,
    padding = '6px 12px 6px 6px',
    fontSize = '1rem',
    errorColor = '#cc2936',
    fontWeight = '300',
    cantEdit = false,
    value,
    onChange,
    inputRef,
    ...others
}: Omit<TextFieldProps, 'onChange'> & RoundedPhoneInputProps): JSX.Element {
    const classes = useTextfieldStyles({
        padding,
        textColor,
        errorColor,
        focusColor,
        outlineColor,
        helperTextColor,
        backgroundColor,
        borderRadius,
        readOnly,
        fontSize,
        fontWeight,
        cantEdit,
    })();

    const defaultCountry = useAppSelector((state) => state.global.countryCode);

    const countrySelectClasses = useCountrySelectStyles();

    const {
        inputValue,
        handlePhoneValueChange,
        inputRef: phoneRef,
        country,
        setCountry,
    } = usePhoneInput({
        value: '+' + value,
        inputRef,
        defaultCountry: defaultCountry.toLowerCase(),
        disableDialCodePrefill: true,
        disableDialCodeAndPrefix: true,
        onChange: (data) => onChange(data.phone.replace('+', '')),
    });

    const countries = useMemo(
        () =>
            defaultCountries
                .map((c) => {
                    const country = parseCountry(c);
                    return {
                        country,
                        name:
                            isoCountries.getName(
                                country.iso2.toUpperCase(),
                                'es'
                            ) ?? country.name,
                    };
                })
                .sort((a, b) => a.name.localeCompare(b.name)),
        []
    );

    return (
        <TextField
            classes={classes}
            {...others}
            variant="outlined"
            size="small"
            fullWidth
            value={inputValue}
            onChange={handlePhoneValueChange}
            type="tel"
            onPaste={(e) => {
                const value = e.clipboardData.getData('Text');
                if (!value.startsWith('+')) {
                    const phone = parse('+' + value);
                    if (phone?.isValid()) {
                        e.preventDefault();
                        if (phone.country)
                            setCountry(phone.country.toLowerCase());
                        onChange(phone.number);
                    }
                }
            }}
            inputRef={phoneRef}
            InputProps={{
                readOnly,
                autoComplete: 'tel',
                disabled: cantEdit,
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{
                            borderBottom: 'none',
                        }}
                    >
                        <Select
                            classes={countrySelectClasses}
                            value={country.iso2}
                            onChange={(e) =>
                                setCountry(e.target.value as CountryIso2)
                            }
                            inputProps={{ disabled: cantEdit }}
                            renderValue={(value) => (
                                <FlagImage
                                    iso2={value as CountryIso2}
                                    style={{ display: 'flex' }}
                                    width={20}
                                />
                            )}
                        >
                            {countries.map(({ country, name }) => {
                                return (
                                    <MenuItem
                                        key={country.iso2}
                                        value={country.iso2}
                                    >
                                        <FlagImage
                                            iso2={country.iso2}
                                            size={20}
                                        />
                                        <span
                                            style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                            }}
                                        >
                                            {name}
                                        </span>
                                        <span style={{ color: 'grey' }}>
                                            +{country.dialCode}
                                        </span>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                ),
            }}
        />
    );
}

/**
 * Generic phoneinput with apps designs. Is class due to the use in the react-hook-forms library
 */
class RoundedPhoneInput extends React.Component<
    RoundedPhoneInputProps & Omit<TextFieldProps, 'onChange'>
> {
    render(): JSX.Element {
        return <CustomPhoneInput {...this.props} />;
    }
}
export default RoundedPhoneInput;
