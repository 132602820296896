import { ApiSelector } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { useAppSelector } from '../../hooks';
import { StepProps } from '../Step';
import MaterialApiSelectorStep from './MaterialApiSelectorStep/MaterialApiSelectorStep';

export interface StepOption {
    id: string | number;
    label: string;
}

export interface ApiSelectorStepProps extends StepProps {
    /** The ApiSelectorStep to display */
    step: ApiSelector;
    /** Default value to display */
    defaultValue?: Date | null;
}
function ApiSelectorStep(props: ApiSelectorStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialApiSelectorStep {...props} />;
        }
    }
}
export default ApiSelectorStep;
