import { FormSelectorOption } from '../../../@Types/FormStep';
import React, { useContext, useEffect, useMemo } from 'react';
import { OptionTypes } from '../../../constants/FormStepTypes';
import StepComponent from '../../Step';
import { SelectorStepProps } from '../SelectorStep';
import RoundedSmartSelect from '../../../Shared/RoundedSmartSelect/RoundedSmartSelect';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector } from '../../../hooks';
import {
    recursivelyCalcConditionSteps,
    selectDependencies,
    useFormStep,
} from '../../StepHooks';
import StepFillerContainer from '../../Utils/@StepFiller/StepFiller';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';
import { calcDefaultValue, evaluateCondition } from '../../StepFunctions';

function SelectorStep({ step, editable }: SelectorStepProps): JSX.Element {
    const { ref, value, onChange, error, field } = useFormStep<{
        label: string;
        value: string;
    }>(step, {
        defaultValue: calcDefaultValue(step),
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
        sizeChange: true,
    });

    const form = useContext(FormContext);
    const { postview, formStyle } = useAppSelector((state) => state.global);

    const idDependencies = useMemo(() => {
        const dependencies = [...(step.dependencies ?? [])];
        for (const option of Object.values(step.options)) {
            if (option.condition) {
                dependencies.push(
                    ...recursivelyCalcConditionSteps(option.condition)
                );
            }
        }
        return dependencies;
    }, []);

    const dependencies = useAppSelector((state) =>
        selectDependencies(state, idDependencies)
    );

    const mapNestedOption = (): void | JSX.Element => {
        let currentOptionIndex: number | null = null;
        let currentOption: FormSelectorOption | null = null;
        for (let i = 0; i < step.options.length; i++) {
            const option = step.options[i];
            if (option?.value === value?.value) {
                currentOptionIndex = i;
                currentOption = option;
            }
        }
        if (
            currentOption?.type === OptionTypes.NESTED &&
            currentOptionIndex !== null
        ) {
            return (
                <React.Fragment>
                    {currentOption.steps.map((idSubStep: string) => {
                        const subStep = form.steps[idSubStep];
                        return (
                            <StepComponent
                                editable={editable}
                                step={subStep}
                                key={idSubStep}
                            />
                        );
                    })}
                </React.Fragment>
            );
        }
    };

    const filteredOptions = useMemo(
        () => {
            return step.options.filter((option) => {
                if (
                    !option.condition ||
                    evaluateCondition(option.condition, dependencies)
                )
                    return true;
                return false;
            });
        },
        idDependencies.map((id) => dependencies[id].value)
    );

    useEffect(() => {
        if (
            value &&
            !filteredOptions.find((option) => option.value === value?.value)
        ) {
            onChange('' as any);
        }
    }, [filteredOptions]);

    return (
        <React.Fragment>
            <MaterialInputContainer step={step} editable={editable}>
                <RoundedSmartSelect
                    {...field}
                    value={value}
                    inputRef={ref}
                    handleUpdate={onChange}
                    disabled={false}
                    loading={false}
                    options={filteredOptions}
                    cantEdit={!editable || postview}
                    fullWidth
                    backgroundColor={formStyle.stepBackgroundColor ?? 'white'}
                    label={step.label}
                    required={step.required}
                    height={'31px'}
                    searchable={step.searchable}
                    icon={undefined}
                    helperTextColor={formStyle.descriptionTextColor}
                    focusColor={formStyle.primaryColor}
                    outlineColor={formStyle.outlineColor}
                    errorColor={formStyle.errorColor}
                    color={formStyle.textColor}
                    containerMargin="0px"
                    getOptionSelected={(option, value): boolean => {
                        return option?.value === value?.value;
                    }}
                    helperText={error?.message?.toString() ?? step.description}
                    error={!!error}
                />
            </MaterialInputContainer>
            {value && mapNestedOption()}
        </React.Fragment>
    );
}

function SelectorStepContainer(props: SelectorStepProps): JSX.Element {
    return (
        <StepFillerContainer step={props.step}>
            <SelectorStep {...props} />
        </StepFillerContainer>
    );
}

export default SelectorStepContainer;
