import { useAppSelector } from '../../hooks';
import { TextArea } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialTextAreaStep from './MaterialTextAreaStep/MaterialTextAreaStep';
import MaterialTextAreaEditorStep from './MaterialTextAreaStep/MaterialTextAreaEditorStep';
export interface TextAreaStepProps extends StepProps {
    /** The TextAreaStep to display */
    step: TextArea;
    maxLength?: number;
}
function TextAreaStep(props: TextAreaStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);
    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            if (props.step.hasTextEditor)
                return <MaterialTextAreaEditorStep {...props} />;
            else return <MaterialTextAreaStep {...props} />;
        }
    }
}
export default TextAreaStep;
