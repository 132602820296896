import React, { createContext, useRef } from 'react';
import { Classifier, Form } from '../@Types/Form';
import Loader from '../Shared/Loader/Loader';
import styles from './App.module.css';
import './App.css';
import FormComponent from '../Form/Form';
import { CustomStep, CustomStepProps } from '../FormSteps/CustomStep';
import { Provider } from 'react-redux';
import { store } from '../Utils/store';
import { useSetupApp } from './AppHooks';
import { StoreContext, useAppSelector } from '../hooks';
import CustomContext from '../Contexts/CustomContext';
import { nanoid } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { CountryCode } from 'libphonenumber-js';
export interface AppProps {
    /** If the app is currently a widget */
    isWidget?: boolean;
    /** The apikey of the Form */
    apiKey?: string;
    /** The id of the form's organization */
    idOrganization?: string;
    /** The form to display, (used for postviews) */
    formData?: Form;
    /** If the widget is usedInternally */
    internal?: boolean;
    /** If the widget is a postview (view what the user filled)*/
    postview?: boolean;
    /** If postview should only show steps with values*/
    partial?: boolean;
    /** If the widget is a preview (view from admin)*/
    preview?: boolean;
    /** If the form can be edited (For entities) */
    editable?: boolean;
    /** The custom send label to display */
    sendLabel?: string;
    /** The data to fill the form with */
    valuesData?: Record<string, unknown>;
    /** Custom steps to display */
    customSteps?: Record<string, CustomStep>;
    /** Custom steps to display */
    customStepProps?: Record<string, unknown>;
    /** Classifiers dict */
    classifiers?: Record<string, Classifier>;
    /** Custom Confirmation Content */
    customConfirmation?: (
        confirmation: { url: string; caseNumber: string },
        renderIcon: () => JSX.Element,
        renderConfirmation: (state?: EditorState) => JSX.Element | void,
        renderLink: () => JSX.Element | void,
        onClose: () => void
    ) => JSX.Element;
    customClientInfoStep?: (props: CustomStepProps) => JSX.Element;
    /** Custom function to call on send */
    customSubmit?: (
        values: Record<string, unknown>,
        reload: () => void
    ) => Promise<void>;
    /** Custom submit function, when passed it is called */
    setSubmit?: (submit: () => Promise<void | Record<string, any>>) => void;
    /** Custom submit buttons */
    customSubmitBtns?:
        | null
        | ((
              onSubmit: () => Promise<Record<string, any> | void>,
              loading: boolean
          ) => JSX.Element);
    /** Function to call on postview to fetch the download url of a file */
    fetchDownloadUrl?: (S3Key: string, fileName: string) => Promise<string>;
    /** Function to call after the confimation dialog has been closed */
    handleConfirmed?: () => void;
    /** Function called to scroll to the top */
    scrollToTop?: () => void;
    /** The default country to use in phone pickers */
    countryCode?: CountryCode;
}

export const IdFormContext = createContext<string>('');

function AppComponent({
    formData,
    valuesData,
    ...props
}: AppProps): JSX.Element {
    const [idForm] = React.useState<string>(nanoid(6));
    return (
        <IdFormContext.Provider value={idForm}>
            <Provider store={store} context={StoreContext}>
                <App formData={formData} valuesData={valuesData} {...props} />
            </Provider>
        </IdFormContext.Provider>
    );
}

export default AppComponent;

function App({ isWidget = true, ...props }: AppProps): JSX.Element {
    const containerRef = useRef<HTMLDivElement>(null);
    const loaded = useAppSelector((state) => state.global.loaded);
    const { form, organization, branding, reload } = useSetupApp(
        isWidget,
        props
    );

    const loading = form === undefined || organization === undefined || !loaded;

    if (loading) {
        if (isWidget) {
            return (
                <div className={styles.widgetContainer}>
                    <div className={styles.curtain}>
                        <Loader
                            size={50}
                            color={branding?.colors?.primaryColor ?? '#b8b8b8'}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.curtain}>
                <Loader
                    size={50}
                    color={branding?.colors?.primaryColor ?? '#b8b8b8'}
                />
            </div>
        );
    } else if (form === null) {
        if (organization === null) {
            if (isWidget) {
                return (
                    <div className={styles.widgetContainer}>
                        <div className={styles.curtain}>Error</div>
                    </div>
                );
            }
            return <div className={styles.curtain}>Error</div>;
        } else {
            if (isWidget) {
                return (
                    <div className={styles.widgetContainer}>
                        <div className={styles.curtain}>404</div>
                    </div>
                );
            }
            return <div className={styles.curtain}>404</div>;
        }
    } else {
        const customSteps = props.customSteps ?? {};
        return (
            <CustomContext.Provider
                value={{
                    customSteps,
                    sendLabel: props.sendLabel,
                    fetchDownloadUrl: props.fetchDownloadUrl,
                    customStepProps: props.customStepProps ?? {},
                    customClientInfoStep: props.customClientInfoStep,
                }}
            >
                <div className={styles.container}>
                    <FormComponent
                        form={form}
                        reload={reload}
                        branding={branding}
                        apiKey={props.apiKey}
                        isWidget={!!isWidget}
                        customSteps={customSteps}
                        containerRef={containerRef}
                        setSubmit={props.setSubmit}
                        customSubmit={props.customSubmit}
                        scrollToTop={props.scrollToTop}
                        customSubmitBtns={props.customSubmitBtns}
                        customConfirmation={props.customConfirmation}
                    />
                </div>
            </CustomContext.Provider>
        );
    }
}
