import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { cloneElement } from 'react';
import { MenuItem, Popper, TextFieldProps } from '@material-ui/core';
import RoundedSelect from '../RoundedSelect/RoundedSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const filter = createFilterOptions<{ label: string; inputValue?: string }>();

interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
    /** The fontsize of the content */
    fontSize?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** If custom icon exists and should be displayed */
    showIcon?: boolean;
    /** If the selector is completely hidden */
    hidden?: boolean;
}

const useTextfieldStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            opacity: props.hidden ? 0.5 : 1,
            borderRadius: props.borderRadius,
            '& input, textarea': {
                cursor: props.cantEdit || props.hidden ? 'default' : 'text',
                fontWeight: props.fontWeight,
                color: props.color,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                backgroundColor: props.backgroundColor,
                borderRadius: props.borderRadius,
                height: props.height,
                marginTop: '0px !important',
                padding: '0px 38px 0px 12px !important',
            },
            '& .EF-MuiInputBase-root': {
                padding: '0px !important',
                pointerEvents: 'all',
            },
            '& label': {
                marginTop: '-4px',
                fontSize: props.fontSize,
                color: props.color?.startsWith('#')
                    ? props.color + '8a'
                    : props.color,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 22px)',
            },
            '& label.EF-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& input + fieldset': {
                borderRadius: props.borderRadius,
            },
            '& .EF-MuiInput-underline:after': {
                borderBottomColor: props.outlineColor,
            },
            '& .EF-MuiOutlinedInput-root': {
                borderRadius: props.borderRadius,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: props.readOnly ? 1 : 2,
                },
                '& .EF-MuiOutlinedInput-notchedOutline': {
                    borderColor: props.outlineColor,
                    pointerEvents: 'all',
                },
                height: props.height,
                '& .EF-MuiAutocomplete-input': {
                    height: props.height,
                    marginTop: -10,
                },
                '& .EF-MuiAutocomplete-input::placeholder': {
                    color: '#787878',
                    opacity: 1,
                },
            },
            '& .EF-MuiOutlinedInput-root:hover .EF-MuiOutlinedInput-notchedOutline':
                {
                    borderColor:
                        props.cantEdit || props.hidden
                            ? props.outlineColor
                            : props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: props.errorColor + ' !important',
            },
            '& .EF-MuiFormHelperText-root': {
                color: props.helperTextColor,
            },
            '& .EF-MuiFormHelperText-root.Mui-error': {
                color: props.errorColor + ' !important',
            },
            '& .EF-MuiFormLabel-root.Mui-disabled': {
                color: '#0000008a',
            },
            '& .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: '#0000003b',
            },
            '& .EF-MuiAutocomplete-popupIndicator': {
                padding: 0,
                color: props.color?.startsWith('#')
                    ? props.color + '8a'
                    : props.color,
            },
            '& .EF-MuiAutocomplete-popupIndicatorOpen': {
                transform: props.showIcon ? 'none' : 'rotate(180deg)',
            },
            '& .EF-MuiAutocomplete-clearIndicator': {
                display: 'none',
            },
            '& .EF-MuiAutocomplete-endAdornment': {
                top: 'calc(50% - 13px)',
                color: props.color?.startsWith('#')
                    ? props.color + '8a'
                    : props.color,
            },
        },
    }));
export interface RoundedSmartSelectProps
    extends Omit<TextFieldProps, 'color' | 'onFocus'>,
        StyleProps {
    /** Currently selected value */
    value: any;
    /** The array of options to display */
    options?: any[];
    /** If the input is loading */
    loading?: boolean;
    /** function called when value changes */
    handleUpdate?: (value: any) => void;
    /** Strig to place in the label */
    label: string;
    /** The helper Text to display */
    helperText?: string;
    /** The margin around the selector */
    containerMargin?: string;
    /** The icon to display */
    icon?: React.ReactNode;
    /** If the options are searchable */
    searchable?: boolean;
    /** If can create new option */
    creatable?: boolean;
    /** Function to determine the currently selected option */
    getOptionSelected?: (option: any, value: any) => boolean;
    /** Function to determine the current value in string format */
    getValueString?: (value: any) => string;
    /** Function called on each value change */
    changeListener?: (value: any) => void;
    onFocus?: any;
}
export default function RoundedSmartSelect({
    options,
    value = '',
    handleUpdate,
    label,
    getValueString = (value): string => value?.value,
    color = '#293241',
    errorColor = '#cc2936',
    focusColor = '#3d5a7f',
    helperTextColor = '#989898',
    outlineColor = '#b8b8b8',
    backgroundColor = '#ffffff',
    cantEdit = false,
    loading = false,
    height = '31px',
    fontSize = '1rem',
    required,
    changeListener,
    error,
    getOptionSelected,
    helperText,
    readOnly = false,
    borderRadius = 10,
    fontWeight = '400',
    icon,
    searchable = false,
    containerMargin = '0px',
    showIcon = true,
    inputRef,
    disabled,
    hidden = false,
    creatable = false,
    onBlur,
    name,
    onFocus,
}: RoundedSmartSelectProps): JSX.Element {
    const props: StyleProps = {
        color,
        height,
        errorColor,
        focusColor,
        outlineColor,
        helperTextColor,
        backgroundColor,
        borderRadius,
        readOnly,
        fontSize,
        fontWeight,
        hidden,
        cantEdit,
        showIcon: showIcon && icon !== undefined,
    };

    const textFieldClasses = useTextfieldStyles(props)();
    if (searchable) {
        return (
            <Autocomplete
                onFocus={onFocus}
                data-testid="smart-select"
                size="small"
                fullWidth
                openOnFocus
                freeSolo={creatable}
                disabled={cantEdit || disabled}
                popupIcon={
                    loading ? (
                        <CircularProgress
                            size={22}
                            style={{ color: '#757575', marginTop: 1 }}
                        />
                    ) : showIcon && icon ? (
                        icon
                    ) : undefined
                }
                onOpen={(): void => {}}
                loading={loading}
                loadingText={'Cargando...'}
                closeIcon={false}
                options={options ?? []}
                getOptionLabel={(option: any): string => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    } else if (option?.inputValue) {
                        return option.inputValue ?? '';
                    } else {
                        // Regular option
                        return option?.label ?? '';
                    }
                }}
                getOptionSelected={getOptionSelected}
                PopperComponent={({ style, ...props }: any): JSX.Element => (
                    <Popper {...props} style={{ ...style, zIndex: 1305 }} />
                )}
                value={!loading && value ? value : null}
                onChange={(event, newValue): void => {
                    if (creatable) {
                        if (typeof newValue === 'string') {
                            changeListener?.(newValue);
                            handleUpdate?.(newValue);
                        } else if (newValue && newValue.inputValue) {
                            changeListener?.(newValue.inputValue);
                            handleUpdate?.(newValue.inputValue); // Create a new value from the user input
                        } else {
                            changeListener?.(newValue?.label);
                            handleUpdate?.(newValue?.label);
                        }
                    } else {
                        changeListener?.(newValue);
                        handleUpdate?.(newValue);
                    }
                }}
                filterOptions={(options, params): any[] => {
                    if (value && value.label === params?.inputValue) {
                        return options;
                    } else {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (creatable && params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                label: `Crear "${params.inputValue}"`,
                            });
                        }
                        return filtered;
                    }
                }}
                renderOption={(option: any): string =>
                    typeof option === 'string' ? option : option.label
                }
                renderInput={(params): JSX.Element => (
                    <TextField
                        classes={textFieldClasses}
                        {...params}
                        inputRef={inputRef}
                        onBlur={onBlur}
                        name={name}
                        variant="outlined"
                        label={label}
                        required={required}
                        error={!hidden && error}
                        helperText={hidden ? undefined : helperText}
                    />
                )}
                noOptionsText="No hay opciones"
            />
        );
    } else {
        return (
            <RoundedSelect
                onBlur={onBlur}
                onFocus={onFocus}
                data-testid="smart-select"
                inputRef={inputRef}
                name={name}
                fullWidth
                handleUpdate={(event): void => {
                    handleUpdate?.(
                        options?.find(
                            (option) =>
                                getValueString(option) === event.target.value
                        )
                    );
                }}
                value={loading ? '' : getValueString(value)}
                color={color}
                errorColor={errorColor}
                focusColor={focusColor}
                helperTextColor={helperTextColor}
                backgroundColor={backgroundColor}
                outlineColor={outlineColor}
                cantEdit={cantEdit}
                disabled={cantEdit || disabled}
                label={label}
                required={required}
                readOnly={readOnly}
                helperText={hidden ? undefined : helperText}
                containerMargin={containerMargin}
                height={height}
                fontSize={fontSize}
                error={!hidden && error}
                iconComponent={
                    loading
                        ? (): React.ReactNode => (
                              <div
                                  style={{
                                      marginTop: 3,
                                      marginRight: 8,
                                  }}
                              >
                                  <CircularProgress
                                      size={22}
                                      style={{
                                          color: '#757575',
                                      }}
                                  />
                              </div>
                          )
                        : showIcon && icon
                          ? (props: any): React.ReactNode =>
                                cloneElement(icon as any, props)
                          : undefined
                }
                showIcon={showIcon}
            >
                <MenuItem
                    value={''}
                    key={'EMPTY'}
                    data-testid={'smart-select-empty'}
                    style={{ whiteSpace: 'normal' }}
                >
                    <em>{options ? 'Sin Seleccionar' : 'Cargando...'}</em>
                </MenuItem>
                {options?.map((option) => (
                    <MenuItem
                        value={getValueString(option)}
                        key={getValueString(option)}
                        style={{ whiteSpace: 'normal' }}
                        data-testid={'smart-select-' + getValueString(option)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
                {options === undefined && value && !loading && (
                    <MenuItem
                        value={getValueString(value)}
                        key={getValueString(value)}
                        style={{ whiteSpace: 'normal' }}
                    >
                        {value.label ?? ''}
                    </MenuItem>
                )}
            </RoundedSelect>
        );
    }
}
