import { Dialog } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import RoundedButton from '../../../../Shared/RoundedButton/RoundedButton';
import RoundedCheckBox from '../../../../Shared/RoundedCheckBox/RoundedCheckBox';
import { TermComponentProps } from '../Term';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import styles from './MaterialTerm.module.css';
import { useAppSelector } from '../../../../hooks';
import { createSelector } from '@reduxjs/toolkit';
import { ValuesStore } from '../../../../States/SiteSlice';
import { RootState } from '../../../../Utils/store';
const selectOriginalValue = createSelector(
    [
        (state: RootState): ValuesStore => state.site.values,
        (state: RootState, idTerm: string): string => idTerm,
    ],
    (values, idTerm) => values.global[idTerm]
);

function TermComponent({ term, tempError }: TermComponentProps): JSX.Element {
    const { formStyle, postview } = useAppSelector((state) => state.global);

    const originalValue = useAppSelector((state) =>
        selectOriginalValue(state, term.id)
    );

    const {
        field: { ref, ...field },
        fieldState: { error },
    } = useController({
        name: term.id,
        rules: {
            required: true,
        },
        defaultValue: originalValue ?? false,
        shouldUnregister: true,
    });

    const [showDialog, setShowDialog] = useState(false);
    /**
     * Function that renders the highlighted text
     * @returns a string with span highlighted
     */
    const renderText = (text: string): any => {
        if (text) {
            const parts: any = text.split(/{{(.*?)}}/gi);
            for (let i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span
                        className={styles.highlight}
                        style={{
                            color:
                                !!error && tempError
                                    ? formStyle.errorColor
                                    : formStyle.primaryColor,
                        }}
                        key={i}
                        onClick={(): void => {
                            if (term.clickEffect?.type === 'LINK') {
                                window
                                    .open?.(term.clickEffect.url, '_blank')
                                    ?.focus?.();
                            } else if (term.clickEffect?.type === 'DIALOG') {
                                setShowDialog(true);
                            }
                        }}
                    >
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };
    return (
        <React.Fragment>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: '20px',
                    },
                }}
                onClose={(): void => {
                    setShowDialog(false);
                }}
                open={showDialog}
            >
                {term.clickEffect?.type === 'DIALOG' && (
                    <div
                        className={styles.dialogContainer}
                        style={{ color: formStyle.textColor }}
                    >
                        <div
                            className={styles.closeIcon}
                            onClick={(): void => {
                                setShowDialog(false);
                            }}
                        >
                            <CloseRoundedIcon fontSize="inherit" />
                        </div>
                        <div
                            className={styles.dialogTitle}
                            style={{
                                borderBottom:
                                    '1px solid ' + formStyle.primaryColor,
                            }}
                        >
                            {term.clickEffect.title}
                        </div>
                        <div className={styles.dialogContentContainer}>
                            {term.clickEffect.text}
                        </div>
                        <RoundedButton
                            text={'Aceptar'}
                            padding="20px 15px"
                            backgroundColor={formStyle.primaryColor}
                            color={formStyle.primaryContrastColor}
                            fontSize={20}
                            onClick={(): void => {
                                setShowDialog(false);
                                if (!postview) {
                                    field.onChange(true);
                                }
                            }}
                        ></RoundedButton>
                    </div>
                )}
            </Dialog>
            <div className={styles.container + ' noselect'}>
                <div className={styles.checkboxContainer}>
                    <RoundedCheckBox
                        inputRef={ref}
                        {...field}
                        checked={field.value}
                        cantEdit={postview}
                        padding="5px"
                        checkedColor={formStyle.primaryColor}
                        errorColor={formStyle.errorColor}
                        size="1.5rem"
                        error={!!error}
                    />
                </div>
                <p
                    className={styles.messageContainer}
                    style={{
                        color:
                            !!error && tempError
                                ? formStyle.errorColor
                                : 'inherit',
                    }}
                >
                    {renderText(term.message)}
                </p>
            </div>
        </React.Fragment>
    );
}
export default TermComponent;
