import { MaterialStyle } from '../@Types/Form';
import { FormStyleTypes } from './FormStepTypes';

const InternalFormStyle: MaterialStyle = {
    type: FormStyleTypes.MATERIAL,
    standAloneBackgroundColor: '#ffffff',
    backgroundColor: '#ffffff',
    stepBackgroundColor: '#ffffff',
    primaryColor: '#3d5a7f',
    secondaryColor: '#98c1d9',
    primaryContrastColor: '#ffffff',
    secondaryContrastColor: '#ee6c4d',
    errorColor: '#cc2936',
    textColor: '#293241',
    outlineColor: '#b8b8b8',
    descriptionTextColor: '#989898',
};
export default InternalFormStyle;
