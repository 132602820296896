import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface StyleProps {
    /** The color of the text */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The backgroundColor of the input */
    backgroundColor?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The padding of the input */
    padding?: string;
    /** The size of the font to display the input in */
    fontSize?: number | string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** If the input is multiline */
    multiline?: boolean;
    /** Cant edit */
    cantEdit?: boolean;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** The icon to display */
    icon?: React.ReactNode;
    /** The max length of the string */
    maxLength?: number;
}

const useTextfieldStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            borderRadius: props.borderRadius,
            '& input, textarea': {
                cursor: props.cantEdit ? 'default' : 'text',
                padding: props.multiline ? props.padding : props.padding,
                fontWeight: props.fontWeight,
                color: props.textColor,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                backgroundColor: props.backgroundColor,
                borderRadius: props.borderRadius,
            },
            '& label': {
                marginTop: props.multiline ? '0px' : '-4px',
                fontSize: props.fontSize,
                color: props.textColor + '8a',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 22px)',
            },
            '& label.EF-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& input + fieldset': {
                borderRadius: props.borderRadius,
            },
            '& .EF-MuiInput-underline:after': {
                borderBottomColor: props.outlineColor,
            },
            '& .EF-MuiOutlinedInput-root': {
                borderRadius: props.borderRadius,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: props.readOnly ? 1 : 2,
                },
                '& .EF-MuiOutlinedInput-notchedOutline': {
                    borderColor: props.outlineColor,
                    '& legend': {
                        fontSize: 'calc(' + props.fontSize + ' * 0.75)',
                    },
                },
            },
            '& .EF-MuiOutlinedInput-root:hover .EF-MuiOutlinedInput-notchedOutline':
                {
                    borderColor: props.cantEdit
                        ? props.outlineColor
                        : props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '& .EF-MuiOutlinedInput-adornedEnd': {
                paddingRight: '7px',
            },
            '& .EF-MuiFormHelperText-root': {
                color: props.helperTextColor,
                position: 'relative',
                marginRight: props.maxLength ? 40 : 14,
            },
            '& .EF-MuiFormHelperText-root.Mui-error': {
                color: props.errorColor,
            },
            '& .EF-MuiOutlinedInput-multiline': {
                padding: 0,
                backgroundColor: props.backgroundColor,
            },
        },
    }));

interface RoundedTextFieldProps extends StyleProps {
    /** The value of the textfield */
    value?: string;
}
function CustomTextfield({
    focusColor = '#3d5a7f',
    helperTextColor = '#989898',
    outlineColor = '#0000003b',
    backgroundColor = 'white',
    textColor = '#293241',
    readOnly = false,
    borderRadius = 10,
    padding = '6px 12px',
    fontSize = '1rem',
    errorColor = '#cc2936',
    fontWeight = '300',
    multiline = false,
    cantEdit = false,
    maxLength,
    value,
    icon,
    ...others
}: TextFieldProps & RoundedTextFieldProps): JSX.Element {
    const classes = useTextfieldStyles({
        padding,
        textColor,
        errorColor,
        focusColor,
        outlineColor,
        helperTextColor,
        backgroundColor,
        borderRadius,
        readOnly,
        fontSize,
        fontWeight,
        multiline,
        cantEdit,
        maxLength,
    })();
    const length = maxLength ? value?.length ?? null : null;
    const inputProps: OutlinedInputProps = {
        readOnly,
        disabled: cantEdit,
        endAdornment: icon,
    };
    if (maxLength) {
        inputProps.inputProps = { maxLength };
    }
    return (
        <div style={{ position: 'relative', width: '100%', maxWidth: '100%' }}>
            <TextField
                classes={classes}
                {...others}
                value={value ?? ''}
                multiline={multiline}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={inputProps}
                helperText={
                    others.helperText ? (
                        <React.Fragment>
                            {others.helperText}
                            {length !== null && (
                                <label
                                    style={{
                                        position: 'absolute',
                                        top: '1px',
                                        right: '-32px',
                                        fontSize: '12px',
                                        color: helperTextColor,
                                    }}
                                >
                                    {length}/{maxLength}
                                </label>
                            )}
                        </React.Fragment>
                    ) : undefined
                }
            />
            {length !== null && !others.helperText && (
                <div
                    style={{
                        fontSize: '12px',
                        color: helperTextColor,
                        textAlign: 'right',
                        paddingRight: '8px',
                        paddingTop: '2px',
                        paddingBottom: '2px',
                    }}
                >
                    {length}/{maxLength}
                </div>
            )}
        </div>
    );
}

/**
 * Generic textfield with apps designs. Is class due to the use in the react-hook-forms library
 */
class RoundedTextField extends React.Component<
    RoundedTextFieldProps & TextFieldProps
> {
    render(): JSX.Element {
        return <CustomTextfield {...this.props} />;
    }
}
export default RoundedTextField;
