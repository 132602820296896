import { Dialog } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import styles from './MaterialEntityValueDialog.module.css';
import RoundedButton from '../../../../Shared/RoundedButton/RoundedButton';
import { useAppSelector } from '../../../../hooks';
import { EurekaDraft } from '../../../../@Types/Draft/Draft';
import { EditorState, convertFromRaw } from 'draft-js';
import { useContext, useMemo } from 'react';
import { mapDraftEntities } from '../../../../Utils/DraftFunctions';
import { Editor } from 'react-draft-wysiwyg';
import { Entity } from '../../../../@Types/Entity';
import FormContext from '../../../../Contexts/FormContext';
interface EntityValueDialogProps {
    type: 'WARNING' | 'INFO';
    message: EurekaDraft;
    entityValue: any;
    entity: Entity;
    handleClose: () => void;
}

function MaterialEntityValueDialog({
    type,
    entity,
    message,
    entityValue,
    handleClose,
}: EntityValueDialogProps): JSX.Element {
    const formStyle = useAppSelector((state) => state.global.formStyle);
    const form = useContext(FormContext);

    const dependencies = useAppSelector((state) => state.site.dependencies);

    const editorState = useMemo(() => {
        return EditorState.createWithContent(
            convertFromRaw(
                mapDraftEntities(
                    { form, dependencies, entity, entityValue },
                    message
                )
            )
        );
    }, [message, dependencies]);

    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    maxWidth: '100vw',
                    boxSizing: 'content-box',
                },
            }}
            open={true}
        >
            <div
                className={styles.confirmationContainer}
                style={{ color: formStyle.textColor }}
            >
                <div
                    className={styles.closeIcon}
                    onClick={(): void => handleClose()}
                >
                    <CloseRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.container}>
                    <div className={styles.contentContainer}>
                        <div
                            className={styles.warningContainer}
                            style={{
                                color:
                                    type === 'INFO'
                                        ? formStyle.primaryColor
                                        : formStyle.errorColor,
                            }}
                        >
                            {type === 'INFO' ? (
                                <InfoRoundedIcon
                                    fontSize="inherit"
                                    style={{ fontSize: '160px' }}
                                />
                            ) : (
                                <WarningRoundedIcon
                                    fontSize="inherit"
                                    style={{ fontSize: '160px' }}
                                />
                            )}
                        </div>
                        <div className={styles.messageContainer}>
                            <Editor
                                editorClassName={'Erk-Forms-Dialog'}
                                editorState={editorState}
                                readOnly
                                toolbarHidden
                            />
                        </div>
                    </div>
                    <div className={styles.btnContainer}>
                        <RoundedButton
                            text={'Continuar'}
                            color={formStyle.primaryContrastColor}
                            backgroundColor={
                                type === 'INFO'
                                    ? formStyle.primaryColor
                                    : formStyle.errorColor
                            }
                            fontSize={'1.2rem'}
                            height={undefined}
                            padding={'16px 20px'}
                            onClick={(): void => handleClose()}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
export default MaterialEntityValueDialog;
