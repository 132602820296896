import { useCallback, useLayoutEffect } from 'react';
import { updateWidthStats } from '../States/WidthStatsSlice';
import { useAppDispatch } from '../hooks';
import { WidthStats } from './Form';
import { Form } from '../@Types';

export function useWidthStats(
    containerRef: React.RefObject<HTMLDivElement>,
    form: Form,
    postview: boolean,
    internal: boolean
): void {
    const calcWidthStats = useCallback(
        (currentWidth: number): WidthStats => {
            let currentBreakPoint = form.size.blockNum;
            const padding = postview || internal || !form.isStandAlone ? 0 : 40;
            while (
                currentWidth <
                (form.size.blockSize + form.size.spacingSize) *
                    currentBreakPoint +
                    padding
            ) {
                currentBreakPoint--;
            }
            return {
                isResponsive:
                    currentWidth <
                    (form.size.blockSize + form.size.spacingSize) *
                        form.size.blockNum +
                        padding,
                isMobile:
                    currentWidth <
                    (form.size.blockSize + form.size.spacingSize) * 2 + padding,
                currentBreakPoint,
            };
        },
        [form, postview, internal]
    );
    const dispatch = useAppDispatch();

    /** Calcs the currentBreakPoint and if is mobile */
    useLayoutEffect(() => {
        const handleResize = (): void => {
            const currentWidth = containerRef?.current?.offsetWidth;
            if (currentWidth !== undefined) {
                const current = calcWidthStats(currentWidth);
                dispatch(updateWidthStats(current));
            }
        };
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
}
