import React from 'react';
import { makeStyles, Switch, SwitchProps } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface StyleProps {
    /** The color of the toggle */
    color?: string;
    /** The color of the toggle when unchecked */
    baseColor?: string;
}

const useStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles({
        root: {
            padding: '6px 12px',
            height: 26,
        },
        switchBase: {
            padding: '3px 6px',
            color: props.baseColor + ' !important',
            '&$checked': {
                color: props.color + ' !important',
            },
            '&$checked + $track': {
                backgroundColor: props.color + ' !important',
            },
            '&:hover': {
                backgroundColor: props.color?.startsWith('#')
                    ? props.color + '40'
                    : props.color,
            },
        },
        checked: {},
        track: {},
    });

interface RoundedToggleProps
    extends Omit<SwitchProps, 'color' | 'onChange'>,
        StyleProps {
    /** If the toggle is checked */
    checked?: boolean;
    /** Function to call when the toggle is clicked on */
    onChange: (checked: boolean) => void;
    /** If the Toggle is disabled */
    disabled?: boolean;
}
function RoundedToggle({
    color = '#3d5a7f',
    baseColor = '#b8b8b8',
    checked = true,
    onChange,
    disabled = false,
    size = 'medium',
}: RoundedToggleProps): JSX.Element {
    const classes = useStyles({ color, baseColor })();
    return (
        <Switch
            size={size}
            classes={classes}
            checked={checked}
            disabled={disabled}
            onChange={(event): void => {
                onChange(event.target.checked);
            }}
        />
    );
}
export default RoundedToggle;
