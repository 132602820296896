import { FormStyle } from '../@Types/Form';
import InternalFormStyle from '../constants/InternalFormStyle';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SiteState } from './SiteSlice';
import { BaseConfirmationMessage } from '../Contexts/FormContext';
import { EurekaDraft } from '../@Types/Draft/Draft';
import { CountryCode } from 'libphonenumber-js';

export interface GlobalState {
    idOrganization: string;
    countryCode: CountryCode;
    formStyle: FormStyle;
    confirmation: {
        confirmationMessage: EurekaDraft;
        showLink: boolean;
    };
    /** If the formsteps can be edited (For internal) */
    editable: boolean;
    preview: boolean;
    postview: boolean;
    partial: boolean;
    internal: boolean;
    loaded: boolean;
}

const initialState: GlobalState = {
    idOrganization: '',
    countryCode: 'CO',
    formStyle: InternalFormStyle,
    confirmation: {
        confirmationMessage: BaseConfirmationMessage,
        showLink: true,
    },
    editable: false,
    preview: false,
    postview: false,
    partial: false,
    internal: false,
    loaded: false,
};

export const GlobalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        reset: (
            state,
            action: PayloadAction<Omit<GlobalState, 'loaded'> & SiteState>
        ) => {
            return { ...state, ...action.payload, loaded: true };
        },
    },
});

export const { reset } = GlobalSlice.actions;

export default GlobalSlice;
