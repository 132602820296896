import { DependencyStore, StepDependency } from '../Form/Form';
import { GBaseStep } from '../@Types/GenericFormSteps';
import StepTypes from '../constants/FormStepTypes';
import { FormStep } from '../@Types/FormStep';
import { CustomStep } from '../FormSteps/CustomStep';
import { CBRFormStep } from '../@Types/CBRFormStep';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { reset } from './GlobalSlice';
import { calcDependencies } from '../Form/FormFunctions';
import { FieldValues } from 'react-hook-form';
import { calcDefaultValue } from '../FormSteps/StepFunctions';
import { Form } from '../@Types';

export interface SiteState {
    dependencies: DependencyStore;
    values: ValuesStore;
    idCurrentSection: string | null;
    previousSections: string[];
    nextSections: string[];
    focusStep?: string;
}

const initialState: SiteState = {
    values: {
        global: {},
        sections: {},
    },
    dependencies: {},
    idCurrentSection: null,
    previousSections: [],
    nextSections: [],
};

export interface ValuesStore {
    global: Record<string, any>;
    sections: Record<string, Record<string, any>>;
}

export const SiteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setStepDependency: (
            state,
            {
                payload: {
                    step: { id, type, idSection },
                    value,
                },
            }: PayloadAction<{
                step: GBaseStep;
                value: StepDependency['value'];
            }>
        ) => {
            const old = state.dependencies[id];
            if (old && (old.type !== type || old.value !== value)) {
                state.dependencies[id] = {
                    ...state.dependencies[id],
                    type: type as StepTypes,
                    value,
                };

                if (state.idCurrentSection !== null) {
                    for (const dependent of old.dependents) {
                        if (dependent.idSection !== idSection) {
                            state.values.sections[dependent.idSection][
                                dependent.id
                            ] = calcDefaultValue(dependent);
                        }
                    }
                }
            }
        },
        clearDependency: (
            state,
            { payload: { id, idSection } }: PayloadAction<GBaseStep>
        ) => {
            if (
                state.idCurrentSection === null ||
                state.idCurrentSection === idSection
            ) {
                const old = state.dependencies[id];
                if (old && old.value !== null) {
                    state.dependencies[id] = {
                        ...state.dependencies[id],
                        value: null,
                        empty: false,
                    };
                    if (state.idCurrentSection !== null) {
                        for (const dependent of old.dependents) {
                            if (dependent.idSection !== idSection) {
                                state.values.sections[dependent.idSection][
                                    dependent.id
                                ] = calcDefaultValue(dependent);
                            }
                        }
                    }
                }
            }
        },
        setEmptyDependency: (
            state,
            {
                payload: {
                    step: { id },
                    empty,
                },
            }: PayloadAction<{
                step: GBaseStep;
                empty: StepDependency['empty'];
            }>
        ) => {
            if (state.dependencies[id]) {
                state.dependencies[id].empty = empty;
            }
        },
        addStepsDependencies: (
            state,
            {
                payload: { steps, customSteps, allSteps },
            }: PayloadAction<{
                steps: Record<string, FormStep | CBRFormStep>;
                customSteps: Record<string, CustomStep>;
                allSteps: Record<string, FormStep | CBRFormStep>;
            }>
        ) => {
            calcDependencies(steps, customSteps, allSteps, state.dependencies);
        },
        handlePrevious: (
            state,
            {
                payload: { values, form },
            }: PayloadAction<{ values: Record<string, any>; form: Form }>
        ) => {
            const { previousSections, nextSections, idCurrentSection } = state;
            const idPreviousSection = previousSections.pop();
            if (!idPreviousSection || !idCurrentSection) return;
            nextSections.unshift(idCurrentSection);
            addPagedValues(
                form.steps,
                values,
                state.values.sections[idCurrentSection]
            );
            state.values.sections[idCurrentSection] = values;
            state.idCurrentSection = idPreviousSection;
        },
        handleNext: (
            state,
            {
                payload: { values, form },
            }: PayloadAction<{ values: Record<string, any>; form: Form }>
        ) => {
            const { previousSections, nextSections, idCurrentSection } = state;
            const idNextSection = nextSections.shift();
            if (!idNextSection || !idCurrentSection) return;
            previousSections.push(idCurrentSection);
            addPagedValues(
                form.steps,
                values,
                state.values.sections[idCurrentSection]
            );
            state.values.sections[idCurrentSection] = values;
            state.idCurrentSection = idNextSection;
        },
        updateNestedValues: (
            state,
            {
                payload: { key, values },
            }: PayloadAction<{ key: string; values: Record<string, any> }>
        ) => {
            const { idCurrentSection } = state;
            if (!idCurrentSection) return;
            const sectionValues = state.values.sections[idCurrentSection];
            for (const idStep of Object.keys(sectionValues)) {
                if (idStep.startsWith(key)) {
                    delete sectionValues[idStep];
                }
            }
            state.values.sections[idCurrentSection] = {
                ...sectionValues,
                ...values,
            };
        },
        focusStep: (
            state,
            {
                payload,
            }: PayloadAction<
                { step: FormStep; values: FieldValues } | undefined
            >
        ) => {
            const idSection = payload?.step.idSection;
            const { previousSections, nextSections } = state;
            let idCurrentSection: string | undefined | null =
                state.idCurrentSection;

            if (
                idSection &&
                idCurrentSection &&
                previousSections.includes(idSection)
            ) {
                state.values.sections[idCurrentSection] = payload.values;
                while (idCurrentSection !== idSection) {
                    const idPreviousSection = previousSections.pop();
                    if (!idCurrentSection) return;
                    nextSections.unshift(idCurrentSection);
                    idCurrentSection = idPreviousSection;
                }
            }
            state.previousSections = previousSections;
            state.idCurrentSection = idCurrentSection;
            state.nextSections = nextSections;
            state.focusStep = payload?.step.id;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(reset, (state, action) => {
            const payload = action.payload;
            state.previousSections = payload.previousSections;
            state.idCurrentSection = payload.idCurrentSection;
            state.nextSections = payload.nextSections;
            state.values = payload.values;
            state.dependencies = payload.dependencies;
        });
    },
});

export const {
    focusStep,
    clearDependency,
    setStepDependency,
    addStepsDependencies,
    setEmptyDependency,
    handlePrevious,
    handleNext,
    updateNestedValues,
} = SiteSlice.actions;

export default SiteSlice;

export function addPagedValues(
    steps: Record<string, FormStep>,
    values: Record<string, any>,
    original: Record<string, any>,
    path: string = ''
): void {
    for (const step of Object.values(steps)) {
        const idStep = (path ? path + '-' : '') + step.id;
        if (step.type === StepTypes.MAPPER) {
            const value = values[idStep];
            if (value) {
                const { elements, page } = value;
                for (const element of elements) {
                    addPagedValues(
                        step.steps,
                        values,
                        original,
                        idStep + '-' + element.id
                    );
                }
                if (step.style.type === 'PAGED') {
                    const currentPageElement = elements[page];
                    for (const key of Object.keys(original)) {
                        if (
                            key.startsWith(idStep + '-') &&
                            !key.startsWith(
                                idStep + '-' + currentPageElement.id + '-'
                            ) &&
                            !values[key]
                        ) {
                            values[key] = original[key];
                        }
                    }
                }
            }
        }
    }
}
