import axios from 'axios';

const headers = {};

const widgetInstance = axios.create({
    baseURL: `https://api.forms.${process.env.REACT_APP_DOMAIN}/api`,
    timeout: 30000,
    headers,
});

export default widgetInstance;
