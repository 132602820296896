import { Term } from '../../../@Types/Form';
import { FormStyleTypes } from '../../../constants/FormStepTypes';
import { useAppSelector } from '../../../hooks';
import MaterialTerm from './MaterialTerm/MaterialTerm';

export interface TermComponentProps {
    term: Term;
    tempError: boolean;
}
function TermComponent(props: TermComponentProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialTerm {...props} />;
        }
    }
}
export default TermComponent;
