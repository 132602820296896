import { nanoid } from '@reduxjs/toolkit';
import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { EurekaDraft, EurekaDraftEntity } from '../@Types/Draft/Draft';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../constants/Draft/DraftEntityDataTypes';
import { DraftEntityTypes } from '../constants/Draft/DraftEntityTypes';
import {
    DraftEntityData,
    TicketDraftEntityData,
} from '../@Types/Draft/DraftEntityData';
import { evaluateCondition } from '../FormSteps/StepFunctions';
import { DependencyStore, getLocale } from '../Form/Form';
import { TicketPropertyTypes } from '../constants/TicketPropertyTypes';
import { format } from 'date-fns';
import EntityPropertyTypes from '../constants/EntityPropertyTypes';
import { EntityProperty } from '../@Types/Entity';
import FormStepTypes, { RatingTypes } from '../constants/FormStepTypes';
import { Time } from '../@Types/Time';
import { FormStep } from '../@Types/FormStep';
import { Form } from '../@Types';

interface FormCache {
    form: Form;
    dependencies: DependencyStore;
    ticket?: { caseNumber: string };
    entityValue?: { _id: string; label: string; [key: string]: any };
    company?: { _id: string; label: string; [key: string]: any };
    entity?: Record<string, any>;
}

export function stringToDraft(text: string): RawDraftContentState {
    const draftStructure = {
        blocks: [] as any[],
        entityMap: {},
    };
    text.split('\n').forEach((element, index) => {
        draftStructure.blocks.push({
            key: String(index),
            text: element,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });
    });
    return draftStructure as RawDraftContentState;
}

export const getRawText = (
    draft?: RawDraftContentState,
    text?: string
): RawDraftContentState => {
    if (draft !== undefined) {
        return draft;
    } else if (text !== undefined) {
        return stringToDraft(text);
    } else {
        return {
            blocks: [
                {
                    key: nanoid(5),
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
    }
};

export function mapDraftEntities(
    cache: FormCache,
    draft: EurekaDraft,
    mapToId = false
): RawDraftContentState {
    const newBlocks: RawDraftContentBlock[] = [];
    const { blocks, entityMap } = JSON.parse(
        JSON.stringify(draft)
    ) as EurekaDraft;
    for (const block of blocks) {
        newBlocks.push(...setBlockEntities(cache, block, entityMap, mapToId));
    }
    return { blocks, entityMap };
}

function setBlockEntities(
    cache: FormCache,
    block: RawDraftContentBlock,
    entityMap: Record<string, EurekaDraftEntity>,
    mapToId = false
): RawDraftContentBlock[] {
    //Con esta funcion saca el texto remplazado de la entidad
    const entities = block.entityRanges.sort(
        (s1: any, s2: any) => s2.offset - s1.offset
    );
    const newEntities: RawDraftContentBlock['entityRanges'] = [];

    for (const entity of entities) {
        const styles = block.inlineStyleRanges.sort(
            (s1: any, s2: any) => s2.offset - s1.offset
        );
        const entityEnd = entity.offset + entity.length - 1;
        const entityData = entityMap[entity.key];
        if (entityData.type === DraftEntityTypes.EUREKA) {
            const mappedBlock: MappedBlock = draftEntityMapper(
                cache,
                entityData.data,
                mapToId
            );
            const entityText = mappedBlock.text;
            const entitySizeDelta = entityText.length - entity.length;
            for (const style of styles) {
                const styleEnd = style.offset + style.length - 1;
                //revisa si el estilo termina después del inicio del entity, de lo contrario el estilo no se ve afectado por el entity
                if (styleEnd > entity.offset) {
                    //si el estilo termina en la mitad del entity
                    if (styleEnd < entityEnd) {
                        style.length = entityEnd - style.offset + 1;
                    }
                    //si el estilo empieza en la mitad del entity
                    if (
                        style.offset < entityEnd &&
                        style.offset > entity.offset
                    ) {
                        style.length =
                            style.length + style.offset - entity.offset;
                        style.offset = entity.offset;
                    }
                    //Ahora se modifica el style con base en el tamaño del entityText a reemplazar

                    //Caso 1: Si el estilo rodea el entity
                    if (style.offset <= entity.offset) {
                        style.length = style.length + entitySizeDelta;
                    }
                    //Caso 2: Si el estilo empieza después del entity
                    if (style.offset > entityEnd + 1) {
                        style.offset = style.offset + entitySizeDelta;
                    }
                }
            }
            //reemplaza el entity por su text
            const originalBlockText = block.text;
            block.text =
                block.text.substring(0, entity.offset) +
                entityText +
                originalBlockText.substring(
                    entityEnd + 1,
                    originalBlockText.length
                );
            block.entityRanges = block.entityRanges.filter((entity: any) =>
                newEntities.includes(entity.key)
            );
            delete entityMap[entity.key];
            /** Add new block styles */
            if (!mappedBlock.inlineStyleRanges) continue;
            for (const style of mappedBlock.inlineStyleRanges) {
                style.offset += entity.offset;
                /** Obs: no hay estilos(ya existentes) que se partan en la mitad de la entidad! */
                /** If new style intersects with current style of same type modify the current one */
                const currentInStart = block.inlineStyleRanges.find(
                    (blockStyle) =>
                        blockStyle.offset <= style.offset &&
                        blockStyle.offset + blockStyle.length > style.offset &&
                        blockStyle.style === style.style
                );
                if (currentInStart) {
                    //If the current style covers the new style's range, ignore it
                    if (
                        currentInStart.offset + currentInStart.length >
                        style.offset + style.length
                    )
                        continue;
                    else {
                        //Modify the current style to cover the new style's range
                        currentInStart.length =
                            style.offset + style.length - currentInStart.offset;
                        continue;
                    }
                }
                const currentInEnd = block.inlineStyleRanges.find(
                    (blockStyle) =>
                        blockStyle.offset < style.offset + style.length &&
                        blockStyle.offset + blockStyle.length >
                            style.offset + style.length &&
                        blockStyle.style === style.style
                );
                if (currentInEnd) {
                    if (currentInEnd.offset < style.offset) continue;
                    currentInEnd.offset = style.offset;
                    continue;
                }
                block.inlineStyleRanges.push(style);
            }

            //No filtrar sino cambiar? o poner en estado "Mappeado?"
            //Actualmente el draft queda sin entityMap y sin entityRanges en los bloques. para cambiarlo quitar el delete y el filter
        } else {
            newEntities.push(entity);
        }
    }
    block.entityRanges = newEntities;
    return [block];
}

type MappedBlock = {
    text: string;
    inlineStyleRanges?: RawDraftContentBlock['inlineStyleRanges'];
};

export const draftEntityMapper = (
    cache: FormCache,
    entityData: DraftEntityData,
    mapToId = false
): MappedBlock => {
    if (
        entityData.condition &&
        entityData.type !== DraftEntityDataTypes.CONDITION_MET
    ) {
        if (!evaluateCondition(entityData.condition, cache.dependencies))
            return { text: '' };
    }
    let value = entityMapper(cache, entityData, mapToId);

    if (!value?.text && entityData.fallback) {
        value = { text: entityData.fallback };
    }
    if (value?.text && entityData.prefix) {
        value.text = entityData.prefix + value.text;
    }
    if (value?.text && entityData.suffix) {
        value.text = value.text + entityData.suffix;
    }
    return {
        text: value?.text ?? '',
        inlineStyleRanges: value?.inlineStyleRanges,
    };
};

const entityMapper = (
    cache: FormCache,
    entityData: DraftEntityData,
    mapToId = false
): MappedBlock | null => {
    switch (entityData.type) {
        case DraftEntityDataTypes.CONDITION_MET: {
            if (evaluateCondition(entityData.condition, cache.dependencies)) {
                return { text: entityData.ifTrue ?? 'Sí' };
            }
            return { text: entityData.ifFalse ?? 'No' };
        }
        case DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING: {
            if (!cache?.entityValue)
                throw new Error('Invalid EntityValue Mapping');

            const property = cache.entity?.steps[entityData.idProperty];
            if (!property) return null;
            const text = calcEntityProperty(
                property,
                cache.entityValue[entityData.idProperty]
            );
            return text ? { text } : null;
        }
        case DraftEntityDataTypes.COMPANY: {
            const company = cache.company;
            if (company) {
                const property = cache.entity?.steps[entityData.idProperty];
                if (!property) return null;
                const text = calcEntityProperty(
                    property,
                    company[entityData.idProperty]
                );
                return text ? { text } : null;
            }
            break;
        }
        case DraftEntityDataTypes.TICKET: {
            const text = calcTicketProperty(cache, entityData);
            return text ? { text } : null;
        }
        case DraftEntityDataTypes.NESTED: {
            const blocks = setBlockEntities(
                cache,
                JSON.parse(JSON.stringify(entityData.block)),
                JSON.parse(JSON.stringify(entityData.entityMap))
            );

            const block: MappedBlock = { text: '', inlineStyleRanges: [] };

            for (let i = 0; i < blocks.length; i++) {
                const nested = blocks[i];
                if (i > 0) block.text += ' ';
                const offset = block.text.length;
                block.text += nested.text;
                for (const style of nested.inlineStyleRanges) {
                    style.offset += offset;
                    block.inlineStyleRanges?.push(style);
                }
            }

            return block;
        }
        case DraftEntityDataTypes.FORM_STEP: {
            const step = cache.form.steps[entityData.idStep];
            if (!step) return null;
            const text = formStepToString(
                step,
                cache.dependencies[step.id]?.value,
                mapToId
            );
            if (text === null) return null;
            return { text, inlineStyleRanges: [] };
        }
        case DraftEntityDataTypes.DATE: {
            const millis =
                entityData.days * 86400000 +
                entityData.hours * 3600000 +
                entityData.minutes * 60000;
            const subtract = false;
            const date = new Date(
                new Date().getTime() + (subtract ? -millis : millis)
            );
            return {
                text: format(date, entityData.format ?? 'Pp', {
                    locale: getLocale(),
                }),
            };
        }
        default:
            break;
    }
    return null;
};

function calcTicketProperty(
    cache: FormCache,
    entityData: TicketDraftEntityData
): string | null {
    const property = entityData.property;
    switch (property) {
        case TicketPropertyTypes.CASENUMBER:
            return cache.ticket?.caseNumber ?? null;
        default:
            break;
    }
    return null;
}

function calcEntityProperty(
    property: EntityProperty,
    value: any | undefined
): string | null {
    if (!value) return null;
    switch (property.type) {
        case EntityPropertyTypes.TEXTAREA:
            return value.value;
        case EntityPropertyTypes.NAME:
        case EntityPropertyTypes.TEXTINPUT:
        case EntityPropertyTypes.SELECTOR:
            return value;
        case EntityPropertyTypes.DATEPICKER:
            return format(new Date(value), property.pickTime ? 'Pp' : 'P', {
                locale: getLocale(),
            });
        case EntityPropertyTypes.CHECKBOX:
            return value ? 'Si' : 'No';
        default:
            break;
    }
    return null;
}

type DraftBlock = EurekaDraft['blocks'][0];

/**
 * The function will generate text for given draftjs editorContent.
 */
export function draftToString(
    cache: FormCache,
    draft: EurekaDraft,
    mapToId = false
): string {
    const text: string[] = [];
    if (draft) {
        const { blocks, entityMap }: EurekaDraft = JSON.parse(
            JSON.stringify(draft)
        );
        if (blocks && blocks.length > 0) {
            blocks.forEach((block) => {
                let content = getBlockText(
                    cache,
                    block,
                    entityMap,
                    blocks.length === 1,
                    mapToId
                );
                if (isList(block.type)) {
                    content = getDepthPadding(block.depth) + content;
                }
                text.push(content);
            });
        }
    }
    const response = text.join('');
    if (response === '\n') {
        return '';
    }
    return response;
}

/**
 * Function will return text for the block.
 */
function getBlockText(
    cache: FormCache,
    block: DraftBlock,
    entityMap: EurekaDraft['entityMap'],
    skipNextLine = false,
    mapToId = false
): string {
    const blockMarkdown = [];
    blockMarkdown.push(getBlockContentText(cache, block, entityMap, mapToId));
    if (!isAtomicBlock(block) && !skipNextLine) blockMarkdown.push('\n');
    return blockMarkdown.join('');
}

/**
 * Function will return the text for block content.
 */
function getBlockContentText(
    cache: FormCache,
    block: DraftBlock,
    entityMap: EurekaDraft['entityMap'],
    mapToId = false
): string {
    if (isAtomicBlock(block)) {
        return getEntityText(entityMap, block.entityRanges[0].key, block.text);
    }
    setBlockEntities(cache, block, entityMap, mapToId);
    return block.text.replace('\n', '\\s\\s\n');
}

/**
 * Function to check if a block is of type list.
 */
export function isList(blockType: DraftBlock['type']): boolean {
    return (
        blockType === 'unordered-list-item' || blockType === 'ordered-list-item'
    );
}

/**
 * Function will return text for Entity.
 */
export function getEntityText(
    entityMap: EurekaDraft['entityMap'],
    entityKey: number,
    blockText: string
): string {
    const entity = entityMap[entityKey];
    if (entity && entity.type === 'EUREKA') {
        return blockText;
    }
    return '';
}

/**
 * Function to check if the block is an atomic entity block.
 */
export function isAtomicBlock(block: DraftBlock): boolean {
    if (block.entityRanges.length > 0 && isEmptyString(block.text)) {
        return true;
    }
    return false;
}

/**
 * The function returns true if the string passed to it has no content.
 */
export function isEmptyString(str: string): boolean {
    if (
        str === undefined ||
        str === null ||
        str.length === 0 ||
        str.trim().length === 0
    ) {
        return true;
    }
    return false;
}

export function getDepthPadding(depth: number): string {
    let padding = '';
    for (let i = 0; i < depth * 4; i += 1) {
        padding += ' ';
    }
    return padding;
}

export function formStepToString(
    step: FormStep,
    value: any | undefined,
    mapToId = false
): string | null {
    if (value === undefined || value === null) return null;
    switch (step.type) {
        case FormStepTypes.TIMEPICKER: {
            const time = value as Time;
            const parts = [];
            if (time.days) {
                parts.push(`${time.days} día${time.days > 1 ? 's' : ''}`);
            }
            if (time.hours) {
                parts.push(`${time.hours} hora${time.hours > 1 ? 's' : ''}`);
            }
            if (time.minutes) {
                parts.push(
                    `${time.minutes} minuto${time.minutes > 1 ? 's' : ''}`
                );
            }
            if (parts.length === 0)
                return time.working
                    ? 'horario hábil más cercano'
                    : 'inmediatamente';
            if (time.working) {
                const isMultiple = parts.length > 1 || parts[0].includes('s');
                return parts.join(', ') + (isMultiple ? ' hábiles' : 'hábil');
            }
            return parts.join(', ');
        }
        case FormStepTypes.TEXTAREA:
            return value.value;
        case FormStepTypes.TEXTINPUT:
        case FormStepTypes.SELECTOR:
            return value;
        case FormStepTypes.DATEPICKER:
            return new Date(value).toUTCString();
        case FormStepTypes.CHECKBOX:
            return value ? 'Sí' : 'No';
        case FormStepTypes.ENTITYVALUEPICKER:
            if (mapToId && value._id) return value._id;
            if (value.label) return value.label;
            return null;
        case FormStepTypes.CLASSIFIER_SELECTOR:
            return value;
        case FormStepTypes.API_SELECTOR:
            if (mapToId && value[step.idAttribute])
                return value[step.idAttribute];
            if (value[step.labelAttribute]) return value[step.labelAttribute];
            return null;
        case FormStepTypes.FILEUPLOAD: {
            return value
                .map((file: any) => file.fileName ?? 'Archivo')
                .join(', ');
        }
        case FormStepTypes.RATING:
            switch (step.ratingType) {
                case RatingTypes.LIKE:
                    return value ? 'Si' : 'No';
                case RatingTypes.SATISFACTION:
                    switch (value) {
                        case 1:
                            return 'Muy Insatisfecho';
                        case 2:
                            return 'Insatisfecho';
                        case 3:
                            return 'Satisfecho';
                        case 4:
                            return 'Muy Satisfecho';
                        default:
                            return null;
                    }
                default:
                    break;
            }
            return value;
        default:
            break;
    }
    return null;
}
