import { WidthStats } from '../Form/Form';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: WidthStats = {
    currentBreakPoint: 0,
    isMobile: false,
    isResponsive: false,
};

export const WidthStatsSlice = createSlice({
    name: 'widthStats',
    initialState,
    reducers: {
        updateWidthStats: (state, { payload }: PayloadAction<WidthStats>) => {
            if (
                payload.isMobile !== state?.isMobile ||
                payload.isResponsive !== state?.isResponsive ||
                payload.currentBreakPoint !== state?.currentBreakPoint
            )
                return payload;
            else return state;
        },
    },
});

export const { updateWidthStats } = WidthStatsSlice.actions;

export default WidthStatsSlice;
