import { TextInput } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialTextInputStep from './MaterialTextInputStep/MaterialTextInputStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
export interface TextInputStepProps extends StepProps {
    /** The TextInputStep to display */
    step: TextInput;
    /** The icon to display */
    icon?: React.ReactNode;
    /** Default value to display */
    defaultValue?: string;
    /** Custom Validation to display */
    validation?: TextInput['validation'];
    /** Custom step Max char length */
    maxLength?: number;
}
function TextInputStep(props: TextInputStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialTextInputStep {...props} />;
        }
    }
}
export default TextInputStep;
