import { Title } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialTitleStep from './MaterialTitleStep/MaterialTitleStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';

export interface TitleStepProps extends StepProps {
    /** The TitleStep to display */
    step: Title;
}
function TitleStep(props: TitleStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialTitleStep {...props} />;
        }
    }
}
export default TitleStep;
