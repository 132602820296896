export enum ConditionTypes {
    EXPRESSION = 'EXPRESSION',
    FORM_STEP = 'FORM_STEP',
    ENTITYVALUE = 'ENTITYVALUE',
}
export default ConditionTypes;

export enum ExpressionTypes {
    'OR' = 'OR',
    'AND' = 'AND',
}

/**
 * Operators available to all conditions
 */
export enum OperatorTypes {
    EQUAL = 'EQUAL',
    NOTEQUAL = 'NOTEQUAL',
    INCLUDES = 'INCLUDES',
    NOTINCLUDES = 'NOTINCLUDES',
    LESS = 'LESS',
    MORE = 'MORE',
    PAST_RELATIVE_LESS = 'PAST_RELATIVE_LESS',
    PAST_RELATIVE_MORE = 'PAST_RELATIVE_MORE',
    FUTURE_RELATIVE_LESS = 'FUTURE_RELATIVE_LESS',
    FUTURE_RELATIVE_MORE = 'FUTURE_RELATIVE_MORE',
    EXISTS = 'EXISTS',
    NOTEXISTS = 'NOTEXISTS',
}
