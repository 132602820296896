import { useContext } from 'react';
import { GSmartSelect } from '../../../@Types/GenericFormSteps';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector, selectBreakPoint } from '../../../hooks';
import { calcStepWidth } from '../../StepFunctions';
import styles from './MaterialInputContainer.module.css';
import {
    CheckBox,
    DatePicker,
    ClassifierSelector,
    FormSelector,
    TimePicker,
} from '../../../@Types/FormStep';

interface MaterialInputProps {
    step:
        | GSmartSelect
        | CheckBox
        | ClassifierSelector
        | DatePicker
        | TimePicker
        | FormSelector;
    editable: boolean;
    onClick?: () => void;
    children: React.ReactNode;
    maxHeight?: boolean;
}
function MaterialInputContainer({
    step,
    editable,
    children,
    onClick,
    maxHeight = true,
}: MaterialInputProps): JSX.Element {
    const form = useContext(FormContext);
    const currentBreakPoint = useAppSelector(selectBreakPoint);
    const postview = useAppSelector((state) => state.global.postview);
    return (
        <div
            className={styles.container}
            style={{
                width:
                    currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight: maxHeight
                    ? step.description ||
                      (!postview && editable && step.required)
                        ? '55px'
                        : '43px'
                    : undefined,
            }}
            onClick={onClick}
            data-testid={step.id}
        >
            {children}
        </div>
    );
}

export default MaterialInputContainer;
