import { useCallback, useEffect, useState } from 'react';
import { Form, Organization } from '../@Types';
import { AppProps } from './App';
import { Branding } from '../@Types/Branding';
import { reset } from '../States/GlobalSlice';
import { fetchOrganization } from '../Services/OrganizationService';
import { fetchForm } from '../Services/FormService';
import { FormStep } from '../@Types/FormStep';
import { calcCbrForm } from '../Utils/CBRFunctions';
import { calcInitialSections, calcValuesStore } from './AppFunctions';
import { useAppDispatch } from '../hooks';
import InternalFormStyle from '../constants/InternalFormStyle';
import { calcDependencies } from '../Form/FormFunctions';
import { iterateNestedSteps } from '../FormSteps/StepFunctions';
import { createNextState } from '@reduxjs/toolkit';
import { BaseConfirmationMessage } from '../Contexts/FormContext';

export const useSetupApp = (
    isEmbedded: boolean,
    {
        preview,
        internal,
        postview,
        partial,
        editable,
        handleConfirmed,
        customSteps,
        valuesData,
        apiKey,
        formData,
        classifiers,
        ...others
    }: Omit<AppProps, 'isWidget'>
): {
    form: Form | undefined | null;
    organization: Organization | undefined | null;
    branding: Branding | undefined;
    reload: () => void;
} => {
    const [form, setForm] = useState<Form | undefined | null>(undefined);
    const [organization, setOrganization] = useState<
        Organization | undefined | null
    >(undefined);
    const dispatch = useAppDispatch();

    const loadOrg = async (
        idOrganization?: string
    ): Promise<Organization | null> => {
        const organization = await fetchOrganization(idOrganization);
        if (organization) {
            if (!isEmbedded) {
                // document.title = currentOrg.name;
                // const favicon: any = document.getElementById('favicon');
                // if (favicon !== undefined) {
                //     favicon.href = currentOrg.partialLogoUrl;
                // }
                //TODO cambiar el color del navbar en movil
                // eslint-disable-next-line no-console
            }
        }
        return organization;
    };

    const loadForm = async (
        apiKey: string,
        idOrganization?: string
    ): Promise<Form | null> => {
        const form = await fetchForm(apiKey, idOrganization);
        return form;
    };

    const loadData = async (): Promise<void> => {
        let idOrganization = others.idOrganization;
        let organization: Organization | null = null;
        let form: Form | null = formData ?? null;
        if (form && preview && form.isStandAlone) {
            organization = await loadOrg();
        } else if (!form && apiKey) {
            //Is iframe or widget
            [organization, form] = await Promise.all([
                loadOrg(idOrganization),
                loadForm(apiKey, idOrganization),
            ]);
        }

        if (organization && !idOrganization)
            idOrganization = organization.idOrganization;
        setOrganization(organization);

        const countryCode =
            others.countryCode ?? organization?.countryCode ?? 'CO';

        /** For localhost development */
        if (!idOrganization && process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-restricted-globals
            const match = location.origin.match(/\/\/(.*?)\./);
            idOrganization = match ? match[1] : '';
        }
        if (form) {
            form = createNextState(form, (form) => {
                const firstSection = form.sections[form.firstSection];
                if (
                    firstSection.steps === undefined &&
                    (form as any).rootSteps !== undefined
                ) {
                    firstSection.steps = (form as any).rootSteps;
                }
                //If a rootStep is a mapper of type section
                if (form && classifiers) form.classifiers = classifiers;
                /** Assign the idSection to all the forms steps */
                for (const section of Object.values(form.sections)) {
                    for (const idStep of section.steps) {
                        iterateNestedSteps(
                            idStep,
                            form.steps,
                            (step: FormStep) => {
                                step.idSection = section.id;
                            }
                        );
                    }
                }

                const CBRStep = Object.values(form.steps).find(
                    (step: FormStep): boolean => step.type.startsWith('CBR')
                );
                if (CBRStep) form = calcCbrForm(form, valuesData ?? {});
            });
        }
        setForm(form);
        if (!form || !idOrganization) return;
        const dependencies = {};
        const values = await calcValuesStore(
            { idOrganization, countryCode },
            form,
            valuesData,
            postview,
            customSteps
        );
        dispatch(
            reset({
                formStyle: internal
                    ? InternalFormStyle
                    : {
                          ...InternalFormStyle,
                          ...(form?.style ?? {}),
                      },
                confirmation: {
                    confirmationMessage:
                        form.confirmationMessage ?? BaseConfirmationMessage,
                    showLink: form.showLink ?? false,
                },
                internal: !!internal,
                idOrganization,
                preview: !!preview,
                partial: !!partial,
                postview: !!postview,
                editable: !!(editable ?? true),
                values,
                ...calcInitialSections(form),
                dependencies: calcDependencies(
                    form.steps,
                    customSteps,
                    form.steps,
                    dependencies,
                    values
                ),
                countryCode,
            })
        );
    };

    useEffect(() => {
        loadData();
        return () => {
            dispatch({ type: 'CLEAR' });
        };
    }, []);

    const reload = useCallback(() => {
        if (!form) return;
        const tempForm = { ...form };
        setForm(undefined);
        setTimeout(() => {
            setForm(tempForm);
        }, 100);
        if (handleConfirmed) {
            handleConfirmed();
        }
    }, [form]);
    return {
        form,
        organization,
        branding: organization?.customBrandings
            ? form?.branding ?? organization.branding
            : organization?.branding,
        reload,
    };
};

export const useLogRocket = (
    organization: Organization | null | undefined,
    form: Form | null | undefined,
    {
        apiKey,
        internal,
        postview,
        preview,
    }: Pick<AppProps, 'apiKey' | 'internal' | 'postview' | 'preview'>
): void => {
    const loadLogRocket = async (): Promise<void> => {
        if (
            form &&
            organization &&
            process.env.NODE_ENV === 'production' &&
            //Tools
            // apiKey !== 'rCgWiEfOSN1TlUmHO28Y0' &&
            !internal &&
            !postview &&
            !preview
        ) {
            try {
                const LogRocket = await import('logrocket');
                LogRocket.default.init('63mg8a/forms-uv0gd');
                LogRocket.default.identify(
                    organization.idOrganization + '/' + apiKey,
                    {
                        name:
                            organization.name +
                            '/' +
                            (form?.name ? form?.name : '404'),
                    }
                );
            } catch (error) {
                console.error('LogRocker Error', error);
            }
        }
    };

    useEffect(() => {
        loadLogRocket();
    }, [organization, form]);
};
