import styles from './MaterialRatingStep.module.css';
import { RatingStepProps } from '../RatingStep';
import React, { useContext } from 'react';
import Rating from '../../../Shared/Rating/Rating';
import { RatingTypes } from '../../../constants/FormStepTypes';
import StepComponent from '../../Step';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
import { calcDefaultValue } from '../../StepFunctions';

function RatingStep({ step, editable }: RatingStepProps): JSX.Element {
    const { ref, value, onChange, error } = useFormStep<
        undefined | null | number
    >(step, {
        defaultValue: calcDefaultValue(step),
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    const { formStyle, postview } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);
    const widthStats = useAppSelector((state) => state.widthStats);

    const renderNestedOption = (): JSX.Element | void => {
        let currentOption;
        if (value !== undefined && value !== null && step.nestedSteps) {
            if (step.ratingType === RatingTypes.LIKE) {
                currentOption = step.nestedSteps[value];
            } else {
                currentOption = step.nestedSteps[value - 1];
            }
            if (currentOption && currentOption.length > 0) {
                return (
                    <React.Fragment>
                        {currentOption.map((idSubStep: string) => {
                            const subStep = form.steps[idSubStep];
                            return (
                                <StepComponent
                                    editable={editable}
                                    step={subStep}
                                    key={idSubStep}
                                />
                            );
                        })}
                    </React.Fragment>
                );
            }
        }
    };
    return (
        <React.Fragment>
            <div
                className={styles.container}
                style={{
                    paddingBottom: step.description || step.required ? 0 : 15,
                }}
            >
                <div className={styles.labelLabel}>
                    {step.label + (step.required ? ' *' : '')}
                </div>
                <div className={styles.ratingContainer}>
                    <Rating
                        type={step.ratingType}
                        isMobile={widthStats.isMobile}
                        focusColor={formStyle.primaryColor ?? '#3d5a7f'}
                        unSelectedColor={formStyle.outlineColor ?? '#b8b8b8'}
                        disabled={!editable || postview}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                    />
                </div>
                {(step.description || step.required) && (
                    <div
                        className={styles.stepDescriptionLabel}
                        style={{
                            color:
                                !!error && value === null
                                    ? formStyle.errorColor ?? '#cc2936'
                                    : formStyle.descriptionTextColor ??
                                      '#989898',
                        }}
                    >
                        {!!error && value === null
                            ? error?.message
                            : step.description}
                    </div>
                )}
            </div>
            {renderNestedOption()}
        </React.Fragment>
    );
}

export default RatingStep;
