import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GBaseStep } from '../@Types/GenericFormSteps';
import { useAppSelector, useAppDispatch } from '../hooks';
import {
    ValuesStore,
    clearDependency,
    setStepDependency,
} from '../States/SiteSlice';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../Utils/store';
import { Form } from '../@Types';
import { DependencyStore } from '../Form/Form';
import { calcDefaultValue, evaluateCondition } from './StepFunctions';
import { FormStep } from '../@Types/FormStep';
import {
    FieldError,
    RefCallBack,
    UseControllerProps,
    useController,
} from 'react-hook-form';
import { SizeChangeContext } from './Utils/@StepFiller/StepFiller';
import { Condition } from '../@Types/Condition';
import ConditionTypes from '../constants/ConditionTypes';
import FormStepTypes, {
    ApiSelectorOptionTypes,
    ClassifierOptionTypes,
    EntityValueOptionTypes,
} from '../constants/FormStepTypes';
import { CBRFormStep } from '../@Types/CBRFormStep';

const selectIsDependency = createSelector(
    [
        (state: RootState): DependencyStore => state.site.dependencies,
        (state: RootState, step: GBaseStep): GBaseStep => step,
    ],
    (dependencies, step) => {
        return dependencies[step.id] !== undefined;
    }
);

export const selectOriginalValue = createSelector(
    [
        (state: RootState): ValuesStore => state.site.values,
        (state: RootState, step: GBaseStep): GBaseStep => step,
    ],
    (values, step) => values.sections[step.idSection]?.[step.id]
);

export interface StepDependency {
    originalValue: any;
    isDependency: boolean;
    handleStepDep: (value: any | undefined) => void;
}

export const useStepDependency = (
    step: GBaseStep,
    defaultValue?: any
): StepDependency => {
    const isDependency = useAppSelector((state) =>
        selectIsDependency(state, step)
    );
    const originalValue = useAppSelector((state) =>
        selectOriginalValue(state, step)
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isDependency && !originalValue && defaultValue) {
            dispatch(
                setStepDependency({
                    step,
                    value: defaultValue,
                })
            );
        }
        return () => {
            dispatch(clearDependency(step));
        };
    }, [isDependency]);

    const handleStepDep = useCallback(
        (value: any | undefined) => {
            if (!isDependency) return;
            dispatch(
                setStepDependency({
                    step,
                    value: value ?? null,
                })
            );
        },
        [step.id, step.type, isDependency]
    );

    return {
        handleStepDep,
        isDependency,
        originalValue:
            originalValue ?? defaultValue ?? calcDefaultValue(step as FormStep),
    };
};

export const selectStepDependencies = createSelector(
    [
        (state: RootState): DependencyStore => state.site.dependencies,
        (state: RootState, step: GBaseStep): GBaseStep => step,
        (state: RootState, step: GBaseStep, form: Form): Form => form,
    ],
    (dependencies, step, form) => {
        const invalids: string[] = [];
        let emptyDep = false;
        /** Show deep error changes even if not in dependencies */
        for (const idDep of calcDeepDependencies(step.id, form)) {
            const dependency = dependencies[idDep];
            if (dependency?.value === null && form.steps[idDep]) {
                invalids.push(idDep);
            }
            if (dependency?.empty) emptyDep = true;
        }
        return { invalids, emptyDep };
    }
);

const calcDeepDependencies = (
    idStep: string,
    form: Form,
    deps: string[] = []
): any[] => {
    const step = form.steps[idStep];
    if (step?.dependencies) {
        for (const dep of step.dependencies) {
            if (!deps.includes(dep)) {
                deps.push(...calcDeepDependencies(dep, form, deps), dep);
            }
        }
    }
    return deps;
};

export interface UseFormStepOptions<ValueType> {
    defaultValue: ValueType;
    /** If true, will not trigger onChange until the user has finished typing */
    debounce?: boolean;
    rules?: UseControllerProps['rules'];
    /** If the step should trigger a filler(root StepFiller) size change */
    sizeChange?: boolean;
}

export interface FormStepFunctions<ValueType = unknown> {
    value: ValueType;
    ref: RefCallBack;
    onChange: (value: ValueType) => void;
    error: FieldError | undefined;
    field: Omit<
        ReturnType<typeof useController>['field'],
        'ref' | 'value' | 'onChange'
    >;
}

export const useFormStep = <ValueType = unknown>(
    step: GBaseStep,
    { rules, debounce, sizeChange, defaultValue }: UseFormStepOptions<ValueType>
): FormStepFunctions<ValueType> => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

    const { isDependency, handleStepDep, originalValue } = useStepDependency(
        step,
        defaultValue
    );

    const {
        field: { ref, value, onChange: fieldOnChange, ...field },
        fieldState: { error },
    } = useController({
        name: step.id,
        rules,
        shouldUnregister: true,
        defaultValue: originalValue,
    });

    const handleSizeChange = useContext(SizeChangeContext);

    const onChange = useCallback(
        (value: ValueType): void => {
            if (isDependency) {
                if (debounce) {
                    try {
                        clearTimeout(timer);
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                    setTimer(
                        setTimeout(() => {
                            handleStepDep(value);
                            setTimer(undefined);
                        }, 1000)
                    );
                } else {
                    handleStepDep(value);
                }
            }
            fieldOnChange(value);
            if (sizeChange) handleSizeChange?.();
        },
        [handleStepDep, isDependency, fieldOnChange]
    );

    return {
        ref,
        value,
        onChange,
        error,
        field,
    };
};

const EMPTY_ARRAY: [] = [];

export const selectDependencies = createSelector(
    [
        (state: RootState): DependencyStore => state.site.dependencies,
        (state: RootState, idDeps: string[] | undefined): string[] =>
            idDeps ?? EMPTY_ARRAY,
    ],
    (dependencies, idDeps): DependencyStore =>
        idDeps.reduce(
            (acc, idDep) => ({
                ...acc,
                [idDep]: dependencies[idDep],
            }),
            {}
        )
);

export const useCondition = (condition?: Condition): boolean => {
    const ids = useMemo(() => recursivelyCalcConditionSteps(condition), []);
    const dependencies = useAppSelector((state) =>
        selectDependencies(state, ids)
    );
    return useMemo(
        () => {
            return !condition || evaluateCondition(condition, dependencies);
        },
        ids.map((id) => dependencies[id].value)
    );
};

export const calcStepDeps = (step: FormStep | CBRFormStep): string[] => {
    const dependencies = [...(step.dependencies ?? [])];
    if (step.condition) {
        dependencies.push(...recursivelyCalcConditionSteps(step.condition));
    }
    switch (step.type) {
        case FormStepTypes.CLASSIFIER_SELECTOR:
            {
                for (const option of Object.values(step.options)) {
                    if (
                        option.type !== ClassifierOptionTypes.HIDE &&
                        option.condition
                    ) {
                        dependencies.push(
                            ...recursivelyCalcConditionSteps(option.condition)
                        );
                    }
                }
            }
            break;
        case FormStepTypes.ENTITYVALUEPICKER:
            {
                for (const option of Object.values(step.options)) {
                    if (
                        option.type !== EntityValueOptionTypes.HIDE &&
                        option.condition
                    ) {
                        dependencies.push(
                            ...recursivelyCalcConditionSteps(option.condition)
                        );
                    }
                }
                if (step.dialogs)
                    for (const dialog of Object.values(step.dialogs)) {
                        dependencies.push(
                            ...recursivelyCalcConditionSteps(dialog.condition)
                        );
                    }
            }
            break;
        case FormStepTypes.API_SELECTOR:
            {
                for (const option of Object.values(step.options)) {
                    if (
                        option.type !== ApiSelectorOptionTypes.HIDE &&
                        option.condition
                    ) {
                        dependencies.push(
                            ...recursivelyCalcConditionSteps(option.condition)
                        );
                    }
                }
            }
            break;
        case FormStepTypes.SELECTOR:
            {
                for (const option of Object.values(step.options)) {
                    if (option.condition) {
                        dependencies.push(
                            ...recursivelyCalcConditionSteps(option.condition)
                        );
                    }
                }
            }
            break;

        default:
            break;
    }
    return dependencies;
};

export const recursivelyCalcConditionSteps = (
    condition: Condition | undefined
): string[] => {
    if (!condition) return [];
    switch (condition.type) {
        case ConditionTypes.EXPRESSION: {
            return condition.conditions
                .map((condition) => recursivelyCalcConditionSteps(condition))
                .flat();
        }
        case ConditionTypes.FORM_STEP: {
            return [condition.idStep];
        }
        case ConditionTypes.ENTITYVALUE: {
            return [];
        }
    }
};
