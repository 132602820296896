enum CBRFormStepTypes {
    CBR_MACRO_PROYECTO = 'CBR_MACRO_PROYECTO',
    CBR_POSTVENTA = 'CBR_POSTVENTA',
    CBR_FIRST_NAME = 'CBR_FIRST_NAME',
    CBR_MIDDLE_NAME = 'CBR_MIDDLE_NAME',
    CBR_LAST_NAME = 'CBR_LAST_NAME',
    CBR_SECOND_LAST_NAME = 'CBR_SECOND_LAST_NAME',
    CBR_DOC = 'CBR_DOC',
    CBR_CEL = 'CBR_CEL',
    CBR_PHONE = 'CBR_PHONE',
    CBR_EMAIL = 'CBR_EMAIL',
    CBR_PROYECTO = 'CBR_PROYECTO',
    CBR_TIPO_DOC = 'CBR_TIPO_DOC',
    CBR_TIPO_SOLICITANTE = 'CBR_TIPO_SOLICITANTE',
    CBR_INMUEBLE = 'CBR_INMUEBLE',
    CBR_TIPO_ESPACIO = 'CBR_TIPO_ESPACIO',
    CBR_LOCATIVAS = 'CBR_LOCATIVAS',
    CBR_INCIDENCIAS = 'CBR_INCIDENCIAS',
    CBR_COMENTARIO = 'CBR_COMENTARIO',
}
export default CBRFormStepTypes;
