import RoundedTextField from '../../../Shared/RoundedTextField/RoundedTextField';
import styles from './MaterialTextInputStep.module.css';
import { TextInputStepProps } from '../TextInputStep';
import { calcStepWidth } from '../../StepFunctions';
import { useContext } from 'react';
import FormContext from '../../../Contexts/FormContext';
import { selectBreakPoint, useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
function TextInputStep({
    icon,
    step,
    editable,
    maxLength,
    defaultValue = step.defaultValue,
    validation = step.validation,
}: TextInputStepProps): JSX.Element {
    const currentBreakPoint = useAppSelector(selectBreakPoint);
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);

    const { ref, value, onChange, error, field } = useFormStep<string>(step, {
        defaultValue: defaultValue ?? '',
        debounce: true,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
            pattern: validation
                ? {
                      value: new RegExp(
                          validation.type === 'EMAIL'
                              ? // eslint-disable-next-line no-control-regex
                                /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
                              : validation.value
                      ),
                      message: validation.message,
                  }
                : undefined,
        },
    });

    return (
        <div
            className={styles.container}
            style={{
                width:
                    currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight:
                    step.description ||
                    (!postview && editable && (step.required || validation))
                        ? '55px'
                        : '43px',
            }}
        >
            <RoundedTextField
                {...field}
                inputRef={ref}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                maxLength={maxLength}
                data-testid={step.id}
                label={step.label}
                cantEdit={!editable || postview}
                required={step.required}
                fontWeight={400}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                textColor={formStyle.textColor}
                errorColor={formStyle.errorColor}
                backgroundColor={formStyle.stepBackgroundColor}
                helperText={error?.message ?? step.description}
                helperTextColor={formStyle.descriptionTextColor}
                error={!!error}
                icon={step.showIcon ? icon : undefined}
            ></RoundedTextField>
        </div>
    );
}

export default TextInputStep;
