import { SVGProps } from 'react';

const GenericEntityIcon = (props: SVGProps<SVGSVGElement>): any => (
    <svg
        {...props}
        fill={props.fill ?? '#757575'}
        version="1.1"
        width="500pt"
        height="500pt"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10 5H4c-.55 0-1 .45-1 1s.45 1 1 1h2v3c0 .55.45 1 1 1s1-.45 1-1V7h2c.55 0 1-.45 1-1s-.45-1-1-1zM10 2H4c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1zM20.89 13.11a.9959.9959 0 00-1.41 0l-6.36 6.36c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41z"></path>
        <circle cx="14.5" cy="14.5" r="1.5"></circle>
        <circle cx="19.5" cy="19.5" r="1.5"></circle>
        <path d="M15.5 11c1.38 0 2.5-1.12 2.5-2.5V4h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1v3.51c-.42-.32-.93-.51-1.5-.51C14.12 6 13 7.12 13 8.5s1.12 2.5 2.5 2.5zM10.45 18.09c.39-.39.39-1.02 0-1.41a.9959.9959 0 00-1.41 0l-.71.71-.71-.71.35-.35c.98-.98.98-2.56 0-3.54-.49-.49-1.13-.73-1.77-.73-.64 0-1.28.24-1.77.73-.98.98-.98 2.56 0 3.54l.35.35-1.06 1.06c-.98.98-.98 2.56 0 3.54.5.48 1.14.72 1.78.72.64 0 1.28-.24 1.77-.73l1.06-1.06.71.71c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41l-.71-.71.71-.71zm-4.6-3.89c.12-.12.26-.15.35-.15s.23.03.35.15c.19.2.19.51 0 .71l-.35.35-.35-.36c-.12-.12-.15-.26-.15-.35s.03-.23.15-.35zm0 5.65c-.12.12-.26.15-.35.15s-.23-.03-.35-.15c-.12-.12-.15-.26-.15-.35s.03-.23.15-.35l1.06-1.06.71.71-1.07 1.05z"></path>
    </svg>
);
export default GenericEntityIcon;
