import {
    FileObject,
    UploadedFileObject,
    isUploadedFileObject,
} from '../MaterialFileUploadStep';
import styles from './FileComponent.module.css';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Loader from '../../../../Shared/Loader/Loader';
import { FormStyle } from '../../../../@Types/Form';
import { useState } from 'react';

interface FileComponentProps {
    file: FileObject | UploadedFileObject;
    formStyle: FormStyle;
    error: boolean;
    editable: boolean;
    handleRemove: () => void;
    /** Function to call on postview to fetch the download url of a file */
    fetchDownloadUrl?: (S3Key: string, fileName: string) => Promise<string>;
}

function FileUploadComponent({
    file,
    formStyle,
    error,
    editable,
    handleRemove,
    fetchDownloadUrl,
}: FileComponentProps): JSX.Element {
    const [downloading, setDownloading] = useState(false);
    if (!file) {
        return <div></div>;
    }

    const downloadFile = async (): Promise<void> => {
        if (fetchDownloadUrl && file.S3Key && file.fileName) {
            try {
                setDownloading(true);
                const fileUrl = await fetchDownloadUrl(
                    file.S3Key,
                    file.fileName
                );
                const a = document.createElement('a');
                a.href = fileUrl;
                a.setAttribute('download', file.fileName);
                a.click();
                setDownloading(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const calcIcon = (): JSX.Element => {
        if (downloading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={26} color={formStyle.primaryColor} />
                </div>
            );
        } else if ((file as FileObject).state !== undefined) {
            switch ((file as FileObject).state) {
                case 'STARTING':
                case 'UPLOADING':
                    return (
                        <div className={styles.loaderContainer}>
                            <Loader size={26} color={formStyle.primaryColor} />
                        </div>
                    );
                case 'DONE':
                    return (
                        <div
                            className={styles.documentIcon}
                            style={{ color: formStyle.primaryColor }}
                        >
                            <DescriptionRoundedIcon />
                        </div>
                    );
                case 'ERROR':
                    return (
                        <div
                            className={styles.documentIcon}
                            style={{ color: formStyle.errorColor }}
                        >
                            <ErrorRoundedIcon />
                        </div>
                    );
                default:
                    return <div></div>;
            }
        } else {
            return (
                <div
                    className={styles.documentIcon}
                    style={{ color: formStyle.primaryColor }}
                >
                    <DescriptionRoundedIcon />
                </div>
            );
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>{calcIcon()}</div>
            <div
                className={
                    (fetchDownloadUrl && file.S3Key) ||
                    (file as UploadedFileObject).downloadUrl
                        ? styles.downloadableLbl
                        : styles.label
                }
                style={{
                    color: error ? formStyle.errorColor : formStyle.textColor,
                }}
                onClick={(): void => {
                    if (isUploadedFileObject(file) && file.downloadUrl) {
                        const a = document.createElement('a');
                        a.href = file.downloadUrl;
                        a.setAttribute('download', file.fileName);
                        a.click();
                    } else if (fetchDownloadUrl !== undefined && file.S3Key) {
                        downloadFile();
                    }
                }}
            >
                {(file as FileObject).file?.name ?? file.fileName}
            </div>
            {editable && (
                <div
                    className={styles.deletBtn}
                    onClick={(): void => {
                        handleRemove();
                    }}
                >
                    <ClearRoundedIcon fontSize="inherit" />
                </div>
            )}
        </div>
    );
}

export default FileUploadComponent;
