import styles from './MaterialCheckBoxStep.module.css';
import RoundedCheckBox from '../../../Shared/RoundedCheckBox/RoundedCheckBox';
import { CheckBoxStepProps } from '../CheckBoxStep';
import React, { useContext } from 'react';
import StepComponent from '../../Step';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
import StepFillerContainer from '../../Utils/@StepFiller/StepFiller';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';
import { calcStepWidth } from '../../StepFunctions';
function CheckBoxStep({ step, editable }: CheckBoxStepProps): JSX.Element {
    const { ref, value, onChange, error, field } = useFormStep<boolean>(step, {
        defaultValue: step.defaultValue ?? false,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
        sizeChange: true,
    });

    const { formStyle, postview } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);

    const renderNested = (): void | JSX.Element => {
        const steps = value ? step.steps : step.uncheckedSteps;
        return (
            <React.Fragment>
                {steps?.map((idStep: string) => {
                    const subStep = form.steps[idStep];
                    return (
                        <StepComponent
                            editable={editable}
                            step={subStep}
                            key={idStep}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    const renderLabel = (colon = false): JSX.Element => {
        return (
            <div
                className={
                    step.size === 4 ? styles.checkboxLbl : styles.overflowLbl
                }
                style={{
                    maxWidth: calcStepWidth(step.size, form.size) - 45,
                }}
            >
                {step.label}
                {step.required ? ' *' : ''}
                {colon ? ':' : ''}
            </div>
        );
    };

    return (
        <StepFillerContainer step={step}>
            <MaterialInputContainer
                step={step}
                editable={editable}
                maxHeight={step.size < 4}
            >
                <div className={styles.container}>
                    <div
                        className={styles.labelCheckBoxContainer}
                        style={{
                            height: step.size < 4 ? 31 : undefined,
                            minHeight: 31,
                            alignItems: step.isList ? 'start' : 'center',
                        }}
                    >
                        {step.label && !step.isList && renderLabel(true)}
                        <RoundedCheckBox
                            {...field}
                            inputRef={ref}
                            onChange={onChange}
                            data-testid={step.id}
                            padding="0px"
                            size="1.6rem"
                            error={!!error}
                            cantEdit={!editable || postview}
                            checkedColor={formStyle.primaryColor}
                            uncheckedColor={formStyle.outlineColor}
                            checked={value}
                        ></RoundedCheckBox>
                        {step.label && step.isList && renderLabel()}
                    </div>
                    {(step.description || !!error) && (
                        <div
                            className={styles.descriptionPar}
                            style={{
                                color: error
                                    ? formStyle.errorColor
                                    : formStyle.descriptionTextColor,
                            }}
                        >
                            {error
                                ? 'Este campo es obligatorio'
                                : step.description}
                        </div>
                    )}
                </div>
            </MaterialInputContainer>
            {renderNested()}
        </StepFillerContainer>
    );
}

export default CheckBoxStep;
