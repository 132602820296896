import { AYFCityStep, AYFFormStep } from '../@Types/AYFFormStep';
import { CBRElementStep, CBRFormStep } from '../@Types/CBRFormStep';
import { Condition } from '../@Types/Condition';
import { FormStep } from '../@Types/FormStep';
import { MapperElement } from '../@Types/MapperElement';
import { CustomStep } from '../FormSteps/CustomStep';
import AYFFormStepTypes from '../constants/AYFFormStepTypes';
import CBRFormStepTypes from '../constants/CBRFormStepTypes';
import ConditionTypes from '../constants/ConditionTypes';
import FormStepTypes, {
    ClassifierOptionTypes,
    EntityValueDataTypes,
    EntityValueOptionTypes,
    OptionTypes,
} from '../constants/FormStepTypes';

export function calcRecursiveData<Type>(
    element: Readonly<MapperElement<Type>>,
    newSteps: Record<string, FormStep>,
    customSteps: Record<string, CustomStep>
): void {
    if (!newSteps) return;
    for (const [idStep, step] of Object.entries(newSteps)) {
        step.id = idStep;
        if (step.dependencies) {
            for (let i = 0; i < step.dependencies.length; i++) {
                const idDep: string = step.dependencies[i];
                const newId = element.ids[idDep];
                if (newId) {
                    step.dependencies[i] = newId;
                }
            }
        }
        const custom = customSteps[step?.type];
        if (custom?.calcRecursiveData) {
            custom.calcRecursiveData(step, element.ids);
        }
        if (step.condition) {
            calcRecursiveCondition(step.condition, element.ids);
        }
        calcSubSteps(
            step.id,
            newSteps,
            (idStep: string): string => element.ids[idStep] ?? idStep
        );
    }
}

export const calcRecursiveCondition = (
    condition: Condition,
    ids: Record<string, string>
): void => {
    switch (condition.type) {
        case ConditionTypes.EXPRESSION:
            for (const subCondition of condition.conditions) {
                calcRecursiveCondition(subCondition, ids);
            }
            break;
        case ConditionTypes.FORM_STEP: {
            const newId = ids[condition.idStep];
            if (newId) {
                condition.idStep = newId;
            }
            break;
        }
        default:
            break;
    }
};
/**
 * Utility function to calc the substeps of a step
 * @param step step to calc the substeps
 * @param idModifier optional modifier for the ids of the substeps and all other properties
 * @returns list of the ids of the substeps of the step, if modifier returns modified steps
 */
export const calcSubSteps = (
    idStep: string,
    allSteps: Record<string, FormStep | CBRFormStep | AYFFormStep>,
    idModifier?: (idSubStep: string) => string
): string[] => {
    const step = allSteps[idStep];
    const subSteps: string[] = [];
    if (!step) return [];
    const handleSteps = (steps: string[]): void => {
        for (let i = 0; i < steps.length; i++) {
            const idSubStep = steps[i];
            if (idModifier) steps[i] = idModifier(steps[i]);
            subSteps.push(
                idSubStep,
                ...calcSubSteps(idSubStep, allSteps, idModifier)
            );
        }
    };

    switch (step.type) {
        case FormStepTypes.SELECTOR: {
            for (const option of step.options) {
                if (option.type === OptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        }
        case FormStepTypes.CLASSIFIER_SELECTOR:
            for (const idOption of Object.keys(step.options)) {
                const option = step.options[idOption];
                if (option.type === ClassifierOptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        case FormStepTypes.RATING:
            if (step.nestedSteps) {
                for (const steps of step.nestedSteps) {
                    handleSteps(steps);
                }
            }
            break;
        case FormStepTypes.COLLAPSIBLE: {
            handleSteps(step.steps);
            break;
        }
        case FormStepTypes.CHECKBOX: {
            if (step.steps) handleSteps(step.steps);
            if (step.uncheckedSteps) handleSteps(step.uncheckedSteps);
            break;
        }
        case FormStepTypes.ENTITYVALUEPICKER:
            if (idModifier) {
                for (const path of step.path) {
                    if (path.type === EntityValueDataTypes.STEP) {
                        path.idStep = idModifier(path.idStep);
                    }
                }
                for (const filter of step.filters) {
                    if (filter.type === EntityValueDataTypes.STEP) {
                        filter.idStep = idModifier(filter.idStep);
                    }
                }
            }
            for (const idOption of Object.keys(step.options ?? {})) {
                const option = step.options[idOption];
                if (option.type === EntityValueOptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        case CBRFormStepTypes.CBR_LOCATIVAS as any:
            {
                const elementStep: CBRElementStep = step as any;
                if (elementStep.subStep) {
                    if (idModifier)
                        elementStep.subStep = idModifier(elementStep.subStep);
                    subSteps.push(elementStep.subStep);
                }
            }
            break;
        case AYFFormStepTypes.AYF_ICA_CITY as any: {
            const cityStep: AYFCityStep = step as any;
            if (cityStep.idNitStep) {
                if (idModifier)
                    cityStep.idNitStep = idModifier(cityStep.idNitStep);
                subSteps.push(cityStep.idNitStep);
            }
            break;
        }
        default:
            break;
    }
    return subSteps;
};
