import { AYFCityStep, AYFFormStep } from '../@Types/AYFFormStep';
import AYFFormStepTypes from '../constants/AYFFormStepTypes';
import { StepProps } from './Step';
import TextInputStep from './TextInputStep/TextInputStep';
import SmartSelectStep from './SmartSelectStep/SmartSelectStep';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import DatePickerStep from './DatePickerStep/DatePickerStep';
import { GSmartSelect } from '../@Types/GenericFormSteps';
import axios from 'axios';
import { DependencyStore } from '../Form/Form';

interface AYFStepProps extends Omit<StepProps, 'step'> {
    step: AYFFormStep;
}

export interface StepOptions {
    id: string | number;
    label: string;
}

const baseUrl = `https://api.integrations.${process.env.REACT_APP_DOMAIN}/sinco/ayf/`;

function AYFStepMapper(props: AYFStepProps): JSX.Element {
    const getMonths = async (): Promise<StepOptions[]> => {
        return [
            { id: '1', label: 'Enero' },
            { id: '2', label: 'Febrero' },
            { id: '3', label: 'Marzo' },
            { id: '4', label: 'Abril' },
            { id: '5', label: 'Mayo' },
            { id: '6', label: 'Junio' },
            { id: '7', label: 'Julio' },
            { id: '8', label: 'Agosto' },
            { id: '9', label: 'Septiembre' },
            { id: '10', label: 'Octubre' },
            { id: '11', label: 'Noviembre ' },
            { id: '12', label: 'Diciembre' },
        ];
    };

    const getCiudadesOptions = async (
        idOrganization: string | undefined,
        step: GSmartSelect,
        dependencyStore: DependencyStore
    ): Promise<string[] | null> => {
        if (!idOrganization) return null;
        const nit = dependencyStore[(step as AYFCityStep).idNitStep]?.value;
        const params = new URLSearchParams({
            idOrganization,
            idCompany: nit,
            nit: 'true',
        });
        for (const dep of step.dependencies ?? []) {
            const value = dependencyStore[dep]?.value;
            if (value && dep.startsWith('AYF')) {
                switch (dep.split('-')?.[0]) {
                    case AYFFormStepTypes.AYF_START_MONTH:
                        params.set('startMonth', value.id);
                        break;
                    case AYFFormStepTypes.AYF_END_MONTH:
                        params.set('endMonth', value.id);
                        break;
                    case AYFFormStepTypes.AYF_YEAR:
                        params.set('year', value);
                        break;
                    default:
                        break;
                }
            }
        }
        try {
            return (
                await axios.get(baseUrl + 'CiudadesICA?' + params.toString())
            ).data;
            // eslint-disable-next-line no-empty
        } catch (error) {
            return null;
        }
    };

    switch (props.step.type) {
        case AYFFormStepTypes.AYF_START_MONTH:
        case AYFFormStepTypes.AYF_END_MONTH:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step as any}
                    getOptions={getMonths}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    defaultValue={
                        props.step.type === AYFFormStepTypes.AYF_START_MONTH
                            ? { id: '1', label: 'Enero' }
                            : { id: '12', label: 'Diciembre' }
                    }
                    getValueString={(value): string => value?.id}
                    icon={
                        <CalendarTodayRoundedIcon
                            fontSize="inherit"
                            style={{
                                color: '#757575',
                                fontSize: 20,
                                marginRight: 4,
                                marginTop: 2,
                            }}
                        />
                    }
                />
            );

        case AYFFormStepTypes.AYF_YEAR:
            return (
                <TextInputStep
                    {...props}
                    step={props.step as any}
                    defaultValue={new Date().getFullYear().toString()}
                />
            );
        case AYFFormStepTypes.AYF_BILL_NUM:
            return (
                <TextInputStep
                    {...props}
                    step={props.step as any}
                    validation={{
                        value: '^\\S*$',
                        type: 'OTHER',
                        message: 'No se permiten espacios',
                    }}
                    maxLength={30}
                    icon={
                        <DescriptionIcon
                            style={{ color: '#757575', fontSize: 23 }}
                        />
                    }
                />
            );
        case AYFFormStepTypes.AYF_CUT_OFF_DATE:
            return (
                <DatePickerStep
                    {...props}
                    step={props.step as any}
                    defaultValue={new Date()}
                />
            );
        case AYFFormStepTypes.AYF_ICA_CITY:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step as any}
                    getOptions={getCiudadesOptions}
                    getOptionSelected={(option, value): boolean =>
                        option === value
                    }
                    getValueString={(value): string => value}
                    icon={
                        <LocationOnRoundedIcon
                            fill="#757575"
                            style={{
                                display: 'flex',
                                height: 23,
                                width: 23,
                                marginRight: 3,
                                marginTop: 1,
                            }}
                        />
                    }
                />
            );
        default:
            return <div></div>;
    }
}

export default AYFStepMapper;
