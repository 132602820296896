import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialSmartSelectStep from './MaterialSmartSelectStep/MaterialSmartSelectStep';
import { StepProps } from '../Step';
import { GSmartSelect } from '../../@Types/GenericFormSteps';
import { FormStep } from '../../@Types/FormStep';
import { DependencyStore } from '../../Form/Form';
import { useAppSelector } from '../../hooks';
export interface SmartSelectStepProps<
    StepType extends GSmartSelect = GSmartSelect,
> extends Omit<StepProps, 'step'> {
    /** The SmartSelectStep to display */
    step: StepType;
    /** The icon to display */
    icon?: React.ReactNode;
    /** Function to call to get the options of the selector, return null if dependency is not met */
    getOptions: (
        idOrganization: string | undefined,
        step: StepType,
        dependencyStore: DependencyStore
    ) => Promise<any[] | null>;
    /** Function to call to filter the options of the selector */
    filterOptions?: (options: any[], dependencyStore: DependencyStore) => any[];
    /** Function to determine the currently selected option */
    getOptionSelected: (option: any, value: any) => boolean;
    /** Function to determine the current value in string format */
    getValueString: (value: any | undefined) => string;
    /** Function to if currently selected option has warning */
    getValueWarning?: (value: any | undefined) => string | null;
    /** Function called to get the idSteps of the step's conditions for better refreshing */
    getOptionsConditionsIdSteps?: (step: StepType) => string[];
    /** Function that returns the error message based on the steps of the missing Dependencies, undefined if no error msg */
    calcDepError?: (steps: FormStep[]) => string | undefined;
    /** Function that overwrites the value before setting it */
    valueOverwrite?: (value: any | null) => any;
    /** Function called on each value change */
    changeListener?: (value: any) => void;
    /** default Value to select */
    defaultValue?: any;
    /** function called to render nested elements when selected */
    renderNestedSteps?: (value: any) => JSX.Element;
}
function SmartSelectStep(props: SmartSelectStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialSmartSelectStep {...props} />;
        }
    }
}
export default SmartSelectStep;
