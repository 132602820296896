import { Form } from '../@Types';
import { Branding } from '../@Types/Branding';
import axiosInstance from '../Utils/AxiosAPI';
import widgetInstance from '../Utils/AxiosWidget';

export async function fetchForm(
    apiKey: string,
    idOrganization?: string
): Promise<(Form & { branding?: Branding }) | null> {
    try {
        const response = await (idOrganization
            ? widgetInstance.get(
                  `/form/${apiKey}?idOrganization=${idOrganization}`
              )
            : axiosInstance.get(`/form/${apiKey}`));
        return response.data;
    } catch (error) {
        return null;
    }
}
