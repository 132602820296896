import { useAppSelector } from '../../hooks';
import { Collapsible } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialCollapsibleStep from './MaterialTitleStep/MaterialCollapsibleStep';
export interface CollapsibleStepProps extends StepProps {
    /** The CollapsibleStep to display */
    step: Collapsible;
}
function CollapsibleStep({
    step,
    ...others
}: CollapsibleStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    if (step.steps.length === 0) return <></>;
    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialCollapsibleStep step={step} {...others} />;
        }
    }
}
export default CollapsibleStep;
