import { StepProps } from './Step';
import FormContext from '../Contexts/FormContext';
import { useAppSelector } from '../hooks';
import { Form } from '../@Types';
import { GlobalState } from '../States/GlobalSlice';
import CustomStepPropsContext from '../Contexts/CustomContext';
import { useContext } from 'react';
import { WidthStats } from '../Form/Form';
import { StepDependency, useStepDependency } from './StepHooks';
import { SiteState } from '../States/SiteSlice';

export interface CustomStepProps
    extends StepProps,
        GlobalState,
        StepDependency {
    form: Form;
    dependencyStore: SiteState['dependencies'];
    customStepProps: Record<string, any>;
    widthStats: WidthStats;
}

export interface CustomStep {
    componentProps?: Record<string, any>;
    component: (props: CustomStepProps) => JSX.Element;
    calcValue?: (step: any, value: any) => any;
    /** Function to change custom steps nested step ids to the generated ones. */
    calcRecursiveData?: (step: any, ids: Record<string, string>) => void;
    calcDependencyValue?: (step: any, value: any) => any;
}

interface CustomStepComponentProps extends StepProps {
    customComponent: (props: CustomStepProps) => JSX.Element;
    customProps?: Record<string, any>;
}

function CustomStepComponent({
    customComponent,
    customProps = {},
    ...props
}: CustomStepComponentProps): JSX.Element {
    const form = useContext(FormContext);
    const global = useAppSelector((state) => state.global);
    const { customStepProps } = useContext(CustomStepPropsContext);
    const dependencyStore = useAppSelector((state) => state.site.dependencies);
    const widthStats = useAppSelector((state) => state.widthStats);
    const dependencyInfo = useStepDependency(props.step, undefined);
    return customComponent({
        ...global,
        ...dependencyInfo,
        ...props,
        dependencyStore,
        widthStats,
        form,
        customStepProps,
        ...customProps,
    });
}

export default CustomStepComponent;
