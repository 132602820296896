import { useFormContext } from 'react-hook-form';
import { SmartSelectStepProps } from '../SmartSelectStep';
import RoundedSmartSelect from '../../../Shared/RoundedSmartSelect/RoundedSmartSelect';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import React from 'react';
import FormContext from '../../../Contexts/FormContext';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { focusStep, setEmptyDependency } from '../../../States/SiteSlice';
import {
    selectDependencies,
    selectStepDependencies,
    useFormStep,
} from '../../StepHooks';
import { GSmartSelect } from '../../../@Types/GenericFormSteps';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';

function SmartSelectStep<StepType extends GSmartSelect>({
    icon,
    step,
    editable,
    getOptions,
    calcDepError,
    defaultValue,
    filterOptions,
    valueOverwrite,
    getValueString,
    changeListener,
    getValueWarning,
    getOptionSelected,
    getOptionsConditionsIdSteps = (): any[] => [],
    renderNestedSteps,
}: SmartSelectStepProps<StepType>): JSX.Element {
    const {
        ref,
        value,
        error,
        field,
        onChange: handleChange,
    } = useFormStep<string>(step, {
        sizeChange: true,
        defaultValue: defaultValue ?? null,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    const onChange = useCallback(
        (value): void =>
            handleChange(valueOverwrite ? valueOverwrite(value) : value),
        [handleChange, valueOverwrite]
    );

    const { postview, formStyle, idOrganization } = useAppSelector(
        (state) => state.global
    );
    const form = useContext(FormContext);
    const [options, setOptions] = useState<any[] | null | undefined>();
    const [loading, setLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [touched, setTouched] = useState(false);
    const dispatch = useAppDispatch();
    const idDependencies = useMemo(() => getOptionsConditionsIdSteps(step), []);

    const dependencies = useAppSelector((state) =>
        selectDependencies(state, step.dependencies)
    );

    const deps = useAppSelector((state) =>
        selectDependencies(state, idDependencies)
    );

    const conditionDependencies = useMemo(
        () => deps,
        idDependencies.map((id) => deps[id])
    );

    const { emptyDep, invalids } = useAppSelector((state) =>
        selectStepDependencies(state, step, form)
    );
    const { getValues, setFocus } = useFormContext();
    const isEmpty = useAppSelector(
        (state) => state.site.dependencies[step.id]?.empty
    );

    const disabled = emptyDep;

    useEffect(
        () => {
            if (!postview && editable) {
                calcOptions(firstTime);
                if (firstTime) setFirstTime(false);
            } else if (value && !step.searchable) {
                setOptions([value]);
            }
        },
        step.dependencies?.map((dep) => dependencies[dep]?.value) ?? []
    );

    const calcOptions = async (firstTime?: boolean): Promise<void> => {
        if (!firstTime) setOptions(undefined);
        if (value && !firstTime) setLoading(true);
        try {
            const resp = await getOptions?.(idOrganization, step, dependencies);
            if (value) {
                /** If the value is still valid select it */
                if (resp && getValueString(value) !== undefined) {
                    const newValue = resp.find(
                        (option) =>
                            getValueString(option) === getValueString(value)
                    );
                    if (!firstTime) onChange(newValue ?? null);
                } else if (!firstTime) onChange(null);
            }
            setOptions(resp);
            const empty = resp === null ? undefined : resp.length === 0;
            /** Tell the dependency store this input is empty or not */
            if (empty !== !!isEmpty) {
                dispatch(
                    setEmptyDependency({
                        step,
                        empty,
                    })
                );
            }
        } catch (error) {
            console.error(error);
            setOptions(null);
        }
        setLoading(false);
    };

    const warning = useMemo(
        (): string | null | undefined => getValueWarning?.(value),
        [value]
    );

    const onFocus = useCallback((): void => {
        if (disabled || !editable || postview) return;
        if (options === null) {
            if (!value) {
                if (invalids.length > 0) {
                    const depStep = form.steps[invalids[0]];
                    if (
                        form.type === 'STEPPER' &&
                        step.idSection !== depStep.idSection
                    ) {
                        dispatch(
                            focusStep({
                                step: form.steps[invalids[0]],
                                values: getValues(),
                            })
                        );
                    } else {
                        setFocus(invalids[0]);
                    }
                } else if (value === undefined) {
                    setLoading(true);
                }
            }
        }
        if (options === undefined) {
            setLoading(true);
        }
        if (!touched) setTouched(true);
    }, [options, disabled, postview, editable, invalids, touched]);

    const filteredOptions = useMemo(() => {
        if (!options || !filterOptions) return options;
        return filterOptions(options, conditionDependencies);
    }, [options, conditionDependencies]);

    const errorMsg = useMemo((): string | undefined => {
        if (postview || loading) return undefined;
        if (invalids.length > 0 && touched) {
            return calcDepError?.(invalids.map((idDep) => form.steps[idDep]));
        } else if (value && filteredOptions) {
            const currentOption = filteredOptions.find((option) =>
                getOptionSelected(option, value)
            );
            if (!currentOption) {
                return 'Este campo es obligatorio';
            }
        } else if (filteredOptions === null && invalids.length === 0) {
            return 'Error al cargar las opciones';
        }
        return undefined;
    }, [invalids, touched, value, filteredOptions]);

    return (
        <React.Fragment>
            <MaterialInputContainer
                step={step}
                onClick={onFocus}
                editable={editable}
            >
                <RoundedSmartSelect
                    {...field}
                    inputRef={ref}
                    value={value}
                    handleUpdate={onChange}
                    onFocus={onFocus}
                    disabled={filteredOptions === null || disabled}
                    hidden={disabled}
                    loading={loading}
                    options={filteredOptions ?? undefined}
                    cantEdit={!editable || postview}
                    fullWidth
                    backgroundColor={formStyle.stepBackgroundColor ?? 'white'}
                    label={step.label}
                    required={step.required}
                    height={'31px'}
                    changeListener={changeListener}
                    searchable={step.searchable}
                    icon={step.showIcon ? icon : undefined}
                    helperTextColor={formStyle.descriptionTextColor}
                    focusColor={formStyle.primaryColor}
                    outlineColor={formStyle.outlineColor}
                    errorColor={formStyle.errorColor}
                    color={formStyle.textColor}
                    containerMargin="0px"
                    getOptionSelected={getOptionSelected}
                    getValueString={getValueString}
                    helperText={
                        errorMsg ??
                        warning ??
                        error?.message?.toString() ??
                        step.description ??
                        undefined
                    }
                    error={!!errorMsg || !!warning || !!error}
                />
            </MaterialInputContainer>
            {renderNestedSteps?.(value)}
        </React.Fragment>
    );
}
export default SmartSelectStep;
