import SmartSelectStep from '../../SmartSelectStep/SmartSelectStep';
import { StepOptions } from '../../CBRStepMapper';
import { GSmartSelect } from '../../../@Types/GenericFormSteps';
import { StepProps } from '../../Step';
import { CBRElementStep } from '../../../@Types/CBRFormStep';
import CBRFormStepTypes from '../../../constants/CBRFormStepTypes';
import axios from 'axios';
import InputIcon from '../../../Shared/InputIcon/InputIcon';
import { FormStep } from '../../../@Types/FormStep';
import { DependencyStore } from '../../../Form/Form';
import FormContext from '../../../Contexts/FormContext';
import { useContext } from 'react';

export interface ElementStepProps extends Omit<StepProps, 'step'> {
    step: CBRElementStep;
}

function CBRElementStepComponent(props: ElementStepProps): JSX.Element {
    return (
        <SmartSelectStep
            {...props}
            step={props.step}
            icon={<InputIcon icon={'LEAK'} />}
            getOptions={getElementOptions}
            getOptionSelected={(option, value): boolean =>
                option.id === value.id
            }
            getValueString={(value): string => value?.id}
            calcDepError={calcDepError}
            valueOverwrite={(value: any): any => {
                if (value) {
                    return { ...value, subStep: props.step.subStep };
                } else {
                    return null;
                }
            }}
            renderNestedSteps={(value: any): JSX.Element => (
                <ElementSubStepRenderer value={value} {...props} />
            )}
        ></SmartSelectStep>
    );
}

export default CBRElementStepComponent;

function ElementSubStepRenderer({
    value,
    ...stepProps
}: ElementStepProps & {
    value: any;
}): JSX.Element {
    const form = useContext(FormContext);

    if (!value || stepProps === undefined) {
        return <div></div>;
    }
    const subStep = form.steps[stepProps.step.subStep ?? ''];
    if ((subStep?.type as any) !== CBRFormStepTypes.CBR_LOCATIVAS) {
        return <div></div>;
    }
    return (
        <SmartSelectStep
            {...stepProps}
            step={subStep as any}
            icon={<InputIcon icon={'LEAK'} />}
            getOptions={getElementOptions}
            calcDepError={calcDepError}
            getOptionSelected={(option, value): boolean =>
                option.id === value.id
            }
            getValueString={(value): string => value?.id}
            renderNestedSteps={(value): JSX.Element => (
                <ElementSubStepRenderer
                    value={value}
                    {...stepProps}
                    step={subStep as any}
                />
            )}
        ></SmartSelectStep>
    );
}

const getElementOptions = async (
    idOrganization: string | undefined,
    step: GSmartSelect,
    dependencyStore: DependencyStore
): Promise<StepOptions[] | null> => {
    if (step.dependencies && idOrganization) {
        const idProyecto = dependencyStore[step.dependencies[0]]?.value?.id;
        if (!idProyecto) {
            return null; //null para indicar que falta la depedency
        }
        //Calc resp given dep, and depency Store
        const response = (
            await axios.get(
                `https://integrations.${process.env.REACT_APP_DOMAIN}/sinco/cbr/Locativas?idOrganization=${idOrganization}`
            )
        ).data;
        const father = dependencyStore[step.dependencies[1]]?.value;
        if (father && father.id === undefined) {
            return null;
        }
        return response.filter((locativa: any) => {
            if (father?.id) {
                return (
                    locativa.id.startsWith(father.id) &&
                    locativa.id !== father.id
                );
            } else {
                return locativa.id.length === 2;
            }
        });
    } else {
        return null;
    }
};

const calcDepError = (steps: FormStep[]): string | undefined => {
    for (const step of steps) {
        const type = step.type as any;
        if (type === CBRFormStepTypes.CBR_PROYECTO) {
            return 'Selecciona un proyecto';
        }
        if (type === CBRFormStepTypes.CBR_LOCATIVAS) {
            return 'Selecciona un padre';
        }
    }
    return undefined;
};
