import { MapperStepProps } from '../../MapperStep';
import StepComponent from '../../../Step';
import { MapperStyleTypes } from '../../../../constants/FormStepTypes';
import ListMapperElement from './ListMapperElement/ListMapperElement';
import PillMapperElement from './PillMapperElement/PillMapperElement';
import React, { useContext, useMemo } from 'react';
import FormContext from '../../../../Contexts/FormContext';
import CustomContext from '../../../../Contexts/CustomContext';
import { MapperElement } from '../../../../@Types/MapperElement';
import PagedMapperElement from './PagedMapperElement/PagedMapperElement';
import { MapperValue } from '../MaterialMapperStep';
export interface MapperElementComponentProps<Type> {
    num: number;
    loading?: boolean;
    element: MapperElement<Type>;
    onChange: (value: MapperValue<Type>) => void;
    value: MapperValue<Type>;
    handleDelete: () => void;
    children?: React.ReactNode;
}

function MapperElementComponent<Type = any>(
    props: MapperStepProps<Type> & MapperElementComponentProps<Type>
): JSX.Element {
    const { step, element, editable } = props;
    const customContext = useContext(CustomContext);
    const form = useContext(FormContext);
    if (element.deleted) {
        return <div></div>;
    }

    const nestedCustomContext = useMemo(() => {
        if (!props.calcCustomStepProps) return customContext;
        return {
            ...customContext,
            customStepProps: props.calcCustomStepProps(
                element,
                customContext.customStepProps
            ),
        };
    }, [element, customContext]);

    const mapSubSteps = (): JSX.Element => {
        return (
            <CustomContext.Provider value={nestedCustomContext}>
                {step.rootSteps.map((idSubStep: string) => {
                    const subStep = form.steps[element.ids[idSubStep]];
                    return (
                        <StepComponent
                            {...props}
                            editable={editable ? step.editable ?? true : false}
                            step={subStep}
                            key={element.ids[idSubStep]}
                        />
                    );
                })}
            </CustomContext.Provider>
        );
    };

    switch (step.style?.type) {
        case MapperStyleTypes.INLINE:
            return <React.Fragment>{mapSubSteps()}</React.Fragment>;
        case MapperStyleTypes.LIST:
            return (
                <ListMapperElement {...props} step={step}>
                    {mapSubSteps()}
                </ListMapperElement>
            );
        case MapperStyleTypes.PAGED:
            return (
                <PagedMapperElement {...props} step={step}>
                    {mapSubSteps()}
                </PagedMapperElement>
            );
        case MapperStyleTypes.PILL:
        default:
            return (
                <PillMapperElement {...props} step={step}>
                    {mapSubSteps()}
                </PillMapperElement>
            );
    }
}

export default MapperElementComponent;
