import styles from './MaterialPhoneInputStep.module.css';
import { PhoneInputStepProps } from '../PhoneInputStep';
import { calcStepWidth } from '../../StepFunctions';
import { useContext } from 'react';
import FormContext from '../../../Contexts/FormContext';
import { selectBreakPoint, useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
import RoundedPhoneInput from '../../../Shared/RoundedPhoneInput/RoundedPhoneInput';
import {
    isEmptyPhoneNumber,
    isValidPhoneNumber,
} from '../../../Utils/PhoneFunctions';
function PhoneInputStep({
    step,
    editable,
    defaultValue = step.defaultValue,
}: PhoneInputStepProps): JSX.Element {
    const currentBreakPoint = useAppSelector(selectBreakPoint);
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);

    const { ref, value, onChange, error, field } = useFormStep<string>(step, {
        defaultValue: defaultValue ?? '',
        debounce: true,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
            validate: (phone) => {
                if (isValidPhoneNumber(phone)) return true;
                if (!step.required && isEmptyPhoneNumber(phone)) return true;
                return (
                    step.validation?.message ?? 'Número de teléfono inválido'
                );
            },
        },
    });

    return (
        <div
            className={styles.container}
            style={{
                width:
                    currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight:
                    step.description || (!postview && editable)
                        ? '55px'
                        : '43px',
            }}
        >
            <RoundedPhoneInput
                {...field}
                inputRef={ref}
                value={value}
                onChange={onChange}
                data-testid={step.id}
                label={step.label}
                cantEdit={!editable || postview}
                required={step.required}
                fontWeight={400}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                textColor={formStyle.textColor}
                errorColor={formStyle.errorColor}
                backgroundColor={formStyle.stepBackgroundColor}
                helperText={error?.message ?? step.description}
                helperTextColor={formStyle.descriptionTextColor}
                error={!!error}
            />
        </div>
    );
}

export default PhoneInputStep;
