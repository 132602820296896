import StepComponent from '../../../FormSteps/Step';
import { SectionComponentProps } from '../Section';
import styles from './MaterialSection.module.css';
import { selectBreakPoint, useAppSelector } from '../../../hooks';
import { useContext } from 'react';
import CustomContext from '../../../Contexts/CustomContext';
import FormContext from '../../../Contexts/FormContext';

function MaterialSection({ idSection }: SectionComponentProps): JSX.Element {
    const currentBreakPoint = useAppSelector(selectBreakPoint);
    const { editable } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);
    const { sendLabel } = useContext(CustomContext);
    const section = form.sections[idSection];
    if (!section) return <></>;
    return (
        <div
            className={styles.container}
            style={{
                width:
                    (form.size.blockSize + form.size.spacingSize) *
                    form.size.blockNum,
                justifyContent:
                    sendLabel !== undefined &&
                    currentBreakPoint < form.size.blockNum
                        ? 'center'
                        : 'normal',
            }}
        >
            {section.steps?.map((idStep: string) => {
                const step = form.steps[idStep];
                return (
                    <StepComponent
                        editable={editable}
                        step={step}
                        key={idStep}
                    />
                );
            })}
        </div>
    );
}

export default MaterialSection;
