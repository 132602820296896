import { Rating } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialRatingStep from './MaterialRatingStep/MaterialRatingStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
export interface RatingStepProps extends StepProps {
    /** The RatingStep to display */
    step: Rating;
}
function RatingStep(props: RatingStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialRatingStep {...props} />;
        }
    }
}
export default RatingStep;
