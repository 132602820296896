import React, { useCallback, useContext, useMemo, useState } from 'react';
import FormStepTypes, {
    EntityValueDataTypes,
    EntityValueOptionTypes,
} from '../../../constants/FormStepTypes';
import StepComponent from '../../Step';
import {
    EntityValuePickerStepProps,
    StepOption,
} from '../EntityValuePickerStep';
import SmartSelect from '../../SmartSelectStep/MaterialSmartSelectStep/MaterialSmartSelectStep';
import widgetInstance from '../../../Utils/AxiosWidget';
import InputIcon from '../../../Shared/InputIcon/InputIcon';
import FormContext from '../../../Contexts/FormContext';
import { EntityValuePicker } from '../../../@Types/FormStep';
import { DependencyStore } from '../../../Form/Form';
import StepFillerContainer from '../../Utils/@StepFiller/StepFiller';
import { evaluateCondition } from '../../StepFunctions';
import {
    recursivelyCalcConditionSteps,
    selectDependencies,
} from '../../StepHooks';
import { EurekaDraft } from '../../../@Types/Draft/Draft';
import { useAppSelector } from '../../../hooks';
import MaterialEntityValueDialog from './MaterialEntityValueDialog/MaterialEntityValueDialog';

interface EntityValuePickerDialog {
    type: 'WARNING' | 'INFO';
    message: EurekaDraft;
}

function EntityValuePickerStep({
    step,
    editable,
}: EntityValuePickerStepProps): JSX.Element {
    const form = useContext(FormContext);
    const [dialogs, setDialogs] = useState<
        | {
              current: EntityValuePickerDialog;
              pending: EntityValuePickerDialog[];
              value: any;
          }
        | undefined
    >();

    const dialogsIdStepDeps = useMemo(() => {
        const ids: string[] = [];
        if (!step.dialogs) return ids;
        for (const dialog of step.dialogs) {
            ids.push(...recursivelyCalcConditionSteps(dialog.condition));
        }
        return ids;
    }, []);

    const dialogDeps = useAppSelector((state) =>
        selectDependencies(state, dialogsIdStepDeps)
    );

    const handleCloseDialog = useCallback(() => {
        setDialogs((dialogs) =>
            dialogs && dialogs?.pending.length > 0
                ? {
                      current: dialogs.pending[0],
                      pending: dialogs.pending.slice(1),
                      value: dialogs.value,
                  }
                : undefined
        );
    }, []);

    return (
        <React.Fragment>
            {dialogs !== undefined && form.entities?.[step.idEntity] && (
                <MaterialEntityValueDialog
                    type={dialogs.current.type}
                    entity={form.entities?.[step.idEntity]}
                    entityValue={dialogs.value}
                    message={dialogs.current.message}
                    handleClose={handleCloseDialog}
                />
            )}
            <StepFillerContainer step={step}>
                <SmartSelect
                    editable={editable}
                    step={step}
                    icon={
                        step.icon ? <InputIcon icon={step.icon} /> : undefined
                    }
                    getOptions={getEntityValueOptions}
                    getOptionSelected={(option, value): boolean =>
                        option._id === value._id
                    }
                    calcDepError={(steps): string | undefined => {
                        for (const step of steps) {
                            if (step.type === FormStepTypes.ENTITYVALUEPICKER) {
                                return 'Selecciona un ' + step.label;
                            }
                        }
                        return undefined;
                    }}
                    changeListener={(value): void => {
                        if (!value) return;
                        const dialogsToDisplay = [];
                        for (const dialog of step.dialogs ?? []) {
                            if (!dialog.condition) {
                                dialogsToDisplay.push(dialog);
                                continue;
                            }
                            if (
                                evaluateCondition(
                                    dialog.condition,
                                    dialogDeps,
                                    {
                                        entity: form.entities?.[step.idEntity],
                                        entityValue: value,
                                    }
                                )
                            ) {
                                dialogsToDisplay.push(dialog);
                            }
                        }
                        if (dialogsToDisplay.length > 0) {
                            setDialogs({
                                value,
                                current: dialogsToDisplay[0],
                                pending: dialogsToDisplay.slice(1),
                            });
                        }
                    }}
                    filterOptions={(options, dependencies): StepOption[] => {
                        const filteredOptions: StepOption[] = [];
                        for (const value of options) {
                            const option = step.options[value._id];
                            if (option?.type === EntityValueOptionTypes.HIDE)
                                continue;
                            if (
                                !option ||
                                !option.condition ||
                                evaluateCondition(
                                    option.condition,
                                    dependencies
                                )
                            )
                                filteredOptions.push(value);
                        }
                        return filteredOptions;
                    }}
                    getOptionsConditionsIdSteps={() => {
                        const dependencies = [...(step.dependencies ?? [])];
                        for (const option of Object.values(step.options)) {
                            if (
                                option.type !== EntityValueOptionTypes.HIDE &&
                                option.condition
                            ) {
                                dependencies.push(
                                    ...recursivelyCalcConditionSteps(
                                        option.condition
                                    )
                                );
                            }
                        }
                        return dependencies;
                    }}
                    getValueString={(value): string => value?._id}
                    renderNestedSteps={(value): JSX.Element => {
                        if (!value) return <></>;
                        const currentOption = step.options[value._id];
                        if (
                            currentOption?.type ===
                            EntityValueOptionTypes.NESTED
                        ) {
                            return (
                                <React.Fragment>
                                    {currentOption.steps.map(
                                        (idStep: string) => {
                                            const subStep = form.steps[idStep];
                                            return (
                                                <StepComponent
                                                    editable={editable}
                                                    step={subStep}
                                                    key={idStep}
                                                />
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            );
                        } else return <></>;
                    }}
                />
            </StepFillerContainer>
        </React.Fragment>
    );
}

export default EntityValuePickerStep;

const getEntityValueOptions = async (
    idOrganization: string | undefined,
    step: EntityValuePicker,
    dependencyStore: DependencyStore
): Promise<StepOption[] | null> => {
    if (!idOrganization) return null;
    let urlPath = '';
    for (const path of step.path) {
        let idEntityValue = 'null';
        switch (path.type) {
            case EntityValueDataTypes.STEP: {
                const currentValue = dependencyStore[path.idStep]?.value;
                if (!currentValue) return null;
                if (typeof currentValue === 'string')
                    idEntityValue = currentValue;
                else idEntityValue = currentValue._id ?? currentValue.id;
                break;
            }
            case EntityValueDataTypes.VALUE:
            default:
                idEntityValue = path.idEntityValue ?? 'null';
                break;
        }
        urlPath +=
            '/' + path.idEntity + (idEntityValue ? '/' + idEntityValue : '');
    }

    const params = new URLSearchParams({});
    for (const filter of step.filters) {
        switch (filter.type) {
            case EntityValueDataTypes.STEP: {
                const currentValue = dependencyStore[filter.idStep]?.value;
                if (currentValue) {
                    if (typeof currentValue === 'string')
                        params.set(filter.idProperty, currentValue);
                    else
                        params.set(
                            filter.idProperty,
                            currentValue._id ?? currentValue.id
                        );
                } else if (filter.required) {
                    return null;
                }
                break;
            }
            case EntityValueDataTypes.VALUE:
            default:
                params.set(filter.idProperty, filter.value);
                break;
        }
    }

    const url = `${idOrganization}/entities/${
        step.idEntity
    }${urlPath}?${params.toString()}`;

    const response = await widgetInstance.get(url, {
        timeout: 120000,
    });
    return response.data.filter(
        (option: any) =>
            step.options[option._id]?.type !== EntityValueOptionTypes.HIDE
    );
};
