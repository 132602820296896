import styles from './MaterialFileUploadStep.module.css';
import { useFormContext } from 'react-hook-form';
import RoundedButton from '../../../Shared/RoundedButton/RoundedButton';
import { useContext, useEffect, useRef, useState } from 'react';
import { getAcceptedExtensions } from '../../../constants/Files/FileExtensions';
import { getUploadUrls, postFile } from '../../../Services/FileService';
import FileComponent from './FileComponent/FileComponent';
import { FileUploadStepProps } from '../FileUploadStep';
import maxSize from '../../../constants/Files/FileMaxSize';
import { useAppSelector } from '../../../hooks';
import CustomContext from '../../../Contexts/CustomContext';
import { useFormStep } from '../../StepHooks';
export interface FileObject {
    state: 'STARTING' | 'UPLOADING' | 'DONE' | 'CANCEL' | 'ERROR';
    file: File;
    postInfo: any;
    fileName?: string;
    S3Key?: string;
    error?: boolean;
}
export interface UploadedFileObject {
    fileName: string;
    S3Key: string;
    downloadUrl?: string;
}

export function isUploadedFileObject(
    file: UploadedFileObject | FileObject
): file is UploadedFileObject {
    return (file as FileObject).state === undefined && file.S3Key !== undefined;
}

function FileUploadStep({ step, editable }: FileUploadStepProps): JSX.Element {
    const {
        ref,
        value,
        onChange,
        error: fieldError,
    } = useFormStep<(FileObject | UploadedFileObject)[]>(step, {
        defaultValue: [],
        rules: {
            validate: (array): boolean =>
                ((step.required && array.length > 0) || !step.required) &&
                array.find((file: any) => !file.S3Key) === undefined,
        },
    });

    const { fetchDownloadUrl } = useContext(CustomContext);
    const { formStyle, idOrganization, postview } = useAppSelector(
        (state) => state.global
    );

    const { clearErrors } = useFormContext();

    const [error, setError] = useState<string | undefined>(undefined);
    const [fileChange, setFileChange] = useState<FileObject[]>([]);
    const inputRef = useRef<any>();
    useEffect(() => {
        const filesToFetchLink = [];
        const filesChanged = [];
        for (const file of fileChange) {
            if ((file as FileObject).state === 'STARTING') {
                filesToFetchLink.push(file);
            }
            if ((file as FileObject).state === 'UPLOADING') {
                uploadFile(file);
                filesChanged.push(file);
            }
            if (
                (file as FileObject).state === 'DONE' ||
                (file as FileObject).state === 'ERROR'
            ) {
                filesChanged.push(file);
            }
            if ((file as FileObject).state === 'CANCEL') {
                const newValue = [...value];
                for (let i = 0; i < value.length; i++) {
                    if ((value[i] as FileObject).file === file.file) {
                        newValue.splice(i, 1);
                        break;
                    }
                }
                onChange(newValue);
            }
        }
        if (filesChanged.length > 0) {
            const newValue = [...value];
            for (const file of filesChanged) {
                for (let i = 0; i < value.length; i++) {
                    if ((value[i] as FileObject).file === file.file) {
                        newValue[i] = file;
                    }
                }
            }
            onChange(newValue);
        }
        if (filesToFetchLink.length > 0) {
            getLinks(filesToFetchLink as FileObject[]);
            onChange([...value, ...filesToFetchLink]);
        }
    }, [fileChange]);

    async function getLinks(pFiles: FileObject[]): Promise<void> {
        try {
            const fileLinks = await getUploadUrls(
                pFiles.map((file) => file.file),
                idOrganization
            );
            const errorsExt: string[] = [];
            setFileChange(
                pFiles.map((file, index) => {
                    if (fileLinks[index] === 'ERROR:INVALIDEXTENSION') {
                        errorsExt.push(file.file.name.split('.').pop() ?? '');
                        return { ...file, state: 'ERROR' };
                    } else {
                        return {
                            ...file,
                            state: 'UPLOADING',
                            postInfo: fileLinks[index],
                        };
                    }
                })
            );
            if (errorsExt.length === 1) {
                setError('La extención .' + errorsExt[0] + ' no es válida');
            } else if (errorsExt.length > 1) {
                setError(
                    'Las extenciónes .' +
                        errorsExt.join(',.') +
                        ' no son válidas'
                );
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function uploadFile(pFile: FileObject): Promise<void> {
        try {
            const resp = await Promise.all([
                postFile(pFile.file, pFile.postInfo),
                new Promise((resolve) => setTimeout(resolve, 1000)),
            ]);
            setFileChange([{ ...pFile, state: 'DONE', ...resp[0] }]);
        } catch (error) {
            console.error(error);
        }
    }

    const calcErrorMsg = (): string => {
        if (fieldError) {
            const errNum = value.filter(
                (val) => (val as FileObject).state === 'ERROR'
            ).length;
            if (errNum === 1) {
                return 'Este archivo no es válido';
            } else if (errNum > 1) {
                return 'Estos archivos no son válidos';
            } else {
                return 'Este campo es obligatorio';
            }
        }
        return '';
    };

    return (
        <div
            className={
                styles.container + (error || !!fieldError ? ' EF-error' : '')
            }
            style={{
                minHeight: editable === false || postview ? undefined : '100px',
            }}
            data-testid={step.id}
        >
            <div className={styles.labelLabel}>{step.label}</div>
            {step.description && (
                <div
                    className={styles.stepDescriptionLabel}
                    style={{ color: formStyle.descriptionTextColor }}
                >
                    {step.description}
                </div>
            )}
            <input
                type="file"
                ref={inputRef}
                className={styles.filesInput}
                onChange={(e): void => {
                    const files = e.target.files;
                    if (files) {
                        const filesArray = Array.from(files);
                        const maxFiles = filesArray.filter(
                            (file) => file.size > maxSize
                        );
                        if (maxFiles.length > 0) {
                            setError('El tamaño máximo de carga es de 30Mb.');
                        } else {
                            setFileChange(
                                filesArray
                                    .filter(
                                        (file) =>
                                            value.find(
                                                (val) =>
                                                    (val as FileObject).file ===
                                                    file
                                            ) === undefined
                                    )
                                    .map(
                                        (file) =>
                                            ({
                                                state: 'STARTING',
                                                file,
                                                postInfo: null,
                                            }) as FileObject
                                    )
                            );
                        }
                        inputRef.current.value = '';
                    }
                }}
                multiple
                accept={getAcceptedExtensions()}
            />
            <div className={styles.btnContainer}>
                <input ref={ref} className={'hidden-input'} />
                <RoundedButton
                    disabled={!editable || postview}
                    text={'Examinar' + (step.required ? ' *' : '')}
                    color={formStyle.primaryContrastColor}
                    backgroundColor={formStyle.primaryColor}
                    fontSize={'1rem'}
                    padding={'5px 15px 5px 15px'}
                    onClick={(): void => {
                        if (editable && !postview) {
                            const input = inputRef.current;
                            if (input !== null) {
                                clearErrors(step.id);
                                setError(undefined);
                                input.click();
                            }
                        }
                    }}
                />
            </div>
            <div className={styles.filesContainer}>
                {value.map((file, index) => (
                    <FileComponent
                        formStyle={formStyle}
                        key={index}
                        file={file}
                        error={
                            (!!fieldError &&
                                (file as FileObject).state !== 'DONE') ||
                            (file as FileObject).state === 'ERROR'
                        }
                        editable={editable && !postview}
                        handleRemove={(): void => {
                            if (
                                value.filter(
                                    (val) =>
                                        (val as FileObject).state === 'ERROR'
                                ).length === 1
                            ) {
                                clearErrors(step.id);
                                setError(undefined);
                            }
                            setFileChange([
                                {
                                    ...(file as FileObject),
                                    state: 'CANCEL',
                                },
                            ]);
                        }}
                        fetchDownloadUrl={fetchDownloadUrl}
                    />
                ))}
            </div>
            <div
                className={styles.errorMsg}
                style={{ color: formStyle.errorColor }}
            >
                {error ?? calcErrorMsg()}
            </div>
        </div>
    );
}

export default FileUploadStep;
