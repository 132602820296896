import { useAppSelector } from '../../hooks';
import { FileUpload } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialFileUploadStep from './MaterialFileUploadStep/MaterialFileUploadStep';
export interface FileUploadStepProps extends StepProps {
    /** The FileUploadStep to display */
    step: FileUpload;
}
function FileUploadStep(props: FileUploadStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialFileUploadStep {...props} />;
        }
    }
}
export default FileUploadStep;
