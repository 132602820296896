import { useAppSelector } from '../../hooks';
import { FormSelector } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialSelectorStep from './MaterialSelectorStep/MaterialSelectorStep';

export interface SelectorStepProps extends StepProps {
    /** The SelectorStep to display */
    step: FormSelector;
}
function SelectorStep(props: SelectorStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);
    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialSelectorStep {...props} />;
        }
    }
}
export default SelectorStep;
