import { TextInput } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialPhoneInputStep from './MaterialTextInputStep/MaterialPhoneInputStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
export interface PhoneInputStepProps extends StepProps {
    /** The TextInputStep to display */
    step: TextInput;
    /** Default value to display */
    defaultValue?: string;
}
function PhoneInputStep(props: PhoneInputStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialPhoneInputStep {...props} />;
        }
    }
}
export default PhoneInputStep;
