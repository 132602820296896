import { createContext } from 'react';
import { CustomStep, CustomStepProps } from '../FormSteps/CustomStep';

export interface CustomContextData {
    sendLabel?: string;
    customStepProps: Record<string, unknown>;
    customSteps: Record<string, CustomStep>;
    customClientInfoStep?: (props: CustomStepProps) => JSX.Element;
    /** Function to call on postview to fetch the download url of a file */
    fetchDownloadUrl?: (S3Key: string, fileName: string) => Promise<string>;
}

const CustomContext = createContext<CustomContextData>({
    customSteps: {},
    customStepProps: {},
});
export default CustomContext;
