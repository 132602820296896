import React, { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import {
    DatePicker,
    DateTimePicker,
    DatePickerProps,
} from '@material-ui/pickers';
import { getLocale } from '../../Form/Form';
import { format } from 'date-fns';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface StyleProps {
    /** The color of the text */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    hoverColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The backgroundColor of the input */
    backgroundColor?: string;
    /** The secondary color of the dialog */
    secondaryColor?: string;
    /** The contrastColor of the input */
    contrastColor?: string;
    /** The accent color of the input */
    accentColor?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If it should display the timePicker */
    pickTime?: boolean;
    /** The padding of the input */
    padding?: string;
    /** The size of the font to display the input in */
    fontSize?: number | string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** The fontsize of the shrunken label */
    shrunkenFontSize?: number | string;
    /** Cant edit */
    cantEdit?: boolean;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** Height of the component */
    height?: string;
}

const useDatePickerStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            borderRadius: props.borderRadius,
            cursor: props.cantEdit ? 'default' : 'pointer',
            height: props.height,
            backgroundColor: props.backgroundColor,
            '& input, textarea': {
                cursor: props.cantEdit ? 'default' : 'pointer',
                padding: props.padding,
                fontWeight: props.fontWeight,
                color: props.textColor,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                borderRadius: props.borderRadius,
            },
            '& .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
                '& span': {
                    fontSize: '0.75rem',
                },
            },
            '&.Mui-error .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&.Mui-focused .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.focusColor,
                borderWidth: 2,
            },
            '&:hover .EF-MuiOutlinedInput-notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .EF-MuiInputAdornment-root': {
                width: 5,
                '& button': {
                    marginLeft: -25,
                    fontSize: 18,
                    padding: 16,
                },
            },
            '&.Erk-MuiOutlinedInput-adornedEnd': {
                paddingRight: 11,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-disabled': {
                cursor: 'default',
            },
        },
    }));

const useDatePickerLabelStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 50px)',
            marginTop: props.height != '40px' ? '-4px' : '0px',
            textOverflow: 'ellipsis',
            fontSize: props.fontSize,
            color: props.textColor + '8a',
            '&.Mui-focused': {
                color: props.focusColor,
            },
            '&.EF-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));
const useDatePickerHelperTextStyles = (
    props: StyleProps
): (() => ClassNameMap) =>
    makeStyles(() => ({
        root: {
            color: props.helperTextColor,
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));
const useDatePickerDialogStyles = (props: StyleProps): (() => ClassNameMap) =>
    makeStyles(() => ({
        datePicker: {
            '& .EF-MuiPickersModal-dialogRoot': {
                color: props.textColor,
                '& .EF-MuiDialogActions-root': {
                    '& .EF-MuiButton-root:hover': {
                        backgroundColor: props.hoverColor,
                    },
                    '& .EF-MuiButton-label': {
                        color: props.focusColor,
                    },
                    '& .EF-MuiTouchRipple-root': {
                        color: props.focusColor,
                    },
                },
            },
            //Days labels
            '& .EF-MuiPickersDay-day': {
                color: props.textColor,
            },
            // Selected day label
            '& .EF-MuiPickersDay-daySelected': {
                color: props.backgroundColor,
                backgroundColor: props.focusColor,
            },
            //Title
            '& .EF-MuiPickersDatePickerRoot-toolbar': {
                color: props.backgroundColor,
                backgroundColor: props.focusColor,
            },
            //Title
            '& .EF-MuiPickersToolbar-toolbar': {
                color: props.backgroundColor,
                backgroundColor: props.focusColor,
            },
            '& .EF-MuiPickerDTTabs-tabs': {
                color: props.backgroundColor,
                backgroundColor: props.focusColor,
            },
            '& .EF-MuiPickersYear-yearSelected, .EF-MuiPickersYear-root:focus':
                {
                    color: props.focusColor,
                },
            '& .EF-MuiTabs-indicator': {
                backgroundColor: props.contrastColor,
            },
            '& .EF-MuiPickersClockNumber-clockNumber': {
                color: props.accentColor,
            },
            '& .EF-MuiPickersClockNumber-clockNumberSelected': {
                color: props.backgroundColor,
            },
            '& .EF-MuiPickersClock-pin, .EF-MuiPickersClockPointer-pointer': {
                backgroundColor: props.focusColor,
            },
            '& .EF-MuiPickersClockPointer-noPoint': {
                backgroundColor: props.focusColor,
            },
            '& .EF-MuiPickersClockPointer-thumb': {
                borderColor: props.focusColor,
            },
            '& .EF-MuiPickersDay-dayDisabled': {
                color: 'rgba(0, 0, 0, 0.38)',
                pointerEvents: 'all',
            },
            '& .EF-MuiPickersDay-current': {
                color: props.backgroundColor,
                backgroundColor: props.secondaryColor,
            },
            '& .EF-MuiPickersDay-hidden': {
                opacity: 0.4,
                pointerEvents: 'none',
            },
            '& .EF-MuiPickersDay-hidden.EF-MuiPickersDay-current': {
                opacity: 0.8,
                pointerEvents: 'none',
            },
        },
    }));

interface RoundedDatePickerProps
    extends Omit<DatePickerProps, 'ref'>,
        StyleProps {
    ref?: React.RefObject<HTMLDivElement>;
}
function CustomDatePicker({
    ref,
    focusColor = '#3d5a7f',
    secondaryColor = '#98c1d9',
    outlineColor = '#0000003b',
    backgroundColor = '#ffffff',
    textColor = '#293241',
    contrastColor = '#ee6c4d',
    accentColor = '#293241',
    errorColor = '#cc2936',
    borderRadius = 10,
    padding = '6px 0px 6px 12px',
    fontSize = '1rem',
    shrunkenFontSize = '1rem',
    helperTextColor = '#989898',
    fontWeight = '300',
    height = '31px',
    cantEdit = false,
    pickTime = false,
    onChange,
    required,
    ...others
}: RoundedDatePickerProps): JSX.Element {
    const classes = useDatePickerStyles({
        padding,
        cantEdit,
        textColor,
        focusColor,
        hoverColor: focusColor + '0a',
        outlineColor,
        errorColor,
        backgroundColor,
        borderRadius,
        fontSize,
        shrunkenFontSize,
        fontWeight,
        helperTextColor,
        height,
    })();

    const labelClasses = useDatePickerLabelStyles({
        padding,
        cantEdit,
        textColor,
        focusColor,
        hoverColor: focusColor + '0a',
        errorColor,
        outlineColor,
        backgroundColor,
        borderRadius,
        fontSize,
        shrunkenFontSize,
        fontWeight,
        helperTextColor,
        height,
    })();
    const { datePicker } = useDatePickerDialogStyles({
        padding,
        cantEdit,
        textColor,
        secondaryColor,
        accentColor,
        contrastColor,
        focusColor,
        hoverColor: focusColor + '0a',
        errorColor,
        outlineColor,
        backgroundColor,
        borderRadius,
        fontSize,
        shrunkenFontSize,
        fontWeight,
        helperTextColor,
        height,
    })();

    const helperTextStyles = useDatePickerHelperTextStyles({
        padding,
        textColor,
        focusColor,
        errorColor,
        outlineColor,
        backgroundColor,
        borderRadius,
        fontSize,
        shrunkenFontSize,
        fontWeight,
        helperTextColor,
        height,
    })();

    if (pickTime) {
        return (
            <DateTimePicker
                {...others}
                ref={ref as any}
                inputVariant="outlined"
                data-testid="date-picker"
                size="small"
                disabled={cantEdit}
                fullWidth
                onChange={onChange}
                placeholder={format(new Date(), 'Pp', { locale: getLocale() })}
                format="Pp"
                required={required}
                InputLabelProps={{ classes: labelClasses }}
                DialogProps={
                    {
                        disableEnforceFocus: true,
                        className: datePicker,
                        cancelLabel: '',
                    } as any //Fixes the typescript declaration of the library: https://material-ui-pickers.dev/api/KeyboardDatePicker
                }
                InputProps={{
                    required,
                    classes,
                    disabled: cantEdit,
                    endAdornment: (
                        <CalendarTodayRoundedIcon
                            fontSize="inherit"
                            style={{
                                color: '#757575',
                                fontSize: 20,
                            }}
                        />
                    ),
                }}
                FormHelperTextProps={{
                    classes: helperTextStyles,
                }}
                renderDay={(
                    day,
                    selectedDate,
                    dayInCurrentMonth,
                    dayComponent
                ): JSX.Element => {
                    if (!day) {
                        return dayComponent;
                    }
                    return (
                        <div
                            onClick={(): void => {
                                onChange(day);
                            }}
                        >
                            {cloneElement(dayComponent)}
                        </div>
                    );
                }}
                variant="dialog"
            />
        );
    } else {
        return (
            <DatePicker
                {...others}
                ref={ref}
                data-testid="date-picker"
                inputVariant="outlined"
                size="small"
                disabled={cantEdit}
                fullWidth
                onChange={(date) => {
                    if (!date || (!others.value && date)) onChange(date);
                }}
                placeholder={format(new Date(), 'P', { locale: getLocale() })}
                format="P"
                required={required}
                InputLabelProps={{ classes: labelClasses }}
                DialogProps={
                    {
                        disableEnforceFocus: true,
                        className: datePicker,
                        cancelLabel: '',
                    } as any //Fixes the typescript declaration of the library: https://material-ui-pickers.dev/api/KeyboardDatePicker
                }
                InputProps={{
                    required,
                    classes,
                    disabled: cantEdit,
                    endAdornment: (
                        <CalendarTodayRoundedIcon
                            fontSize="inherit"
                            style={{
                                color: '#757575',
                                fontSize: 20,
                            }}
                        />
                    ),
                }}
                FormHelperTextProps={{
                    classes: helperTextStyles,
                }}
                renderDay={(
                    day,
                    selectedDate,
                    dayInCurrentMonth,
                    dayComponent
                ): JSX.Element => {
                    if (!day) {
                        return dayComponent;
                    }
                    return (
                        <div
                            onClick={(): void => {
                                onChange(day);
                            }}
                        >
                            {cloneElement(dayComponent)}
                        </div>
                    );
                }}
                variant="dialog"
            />
        );
    }
}

/**
 * Generic datepicker with apps designs. Is class due to the use in the react-hook-forms library
 */
class RoundedDatePicker extends React.Component<RoundedDatePickerProps> {
    render(): JSX.Element {
        return <CustomDatePicker {...this.props} />;
    }
}
export default RoundedDatePicker;
