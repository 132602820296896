import { useCallback, useContext, useEffect, useState } from 'react';
import { FormComponentProps } from '../../Form';
import styles from './ColumnForm.module.css';
import React from 'react';
import Terms from '../../Terms/Terms';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Section from '../../Section/Section';
import CustomContext from '../../../Contexts/CustomContext';
import CustomBtn from '../../../Shared/CustomBtn/CustomBtn';
import FormContext from '../../../Contexts/FormContext';
import { addPagedValues } from '../../../States/SiteSlice';
import { getAppState } from '../../../Utils/store';
import { IdFormContext } from '../../../App/App';

function ColumnForm({
    onSubmit,
    customSubmitBtns,
    ...props
}: FormComponentProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const { postview, editable, formStyle } = useAppSelector(
        (state) => state.global
    );
    const { trigger, getValues, handleSubmit } = useFormContext();
    const { sendLabel } = useContext(CustomContext);
    const form = useContext(FormContext);
    const dispatch = useAppDispatch();
    const idForm = useContext(IdFormContext);
    const previous = useAppSelector((state) => state.site.previousSections);
    const next = useAppSelector((state) => state.site.nextSections);
    const idCurrent = useAppSelector((state) => state.site.idCurrentSection);

    useEffect(() => {
        if (props.setSubmit) props.setSubmit(submit);
    }, [props.setSubmit]);

    /** Esto se usa para no tener que escuchar todo el tiempo los errores del formulario */
    const handleErrors = handleSubmit(
        () => {},
        (errors) => {
            console.error('Errors:', errors);
        }
    );

    const submit = useCallback(async (): Promise<Record<
        string,
        any
    > | void> => {
        setLoading(true);
        const valid = await trigger(undefined, { shouldFocus: true });
        /** Imprimir errores si no es valido */
        if (!valid) {
            handleErrors();
            return setLoading(false);
        }
        let values = getValues();
        if (idCurrent) {
            const state = await dispatch(getAppState({ idForm })).unwrap();
            addPagedValues(
                form.steps,
                values,
                state.site.values.sections[idCurrent]
            );
        }
        values = await onSubmit(values);
        setLoading(false);
        return values;
    }, [form]);

    return (
        <div
            className={styles.container}
            style={{ color: formStyle.textColor }}
        >
            {loading && <div className={styles.curtain}></div>}
            {previous.map((idSection) => (
                <Section key={idSection} idSection={idSection} />
            ))}
            {next.map((idSection) => (
                <Section key={idSection} idSection={idSection} />
            ))}
            {/* MAP term & cond */}
            <Terms />
            {!postview && editable && (
                <React.Fragment>
                    {customSubmitBtns === undefined && (
                        <div className={styles.submitBtnContainer}>
                            <CustomBtn
                                onClick={submit}
                                width="calc(100% - 70px)"
                                label={sendLabel ?? 'Enviar'}
                                backgroundColor={formStyle.primaryColor}
                                color={formStyle.primaryContrastColor}
                                loading={loading}
                                data-testid="form__submit"
                            ></CustomBtn>
                        </div>
                    )}
                    {customSubmitBtns?.(submit, loading)}
                </React.Fragment>
            )}
        </div>
    );
}

export default ColumnForm;
