export const getAcceptedExtensions = (): string => {
    let extensionString = extensions.reduce(
        (acc: string, current: string): string => acc + ',.' + current
    );
    extensionString = '.' + extensionString;
    return extensionString;
};
const extensions = [
    'aif',
    'cda',
    'mid',
    'midi',
    'mp3',
    'mpa',
    'ogg',
    'wav',
    'wma',
    'wpl',
    'ai',
    'bmp',
    'gif',
    'ico',
    'jpeg',
    'jpg',
    'png',
    'ps',
    'psd',
    'svg',
    'tif',
    'tiff',
    'key',
    'odp',
    'pps',
    'ppt',
    'pptx',
    'ods',
    'xls',
    'xlsm',
    'xlsx',
    '3g2',
    '3gp',
    'avi',
    'flv',
    'h264',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    'wmv',
    'doc',
    'docx',
    'docm',
    'odt',
    'pdf',
    'rtf',
    'tex',
    'txt',
    'wpd',
    'csv',
    'tex',
    'msg',
    'zip',
    'rar',
];
export default extensions;
