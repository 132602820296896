import { useAppSelector } from '../../hooks';
import { EntityValuePicker } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialEntityValuePickerStep from './MaterialEntityValuePickerStep/MaterialEntityValuePickerStep';

export interface StepOption {
    id: string | number;
    label: string;
}

export interface EntityValuePickerStepProps extends StepProps {
    /** The EntityValuePickerStep to display */
    step: EntityValuePicker;
    /** Default value to display */
    defaultValue?: Date | null;
}
function EntityValuePickerStep(props: EntityValuePickerStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialEntityValuePickerStep {...props} />;
        }
    }
}
export default EntityValuePickerStep;
