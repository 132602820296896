import { Organization } from '../@Types';
import axiosInstance from '../Utils/AxiosAPI';
import widgetInstance from '../Utils/AxiosWidget';

export async function fetchOrganization(
    idOrganization?: string
): Promise<Organization | null> {
    try {
        const response = idOrganization
            ? await widgetInstance.get(
                  `/organization?idOrganization=${idOrganization}`
              )
            : await axiosInstance.get('/organization');

        return response.data;
    } catch (error) {
        return null;
    }
}
