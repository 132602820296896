import * as ReactDOM from 'react-dom';
import App from './App/App';
import styles from './index.module.css';
const urlParams = new URLSearchParams(window.location.search);
const values = urlParams.get('data-values');
let dataValues = undefined;
if (values) {
    try {
        dataValues = JSON.parse(decodeURIComponent(values));
    } catch (error) {
        console.error('Invalid data-values JSON', error);
    }
}
ReactDOM.render(
    <div className={styles.eurekaForm}>
        <App
            isWidget={false}
            apiKey={window.location.pathname.replace('/', '')}
            valuesData={dataValues}
            scrollToTop={() => {
                location.href = '#';
                location.href = '#eureka-root';
                location.href = '#';
            }}
        />
    </div>,
    document.getElementById('eureka-root')
);
