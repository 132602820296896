import styles from './CBRPropertyStep.module.css';
import SmartSelectStep from '../../SmartSelectStep/SmartSelectStep';
import { GSmartSelect } from '../../../@Types/GenericFormSteps';
import { StepProps } from '../../Step';
import { CBRPropertyStep } from '../../../@Types/CBRFormStep';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import axios from 'axios';
import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import RoundedButton from '../../../Shared/RoundedButton/RoundedButton';
import InputIcon from '../../../Shared/InputIcon/InputIcon';
import { StepOptions } from '../../CBRStepMapper';
import { DependencyStore } from '../../../Form/Form';
import { useAppSelector } from '../../../hooks';
import CBRFormStepTypes from '../../../constants/CBRFormStepTypes';

const baseUrl = `https://integrations.${process.env.REACT_APP_DOMAIN}/sinco/cbr/`;

export interface PropertyRendererProps extends Omit<StepProps, 'step'> {
    step: CBRPropertyStep;
}

function CBRPropertyStepComponent(props: PropertyRendererProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    const [showWarning, setShowWarning] = useState<undefined | Date>(undefined);
    const getInmueblesOptions = async (
        idOrganization: string | undefined,
        step: GSmartSelect,
        dependencyStore: DependencyStore
    ): Promise<StepOptions[] | null> => {
        if (step.dependencies && idOrganization) {
            const idProyecto = dependencyStore[step.dependencies[0]]?.value?.id;
            const idMacroProyecto =
                dependencyStore[step.dependencies[0]]?.value?.idMacroProyecto;
            if (!idProyecto) {
                return null; //null para indicar que falta la depedency
            }
            return (
                await axios.get(
                    baseUrl +
                        'Inmuebles?idMacro=' +
                        idMacroProyecto +
                        '&idProyecto=' +
                        idProyecto +
                        '&idOrganization=' +
                        idOrganization
                )
            ).data;
        } else {
            return null;
        }
    };

    return (
        <React.Fragment>
            <SmartSelectStep
                {...props}
                icon={<InputIcon icon={'PROPERTY'} />}
                getOptionSelected={(option, value): boolean =>
                    option.id === value.id
                }
                calcDepError={(steps): string | undefined => {
                    for (const step of steps) {
                        if (
                            (step.type as any) === CBRFormStepTypes.CBR_PROYECTO
                        ) {
                            return 'Selecciona un proyecto';
                        }
                    }
                    return undefined;
                }}
                getValueString={(value): string => value?.id}
                getValueWarning={
                    props.step.validation
                        ? (value): string | null => {
                              if (
                                  value &&
                                  value.entrega !== undefined &&
                                  props.step.validation
                              ) {
                                  const date = new Date(value.entrega);
                                  const today = new Date();
                                  const time = today.getTime() - date.getTime();
                                  const days = time / (1000 * 3600 * 24);
                                  if (days > props.step.validation.maxDays) {
                                      return props.step.validation.message;
                                  }
                              }
                              return null;
                          }
                        : undefined
                }
                changeListener={
                    props.step.validation
                        ? (value: any): void => {
                              if (
                                  value &&
                                  value.entrega !== undefined &&
                                  props.step.validation
                              ) {
                                  const date = new Date(value.entrega);
                                  const today = new Date();
                                  const time = today.getTime() - date.getTime();
                                  const days = time / (1000 * 3600 * 24);
                                  if (days > props.step.validation.maxDays) {
                                      setShowWarning(
                                          new Date(
                                              date.getTime() +
                                                  (props.step.validation
                                                      .maxDays +
                                                      1) *
                                                      1000 *
                                                      3600 *
                                                      24
                                          )
                                      );
                                  }
                              }
                          }
                        : undefined
                }
                getOptions={getInmueblesOptions}
            ></SmartSelectStep>
            {showWarning !== undefined && (
                <Dialog
                    PaperProps={{
                        style: {
                            borderRadius: '20px',
                            maxWidth: '100vw',
                            boxSizing: 'content-box',
                        },
                    }}
                    open={true}
                >
                    <div
                        className={styles.confirmationContainer}
                        style={{ color: formStyle.textColor }}
                    >
                        <div
                            className={styles.closeIcon}
                            onClick={(): void => {
                                setShowWarning(undefined);
                            }}
                        >
                            <CloseRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.container}>
                            <div
                                className={styles.warningContainer}
                                style={{ color: formStyle.primaryColor }}
                            >
                                <WarningRoundedIcon
                                    fontSize="inherit"
                                    style={{ fontSize: '160px' }}
                                />
                            </div>
                            <div className={styles.messageContainer}>
                                La garantía de su inmueble expiró el día{' '}
                                {showWarning.toLocaleDateString()}.
                            </div>
                            <div className={styles.btnContainer}>
                                <RoundedButton
                                    text={'Continuar'}
                                    color={formStyle.primaryContrastColor}
                                    backgroundColor={formStyle.primaryColor}
                                    fontSize={'1rem'}
                                    padding={'5px 15px 5px 15px'}
                                    onClick={(): void => {
                                        setShowWarning(undefined);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </React.Fragment>
    );
}

export default CBRPropertyStepComponent;
