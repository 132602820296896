import { CBRFormStep } from '../@Types/CBRFormStep';
import CBRFormStepTypes from '../constants/CBRFormStepTypes';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { StepProps } from './Step';
import TextInputStep from './TextInputStep/TextInputStep';
import SmartSelectStep from './SmartSelectStep/SmartSelectStep';
import TextAreaStep from './TextAreaStep/TextAreaStep';
import CBRIncidentsStep from './@Construction/CBRIncidentsStep/CBRIncidentsStep';
import { GSmartSelect } from '../@Types/GenericFormSteps';
import CBRElementStep from './@Construction/CBRElementStep/CBRElementStep';
import axios from 'axios';
import CBRPropertyStep from './@Construction/CBRPropertyStep/CBRPropertyStep';
import InputIcon from '../Shared/InputIcon/InputIcon';
import { DependencyStore } from '../Form/Form';

const baseUrl = `https://integrations.${process.env.REACT_APP_DOMAIN}/sinco/cbr/`;
interface CBRStepProps extends Omit<StepProps, 'step'> {
    step: CBRFormStep;
}

export interface StepOptions {
    id: string | number;
    label: string;
    idMacroProyecto?: number;
}

function CBRStepMapper(props: CBRStepProps): JSX.Element {
    const getTipoDocOptions = async (
        idOrganization: string | undefined
    ): Promise<StepOptions[] | null> => {
        if (!idOrganization) return null;
        return (
            await axios.get(
                baseUrl + 'TiposDoc?idOrganization=' + idOrganization
            )
        ).data;
    };

    const getProyectoOptions = async (
        idOrganization: string | undefined,
        step: GSmartSelect,
        dependencyStore: DependencyStore
    ): Promise<StepOptions[] | null> => {
        if (!idOrganization) return null;
        const idMacroProyecto =
            dependencyStore[step.dependencies?.[0] ?? '']?.value?.id;
        let proyectos = (
            await axios.get(
                baseUrl + 'Proyectos?idOrganization=' + idOrganization
            )
        ).data;
        if (idMacroProyecto) {
            proyectos = proyectos.filter(
                (proyecto: any) => proyecto.idMacroProyecto === idMacroProyecto
            );
        }
        return proyectos;
    };

    const getMacroProyectoOptions = async (
        idOrganization: string | undefined
    ): Promise<StepOptions[] | null> => {
        if (!idOrganization) return null;
        return (
            await axios.get(
                baseUrl + 'MacroProyectos?idOrganization=' + idOrganization
            )
        ).data;
    };

    const getTipoSolicitanteOptions = async (
        idOrganization: string | undefined
    ): Promise<StepOptions[] | null> => {
        if (!idOrganization) return null;
        return (
            await axios.get(
                baseUrl + 'TiposSolicitante?idOrganization=' + idOrganization
            )
        ).data;
    };

    const getTipoEspaciosOptions = async (
        idOrganization: string | undefined,
        step: GSmartSelect,
        dependencyStore: DependencyStore
    ): Promise<StepOptions[] | null> => {
        if (step.dependencies && idOrganization) {
            const idProyecto = dependencyStore[step.dependencies[0]]?.value?.id;
            if (!idProyecto) {
                return null; //null para indicar que falta la depedency
            }
            const params = new URLSearchParams({ idProyecto, idOrganization });
            return (
                await axios.get(baseUrl + 'TiposEspacio?' + params.toString())
            ).data;
        } else {
            return null;
        }
    };

    switch (props.step.type) {
        case CBRFormStepTypes.CBR_EMAIL:
            return (
                <TextInputStep
                    {...props}
                    step={props.step as any}
                    validation={{
                        type: 'EMAIL',
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            .source,
                        message: 'El correo no es válido',
                    }}
                />
            );
        case CBRFormStepTypes.CBR_CEL:
        case CBRFormStepTypes.CBR_PHONE:
            return (
                <TextInputStep
                    {...props}
                    step={props.step as any}
                    validation={{
                        type: 'OTHER',
                        value: /^(\+[0-9])|([0-9])$/.source,
                        message: 'El teléfono no es válido',
                    }}
                />
            );
        case CBRFormStepTypes.CBR_LAST_NAME:
        case CBRFormStepTypes.CBR_FIRST_NAME:
        case CBRFormStepTypes.CBR_MIDDLE_NAME:
        case CBRFormStepTypes.CBR_SECOND_LAST_NAME:
            return <TextInputStep {...props} step={props.step as any} />;
        case CBRFormStepTypes.CBR_DOC:
            return (
                <TextInputStep
                    {...props}
                    step={props.step as any}
                    icon={<DocumentIconComponent />}
                />
            );
        case CBRFormStepTypes.CBR_TIPO_DOC:
            return (
                <SmartSelectStep
                    {...props}
                    step={{ ...props.step, showIcon: false }}
                    getOptions={getTipoDocOptions}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    getValueString={(value): string => value?.id}
                />
            );

        case CBRFormStepTypes.CBR_MACRO_PROYECTO:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step}
                    icon={<InputIcon icon={'PROJECT'} />}
                    getOptions={getMacroProyectoOptions}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    getValueString={(value): string => value?.id}
                />
            );
        case CBRFormStepTypes.CBR_PROYECTO:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step}
                    icon={<InputIcon icon={'PROJECT'} />}
                    getOptions={getProyectoOptions}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    getValueString={(value): string => value?.id}
                />
            );

        case CBRFormStepTypes.CBR_INMUEBLE:
            return <CBRPropertyStep {...props} step={props.step as any} />;

        case CBRFormStepTypes.CBR_TIPO_SOLICITANTE:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step}
                    icon={
                        <PersonRoundedIcon
                            fill="#757575"
                            style={{
                                display: 'flex',
                                height: 23,
                                width: 23,
                                marginRight: 3,
                                marginTop: 1,
                            }}
                        />
                    }
                    getOptions={getTipoSolicitanteOptions}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    getValueString={(value): string => value?.id}
                />
            );

        case CBRFormStepTypes.CBR_TIPO_ESPACIO:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step}
                    icon={<InputIcon icon={'SPACE'} />}
                    getOptions={getTipoEspaciosOptions}
                    calcDepError={(steps): string | undefined => {
                        for (const step of steps) {
                            if (
                                (step.type as any) ===
                                CBRFormStepTypes.CBR_PROYECTO
                            ) {
                                return 'Selecciona un proyecto';
                            }
                        }
                        return undefined;
                    }}
                    getOptionSelected={(option, value): boolean =>
                        option.id === value.id
                    }
                    getValueString={(value): string => value?.id}
                />
            );
        case CBRFormStepTypes.CBR_LOCATIVAS:
            return <CBRElementStep {...props} step={props.step as any} />;
        case CBRFormStepTypes.CBR_COMENTARIO:
            return <TextAreaStep {...props} step={props.step as any} />;
        case CBRFormStepTypes.CBR_INCIDENCIAS:
            return <CBRIncidentsStep {...props} step={props.step} />;
        default:
            return <div></div>;
    }
}

export default CBRStepMapper;

function DocumentIconComponent(): JSX.Element {
    return (
        <div
            style={{
                marginRight: 2,
            }}
        >
            <InputIcon icon={'DOCUMENT'} />
        </div>
    );
}
