import React from 'react';
import { Button, withStyles } from '@material-ui/core';

interface ComponentProps {
    /** Button text */
    text: string;
    /** Background Color */
    backgroundColor?: string;
    /** Font Color */
    color?: string;
    /** Button Click Handle */
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    /** If button is disabled */
    disabled?: boolean;
    /** Text font size */
    fontSize?: number | string;
    /** Button padding '0 13px 0 13px' format */
    padding?: string;
    /** border radius */
    borderRadius?: number;
    /** Component height */
    height?: number;
    /** If text is bold */
    bold?: boolean;
    /** The width of the button */
    width?: string;
}

/**
 * Generic rounded button
 */
function RoundedButton({
    text,
    backgroundColor = '#3d5a7f',
    color = '#ffffff',
    onClick,
    disabled,
    fontSize = '1rem',
    padding = '0 13px 0 13px',
    borderRadius = 10,
    height = 28,
    bold = false,
    width = 'fit-content',
}: ComponentProps): JSX.Element {
    const CustomButton = withStyles({
        root: {
            width,
            textTransform: 'none',
            fontFamily: 'inherit',
            fontSize,
            borderRadius,
            padding,
            height: height,
            boxShadow: 'none',
            backgroundColor,
            color,
            '&:hover': {
                boxShadow: 'none',
                backgroundColor,
            },
            '&:disabled': {
                backgroundColor,
                color,
                opacity: 0.5,
            },
            fontWeight: bold ? 'bold' : 'normal',
        },
    })(Button);

    return (
        <CustomButton
            variant="contained"
            onMouseDown={onClick}
            disabled={disabled}
        >
            {text}
        </CustomButton>
    );
}
export default RoundedButton;
