import { useEffect, useState } from 'react';
import styles from './CustomBtn.module.css';
import Loader from '../Loader/Loader';

interface CustomBtnProps {
    label?: string;
    'data-testid'?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    backgroundColor?: string;
    color?: string;
    height?: string;
    width?: string;
    disabled?: boolean;
    loading?: boolean;
    children?: React.ReactNode;
    padding?: string;
    borderRadius?: string;
    contentMargin?: string;
}

function CustomBtn({
    label,
    onClick,
    backgroundColor,
    color,
    loading = false,
    disabled = false,
    height = 'fit-content',
    width = 'fit-content',
    padding = '15px 30px',
    borderRadius = '20px',
    contentMargin = '0px 10px',
    children,
    ...others
}: CustomBtnProps): JSX.Element {
    const [coords, setCoords] = useState<{ x: number; y: number } | undefined>(
        undefined
    );
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        if (coords) setCoords(undefined);
        return () => setMounted(false);
    }, [label]);

    return (
        <button
            className={styles.customBtn}
            style={{
                width,
                height,
                borderRadius,
                background: backgroundColor,
                padding,
                color,
            }}
            data-testid={others['data-testid']}
            disabled={disabled || loading}
            onClick={async (e) => {
                const rect = (e.target as any).getBoundingClientRect();
                setCoords({
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                });
                setTimeout(() => {
                    if (mounted) setCoords(undefined);
                }, 600);
                onClick && onClick(e);
            }}
        >
            {coords ? (
                <span
                    className={styles.ripple}
                    style={{
                        backgroundColor: color,
                        left: coords.x,
                        top: coords.y,
                    }}
                />
            ) : (
                <></>
            )}
            <div
                style={{
                    visibility: loading ? 'hidden' : 'visible',
                    margin: contentMargin,
                    display: 'flex',
                }}
            >
                {children ?? label}
            </div>
            {loading && (
                <div className={styles.loaderContainer}>
                    <Loader color={color} position="unset" />
                </div>
            )}
        </button>
    );
}

export default CustomBtn;
