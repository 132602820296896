import {
    StylesProvider,
    ThemeProvider,
    createGenerateClassName,
    createTheme,
} from '@material-ui/core';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SEED } from '../constants/MaterialClassNameSeed';
import { getLocale } from '../Form/Form';

const muiTheme = createTheme({
    typography: {
        fontFamily: 'inherit',
    },
});
const generateClassName = createGenerateClassName({
    seed: SEED,
    productionPrefix: 'efjss',
});

interface MaterialProvidersProps {
    children: React.ReactNode;
}

function MaterialProviders({ children }: MaterialProvidersProps): JSX.Element {
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={muiTheme}>
                <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={getLocale()}
                >
                    {children}
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StylesProvider>
    );
}

export default MaterialProviders;
