import { createContext } from 'react';
import { Form } from '../@Types';
import { EurekaDraft } from '../@Types/Draft/Draft';
import { DraftEntityTypes } from '../constants/Draft/DraftEntityTypes';
import { DraftEntityDataTypes } from '../constants/Draft/DraftEntityDataTypes';
import { TicketPropertyTypes } from '../constants/TicketPropertyTypes';

export const BaseConfirmationMessage: EurekaDraft = {
    blocks: [
        {
            depth: 0,
            data: {},
            inlineStyleRanges: [
                {
                    offset: 15,
                    length: 6,
                    style: 'BOLD',
                },
            ],
            text: 'Tu caso número ABC123 ha sido registrado!',
            type: 'unstyled',
            key: '5l788',
            entityRanges: [
                {
                    offset: 15,
                    length: 6,
                    key: 0,
                },
            ],
        },
        {
            depth: 0,
            data: {},
            inlineStyleRanges: [],
            text: 'Pronto te estaremos dando respuesta.',
            type: 'unstyled',
            key: 'aco4l',
            entityRanges: [],
        },
    ],
    entityMap: {
        '0': {
            type: DraftEntityTypes.EUREKA,
            data: {
                type: DraftEntityDataTypes.TICKET,
                property: TicketPropertyTypes.CASENUMBER,
            },
            mutability: 'IMMUTABLE',
        },
    },
};

const FormContext = createContext<Form>({
    firstSection: 'FIRST',
    sections: {
        FIRST: {
            id: 'FIRST',
            name: 'FIRST',
            steps: [],
            nextSection: null,
        },
    },
    steps: {},
    confirmationMessage: BaseConfirmationMessage,
    showLink: true,
    size: {
        blockSize: 0,
        blockNum: 4,
        spacingSize: 0,
    },
});

export default FormContext;
