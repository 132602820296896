export enum EntityPropertyTypes {
    NAME = 'NAME',
    TEXTAREA = 'TEXTAREA',
    DATEPICKER = 'DATEPICKER',
    TIMEPICKER = 'TIMEPICKER',
    TEXTINPUT = 'TEXTINPUT',
    SELECTOR = 'SELECTOR',
    CHECKBOX = 'CHECKBOX',
}
export default EntityPropertyTypes;
