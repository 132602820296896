import { ClassifierSelector } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialClassifierSelectorStep from './MaterialClassifierSelectorStep/MaterialClassifierSelectorStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
export interface ClassifierSelectorStepProps extends StepProps {
    /** The ClassifierSelectorStep to display */
    step: ClassifierSelector;
}
function ClassifierSelectorStep(
    props: ClassifierSelectorStepProps
): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialClassifierSelectorStep {...props} />;
        }
    }
}
export default ClassifierSelectorStep;
