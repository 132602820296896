export enum FormTypes {
    STEPPER = 'STEPPER',
    COLUMN = 'COLUMN',
}

export enum FormStyleTypes {
    MATERIAL = 'MATERIAL',
}

export enum FormStepTypes {
    TIMEPICKER = 'TIMEPICKER',
    TITLE = 'TITLE',
    RATING = 'RATING',
    CHECKBOX = 'CHECKBOX',
    TEXTAREA = 'TEXTAREA',
    DATEPICKER = 'DATEPICKER',
    FILEUPLOAD = 'FILEUPLOAD',
    TEXTINPUT = 'TEXTINPUT',
    SEPARATOR = 'SEPARATOR',
    SELECTOR = 'SELECTOR',
    CLASSIFIER_SELECTOR = 'CLASSIFIER_SELECTOR',
    COLLAPSIBLE = 'COLLAPSIBLE',
    MAPPER = 'MAPPER',
    ENTITYVALUEPICKER = 'ENTITYVALUEPICKER',
    API_SELECTOR = 'API_SELECTOR',
}

export enum OptionTypes {
    DEFAULT = 'DEFAULT',
    NESTED = 'NESTED',
    ADD_SECTION = 'ADD_SECTION',
}

export enum ClassifierOptionTypes {
    DEFAULT = 'CLASSIFIER_DEFAULT',
    HIDE = 'CLASSIFIER_HIDE',
    NESTED = 'CLASSIFIER_NESTED',
}

export enum EntityValueOptionTypes {
    DEFAULT = 'ENTITY_VALUE_DEFAULT',
    HIDE = 'ENTITY_VALUE_HIDE',
    NESTED = 'ENTITY_VALUE_NESTED',
}

export enum ApiSelectorOptionTypes {
    DEFAULT = 'API_SELECTOR_DEFAULT',
    HIDE = 'API_SELECTOR_HIDE',
    NESTED = 'API_SELECTOR_NESTED',
}

export enum RatingTypes {
    SCALE = 'SCALE',
    SATISFACTION = 'SATISFACTION',
    LIKE = 'LIKE',
}

export default FormStepTypes;

export enum MapperStyleTypes {
    PILL = 'PILL',
    LIST = 'LIST',
    PAGED = 'PAGED',
    INLINE = 'INLINE',
}

export enum EntityValueDataTypes {
    STEP = 'STEP',
    VALUE = 'VALUE',
}

export enum ApiSelectorParamTypes {
    STEP = 'STEP',
    VALUE = 'VALUE',
}
