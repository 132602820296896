import React, { useContext, useMemo } from 'react';
import { MapperStepProps } from '../../../MapperStep';
import styles from './PillMapperElement.module.css';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { MapperElementComponentProps } from '../MapperElementComponent';
import FormContext from '../../../../../Contexts/FormContext';
import { useAppSelector } from '../../../../../hooks';
import { MapperElement } from '../../../../../@Types/MapperElement';

function PillMapperElement<Type = any>({
    step,
    num,
    loading,
    element,
    children,
    editable,
    handleDelete,
    customElementModifiers,
    customElementRender,
}: MapperStepProps<Type> & MapperElementComponentProps<Type>): JSX.Element {
    const deletable = step.deletable ?? true;
    const { size } = useContext(FormContext);
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const widthStats = useAppSelector((state) => state.widthStats);
    const { calcName } = customElementModifiers ?? {};

    const name = useMemo(() => {
        return calcName
            ? calcName(element)
            : step.unitLabel
              ? step.unitLabel + ' ' + (num + 1)
              : '';
    }, [calcName, element, num, step.unitLabel]);

    const isDeleted = customElementModifiers?.isDeleted?.(element) ?? false;

    const renderElement = (
        renderElementButtons?: (element: MapperElement<any>) => JSX.Element
    ): JSX.Element => {
        return (
            <div
                className={
                    widthStats.isMobile
                        ? styles.mobileContainer
                        : styles.container
                }
                style={{
                    opacity: isDeleted ? 0.5 : 1,
                }}
            >
                <div
                    className={styles.contentContainer}
                    style={{
                        width:
                            (size.blockSize + size.spacingSize) *
                            (step.style?.size ?? size.blockNum - 1),
                    }}
                >
                    {name && (
                        <div
                            className={styles.titleLbl}
                            style={{
                                color: formStyle.textColor,
                                textDecoration: isDeleted
                                    ? 'line-through'
                                    : 'none',
                            }}
                        >
                            {loading ? 'Cargando...' : name + ':'}
                        </div>
                    )}
                    <div className={styles.btnsContainer}>
                        {renderElementButtons?.(element)}
                        {(deletable || !element.isOriginal) && (
                            <div
                                className={
                                    !editable || postview
                                        ? styles.disabledDeleteBtn
                                        : styles.deleteBtn
                                }
                                style={{ color: formStyle.textColor }}
                                onClick={(): void => {
                                    if (editable && !postview) {
                                        handleDelete?.();
                                    }
                                }}
                            >
                                <DeleteRoundedIcon fontSize="inherit" />
                            </div>
                        )}
                    </div>
                    {children}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerContainer}>
            {customElementRender
                ? customElementRender(element, num, renderElement)
                : renderElement()}
        </div>
    );
}

export default PillMapperElement;
