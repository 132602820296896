import { useAppSelector } from '../../../hooks';
import { SeparatorStepProps } from '../SeparatorStep';
import styles from './MaterialSeparatorStep.module.css';

function SeparatorStep({ step }: SeparatorStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: step.transparent
                    ? 'transparent'
                    : formStyle.primaryColor,
            }}
        ></div>
    );
}

export default SeparatorStep;
