import React, { useContext, useEffect, useState } from 'react';
import styles from './MaterialCollapsibleStep.module.css';
import { CollapsibleStepProps } from '../CollapsibleStep';
import { Controller, useFormContext } from 'react-hook-form';
import StepComponent from '../../Step';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector } from '../../../hooks';
function Collapsible({
    step,
    open,
    setOpen,
    ...others
}: CollapsibleStepProps & {
    open: boolean;
    setOpen: (open: boolean) => void;
}): JSX.Element {
    const [firstTime, setFirstTime] = useState(true);
    const { formStyle } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);
    const {
        formState: { errors },
    } = useFormContext();
    const contentRef = React.useRef<HTMLDivElement>(null);

    useEffect(
        () => {
            if (!open && step.dependencies?.find((dep) => !!errors[dep])) {
                setOpen(true);
            }
        },
        step.dependencies?.map((dep) => errors[dep]) ?? []
    );
    return (
        <div style={{ maxWidth: '100%' }}>
            <div
                className={styles.container}
                style={{
                    color: formStyle.textColor,
                    borderColor: formStyle.primaryColor,
                }}
            >
                <div
                    className={styles.collapsibleLabel}
                    onClick={(): void => {
                        if (firstTime) setFirstTime(false);
                        setOpen(!open);
                    }}
                >
                    {step.label}
                </div>
                <div
                    className={styles.expand}
                    onClick={(): void => {
                        if (firstTime) setFirstTime(false);
                        setOpen(!open);
                    }}
                >
                    <div className={styles.expandIcon}>
                        {open ? (
                            <ExpandLessRoundedIcon fontSize="inherit" />
                        ) : (
                            <ExpandMoreRoundedIcon fontSize="inherit" />
                        )}
                    </div>
                </div>
            </div>
            <div
                className={styles.content}
                style={{
                    height: open
                        ? firstTime
                            ? 'auto'
                            : contentRef.current?.offsetHeight
                        : 0,
                }}
            >
                <div className={styles.contentContainer} ref={contentRef}>
                    {step.steps.map((idSubStep: string) => {
                        const subStep = form.steps[idSubStep];
                        return (
                            <StepComponent
                                {...others}
                                step={subStep}
                                key={idSubStep}
                            />
                        );
                    })}
                </div>
            </div>
            <div
                className={styles.separator}
                style={{
                    backgroundColor: formStyle.primaryColor,
                }}
            ></div>
        </div>
    );
}

function CollapsibleStep(props: CollapsibleStepProps): JSX.Element {
    const { postview } = useAppSelector((state) => state.global);
    const {
        clearErrors,
        formState: { errors },
    } = useFormContext();
    return (
        <Controller
            name={props.step.id}
            defaultValue={props.step.defaultValue ?? (postview ? false : true)}
            render={({ field: { value, onChange } }): JSX.Element => (
                <Collapsible
                    {...props}
                    open={value}
                    setOpen={(open: boolean): void => {
                        if (!open) {
                            const deps = props.step.dependencies?.filter(
                                (dep) => !!errors[dep]
                            );
                            if (deps) clearErrors(deps);
                        }
                        onChange(open);
                    }}
                />
            )}
        />
    );
}
export default CollapsibleStep;
