import {
    ApiSelector,
    CheckBox,
    ClassifierSelector,
    Collapsible,
    DatePicker,
    EntityValuePicker,
    FileUpload,
    FormSelector,
    FormStep,
    Mapper,
    Rating,
    Separator,
    TextArea,
    TextInput,
    TimePicker,
    Title,
} from '../@Types/FormStep';
import FormStepTypes from '../constants/FormStepTypes';
import SelectorStep from './SelectorStep/SelectorStep';
import SeparatorStep from './SeparatorStep/SeparatorStep';
import TextInputStep from './TextInputStep/TextInputStep';
import TitleStep from './TitleStep/TitleStep';
import ClassifierSelectorStep from './ClassifierSelectorStep/ClassifierSelectorStep';
import TextAreaStep from './TextAreaStep/TextAreaStep';
import DatePickerStep from './DatePickerStep/DatePickerStep';
import FileUploadStep from './FileUploadStep/FileUploadStep';
import CheckBoxStep from './CheckBoxStep/MaterialCheckBoxStep/MaterialCheckBoxStep';
import CBRStepMapper from './CBRStepMapper';
import RatingStep from './RatingStep/RatingStep';
import AYFStepMapper from './AYFStepMapper';
import EntityValuePickerStep from './EntityValueStep/EntityValuePickerStep';
import MapperStep from './MapperStep/MapperStep';
import CollapsibleStep from './CollapsibleStep/CollapsibleStep';
import ApiSelectorStep from './ApiSelectorStep/ApiSelectorStep';
import CustomStepComponent, { CustomStep } from './CustomStep';
import { useAppSelector } from '../hooks';
import { useContext } from 'react';
import CustomContext from '../Contexts/CustomContext';
import { selectOriginalValue, useCondition } from './StepHooks';
import TimePickerStep from './TimePickerStep/TimePickerStep';
import PhoneInputStep from './PhoneInputStep/PhoneInputStep';
export interface StepProps {
    step: FormStep;
    editable: boolean;
}

function StepComponent({ step, ...props }: StepProps): JSX.Element {
    if (!step) {
        console.error('Step not found!', step);
        return <div></div>;
    }
    const { postview, partial } = useAppSelector((state) => state.global);
    const { customSteps, customClientInfoStep } = useContext(CustomContext);
    const originalValue = useAppSelector((state) =>
        selectOriginalValue(state, step)
    );
    const showStep = useCondition(step.condition);
    if (!showStep) return <></>;
    const customStep: CustomStep = customSteps[step.type];
    const editable = props.editable ? step.editable ?? true : false;

    if (
        (postview || !editable) &&
        (partial || step.partial) &&
        originalValue === undefined
    ) {
        return <div></div>;
    }

    if (customStep) {
        return (
            <CustomStepComponent
                {...props}
                customComponent={customStep.component}
                customProps={customStep.componentProps}
                step={step}
                editable={editable}
            />
        );
    }
    if (customClientInfoStep && step.clientInfoType) {
        return (
            <CustomStepComponent
                {...props}
                customComponent={customClientInfoStep}
                step={step}
                editable={editable}
            />
        );
    }
    switch (step.type) {
        case FormStepTypes.TITLE: {
            return (
                <TitleStep
                    {...props}
                    step={step as Title}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.TIMEPICKER: {
            return (
                <TimePickerStep
                    {...props}
                    step={step as TimePicker}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.SELECTOR: {
            return (
                <SelectorStep
                    {...props}
                    step={step as FormSelector}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.CHECKBOX: {
            return (
                <CheckBoxStep
                    {...props}
                    step={step as CheckBox}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.CLASSIFIER_SELECTOR: {
            return (
                <ClassifierSelectorStep
                    {...props}
                    step={step as ClassifierSelector}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.TEXTAREA: {
            return (
                <TextAreaStep
                    {...props}
                    step={step as TextArea}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.TEXTINPUT: {
            if (
                step.clientInfoType === 'cel' ||
                step.validation?.type === 'PHONE'
            ) {
                return (
                    <PhoneInputStep
                        {...props}
                        step={step as TextInput}
                        editable={editable}
                    />
                );
            }
            return (
                <TextInputStep
                    {...props}
                    step={step as TextInput}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.DATEPICKER: {
            return (
                <DatePickerStep
                    {...props}
                    step={step as DatePicker}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.SEPARATOR: {
            return (
                <SeparatorStep
                    {...props}
                    step={step as Separator}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.FILEUPLOAD: {
            return (
                <FileUploadStep
                    {...props}
                    step={step as FileUpload}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.RATING: {
            return (
                <RatingStep
                    {...props}
                    step={step as Rating}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.COLLAPSIBLE: {
            return (
                <CollapsibleStep
                    {...props}
                    step={step as Collapsible}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.ENTITYVALUEPICKER: {
            return (
                <EntityValuePickerStep
                    {...props}
                    step={step as EntityValuePicker}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.API_SELECTOR: {
            return (
                <ApiSelectorStep
                    {...props}
                    step={step as ApiSelector}
                    editable={editable}
                />
            );
        }
        case FormStepTypes.MAPPER: {
            return (
                <MapperStep
                    {...props}
                    step={step as Mapper}
                    editable={editable}
                />
            );
        }
        default:
            break;
    }
    if ((step as any).type.startsWith('CBR')) {
        return (
            <CBRStepMapper
                {...(props as any)}
                step={step}
                editable={editable}
            />
        );
    } else if ((step as any).type.startsWith('AYF')) {
        return (
            <AYFStepMapper
                {...(props as any)}
                step={step}
                editable={editable}
            />
        );
    }
    return <div></div>;
}
export default StepComponent;
