/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import CBRFormStepTypes from '../../../../constants/CBRFormStepTypes';
import { CBRIncidentsStepProps } from '../CBRIncidentsStep';
import IncidentComponent from './Incident/Incident';
import styles from './MaterialCBRIncidentsStep.module.css';
import { nanoid } from 'nanoid';
import {
    CBRCommentStep,
    CBRElementStep,
    CBRFormStep,
    CBRSpaceTypeStep,
    IncidentElementStep,
} from '../../../../@Types/CBRFormStep';
import RoundedButton from '../../../../Shared/RoundedButton/RoundedButton';
import FormContext from '../../../../Contexts/FormContext';
import { addStepsDependencies } from '../../../../States/SiteSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import CustomContext from '../../../../Contexts/CustomContext';
import { useFormStep } from '../../../StepHooks';

export interface Incident {
    idSpaceStep: string;
    idElementStep: string;
    idCommentStep: string;
    deleted?: boolean;
    num: number;
}

function CBRIncidentsStep({
    step,
    editable,
    ...others
}: CBRIncidentsStepProps): JSX.Element {
    const { ref, value, onChange, error } = useFormStep<Incident[]>(step, {
        defaultValue: [],
        rules: {
            validate: (array): boolean =>
                !step.required ||
                (step.required &&
                    array.filter((elem: Incident) => !elem.deleted).length > 0),
        },
    });

    const { customSteps } = useContext(CustomContext);
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const dispatch = useAppDispatch();
    const form = useContext(FormContext);

    /** Form to pass down with aditional steps */
    const [localForm, setLocalForm] = useState({
        ...form,
        steps: { ...form.steps },
    });

    const [firstRender, setFirstRender] = useState(false);

    useEffect(() => {
        setFirstRender(true);
    }, []);

    useEffect(() => {
        if (firstRender && value.length === 0 && !postview && editable) {
            handleAddIncident();
        }
    }, [firstRender]);

    const handleAddIncident = (): void => {
        const current = value.filter((incident) => !incident.deleted);
        const newIncident: Incident = {
            idSpaceStep: CBRFormStepTypes.CBR_TIPO_ESPACIO + '-' + nanoid(),
            idCommentStep: CBRFormStepTypes.CBR_COMENTARIO + '-' + nanoid(),
            idElementStep: CBRFormStepTypes.CBR_LOCATIVAS + '-' + nanoid(),
            num: current.length + 1,
        };
        const newSteps: Record<string, CBRFormStep> = {
            ...localForm.steps,
        } as any;
        newSteps[newIncident.idSpaceStep] = {
            ...step.spaceStep,
            id: newIncident.idSpaceStep,
            type: CBRFormStepTypes.CBR_TIPO_ESPACIO,
            dependencies: step.dependencies,
        } as CBRSpaceTypeStep;
        newSteps[newIncident.idCommentStep] = {
            ...step.commentStep,
            id: newIncident.idCommentStep,
            type: CBRFormStepTypes.CBR_COMENTARIO,
        } as CBRCommentStep;

        addRecursiveElementStep(
            newIncident.idElementStep,
            step.elementStep,
            null,
            newSteps,
            step.dependencies?.[0] ?? ''
        );
        onChange([...value, newIncident]);
        setLocalForm({ ...localForm, steps: newSteps as any });
        dispatch(
            addStepsDependencies({
                steps: newSteps,
                customSteps,
                allSteps: { ...form.steps, ...localForm.steps, ...newSteps },
            })
        );
    };

    const addRecursiveElementStep = (
        idStep: string,
        step: IncidentElementStep,
        idParent: string | null,
        steps: Record<string, CBRFormStep>,
        idProjectDep: string
    ): void => {
        const idSubStep = CBRFormStepTypes.CBR_LOCATIVAS + '-' + nanoid();
        const newSubStep = step.subStep ? { ...step.subStep } : undefined;
        const newStep = {
            ...step,
            id: idStep,
            type: CBRFormStepTypes.CBR_LOCATIVAS,
            dependencies: [idProjectDep],
            subStep: null,
        } as CBRElementStep;
        if (idParent) newStep.dependencies!.push(idParent);
        if (newSubStep) {
            newStep.subStep = idSubStep;
        }
        steps[idStep] = newStep;
        if (newSubStep) {
            addRecursiveElementStep(
                idSubStep,
                newSubStep,
                idStep,
                steps,
                idProjectDep
            );
        }
    };

    return (
        <div
            className={styles.container}
            style={{ color: formStyle.textColor }}
        >
            <div className={styles.titleLbl}>{step.label}</div>
            {step.description && (
                <p
                    className={styles.descriptionPar}
                    style={{
                        margin: step.description
                            ? '10px 0px'
                            : '0px 0px 5px 0px',
                    }}
                >
                    {step.description}
                </p>
            )}
            <FormContext.Provider value={localForm}>
                {value.map((incident, index) => (
                    <IncidentComponent
                        key={index}
                        incident={incident}
                        step={step}
                        editable={editable}
                        handleDelete={(): void => {
                            const tempIncidents = [...value];
                            tempIncidents[index].deleted = true;
                            let n = 1;
                            for (const inc of tempIncidents) {
                                if (!inc.deleted) {
                                    inc.num = n;
                                    n++;
                                }
                            }
                            onChange(tempIncidents);
                        }}
                        {...others}
                    />
                ))}
            </FormContext.Provider>
            <div className={styles.btnContainer}>
                <input ref={ref} className={'hidden-input'} />
                <RoundedButton
                    disabled={!editable || postview}
                    text={step.addBtnLabel + (step.required ? ' *' : '')}
                    color={formStyle.primaryContrastColor}
                    backgroundColor={formStyle.primaryColor}
                    fontSize={'1rem'}
                    padding={'5px 15px 5px 15px'}
                    onClick={(): void => {
                        if (editable && !postview) {
                            handleAddIncident();
                        }
                    }}
                />
            </div>
            {!!error && value.filter((elem) => !elem.deleted).length === 0 && (
                <div
                    className={styles.errorMsg}
                    style={{ color: formStyle.errorColor }}
                >
                    Este campo es obligatorio
                </div>
            )}
        </div>
    );
}

export default CBRIncidentsStep;
