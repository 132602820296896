import styles from './Incident.module.css';
import { CBRIncidentsStepProps } from '../../CBRIncidentsStep';
import { Incident } from '../MaterialCBRIncidentsStep';
import StepComponent from '../../../../Step';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import TextAreaStep from '../../../../TextAreaStep/TextAreaStep';
import { TextArea } from '../../../../../@Types/FormStep';
import FormContext from '../../../../../Contexts/FormContext';
import { useAppSelector } from '../../../../../hooks';
import { useContext } from 'react';

function IncidentComponent({
    step,
    incident,
    handleDelete,
    ...others
}: CBRIncidentsStepProps & {
    incident: Incident;
    handleDelete: () => void;
}): JSX.Element {
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);
    const widthStats = useAppSelector((state) => state.widthStats);

    const spaceStep = form.steps[incident.idSpaceStep];
    const elementStep = form.steps[incident.idElementStep];
    const commentStep = form.steps[incident.idCommentStep];
    if (incident.deleted) {
        return <div></div>;
    }
    return (
        <div
            className={
                widthStats.isMobile || form.size.blockNum < 3
                    ? styles.mobileContainer
                    : styles.container
            }
        >
            <div
                className={styles.contentContainer}
                style={{
                    width:
                        (form.size.blockSize + form.size.spacingSize) *
                        (form.size.blockNum - 1),
                }}
            >
                <div
                    className={styles.titleLbl}
                    style={{ color: formStyle.textColor }}
                >
                    {step.unitLabel + ' ' + incident.num}:
                </div>
                <div
                    className={
                        !others.editable || postview
                            ? styles.disabledDeleteBtn
                            : styles.deleteBtn
                    }
                    style={{ color: formStyle.textColor }}
                    onClick={(): void => {
                        if (others.editable && !postview) {
                            handleDelete();
                        }
                    }}
                >
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>

                <StepComponent {...others} step={spaceStep} />
                <StepComponent {...others} step={elementStep} />
                <TextAreaStep
                    {...others}
                    step={commentStep as TextArea}
                    maxLength={200}
                />
            </div>
        </div>
    );
}

export default IncidentComponent;
