import styles from './MaterialTextAreaStep.module.css';
import { useController } from 'react-hook-form';
import { Editor } from 'react-draft-wysiwyg'; //Load programatically only if step has editor
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './DraftEditor.css';
import { TextAreaStepProps } from '../TextAreaStep';
import React, { useCallback, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { useAppSelector } from '../../../hooks';
import { useStepDependency } from '../../StepHooks';
import { EditorState, convertFromRaw } from 'draft-js';
import { getRawText } from '../../../Utils/DraftFunctions';

export interface Payload {
    text: string;
    draft: any;
}

function TextAreaStep({ step, editable }: TextAreaStepProps): JSX.Element {
    const { formStyle, postview } = useAppSelector((state) => state.global);

    const [hovering, setHovering] = useState(false);
    const [focus, setFocus] = useState(false);
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

    const { handleStepDep, originalValue, isDependency } = useStepDependency(
        step,
        getRawText()
    );

    const {
        field: { ref, onChange, ...field },
        fieldState: { error },
    } = useController({
        name: step.id,
        shouldUnregister: true,
        rules: step.required
            ? {
                  validate: (editorState: any): boolean =>
                      editorState.getCurrentContent().hasText(),
              }
            : {},
        defaultValue: EditorState.createWithContent(
            convertFromRaw(originalValue)
        ),
    });

    const handleChange = useCallback(
        (value): void => {
            if (isDependency) {
                try {
                    clearTimeout(timer);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                setTimer(
                    setTimeout(() => {
                        handleStepDep(value.getCurrentContent().getPlainText());
                        setTimer(undefined);
                    }, 1000)
                );
            }
            onChange(value);
        },
        [handleStepDep, isDependency, onChange]
    );

    const calcStyle = (): React.CSSProperties => {
        const backgroundColor = formStyle.stepBackgroundColor;
        if (focus) {
            return {
                border: '2px solid ' + formStyle.primaryColor,
                marginTop: -1,
                marginLeft: -1,
                marginBottom: 0,
                backgroundColor,
            };
        } else if (hovering) {
            return {
                border: '1px solid ' + formStyle.primaryColor,
                backgroundColor,
            };
        } else {
            return {
                border: '1px solid ' + formStyle.outlineColor,
                backgroundColor,
            };
        }
    };

    const canEdit = editable && !postview;

    const value = field.value as EditorState;
    let editorClassName = styles.editor;
    if (!value.getCurrentContent().hasText()) {
        if (
            value.getCurrentContent().getBlockMap().first().getType() !==
            'unstyled'
        ) {
            editorClassName += ' RichEditor-hidePlaceholder';
        }
    }

    if (!canEdit) {
        editorClassName += ' postview-editor';
    }

    return (
        <div
            className={styles.textEditorContainer}
            style={
                {
                    '--eureka-outline': formStyle.outlineColor,
                    '--eureka-primary': formStyle.primaryColor,
                } as React.CSSProperties
            }
        >
            {step.label && (
                <div
                    className={styles.textEditorLabel}
                    style={{
                        color: error
                            ? formStyle.errorColor
                            : formStyle.textColor,
                    }}
                >
                    {step.label} {step.required ? ' *' : ''}
                </div>
            )}
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    if (canEdit) {
                        setFocus(false);
                    }
                }}
            >
                <div
                    className={
                        postview
                            ? styles.postViewContainer + ' EF-DraftContainer'
                            : styles.textContainer + ' EF-DraftContainer'
                    }
                    style={calcStyle()}
                    onMouseEnter={(): void => {
                        if (canEdit) {
                            setHovering(true);
                        }
                    }}
                    onMouseLeave={(): void => {
                        if (canEdit) {
                            setHovering(false);
                        }
                    }}
                    onClick={(): void => {
                        if (canEdit) {
                            setFocus(true);
                        }
                    }}
                    data-testid={step.id}
                >
                    <Editor
                        {...field}
                        editorRef={ref}
                        onFocus={(): void => {
                            setFocus(true);
                        }}
                        readOnly={!canEdit}
                        stripPastedStyles
                        onEditorStateChange={handleChange}
                        toolbarClassName={
                            !canEdit ? ' postview-editor-toolbar' : ''
                        }
                        editorState={value}
                        editorClassName={editorClassName}
                        wrapperClassName={styles.wrapper}
                        placeholder={step.description ?? ''}
                        toolbar={{
                            options: ['inline', 'list', 'history'],
                            inline: {
                                options: [
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strikethrough',
                                ],
                            },
                            list: {
                                options: ['unordered'],
                            },
                        }}
                    />
                </div>
            </ClickAwayListener>

            <div
                className={styles.errorMsg}
                style={{
                    color: formStyle.errorColor,
                    height: !postview && editable ? '0.75rem' : 0,
                }}
            >
                {!!error && 'Este campo es obligatorio'}
            </div>
        </div>
    );
}

export default TextAreaStep;
