import { CBRIncidentsStep as CBRIncidentsStepType } from '../../../@Types/CBRFormStep';
import { FormStyleTypes } from '../../../constants/FormStepTypes';
import { StepProps } from '../../Step';
import MaterialCBRIncidentsStep from './MaterialCBRIncidentsStep/MaterialCBRIncidentsStep';
import { useAppSelector } from '../../../hooks';
export interface CBRIncidentsStepProps extends Omit<StepProps, 'step'> {
    /** The CBRIncidentsStep to display */
    step: CBRIncidentsStepType;
}
function CBRIncidentsStep(props: CBRIncidentsStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialCBRIncidentsStep {...props} />;
        }
    }
}
export default CBRIncidentsStep;
