import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import Navbar from '../Shared/Navbar/Navbar';
import styles from './Form.module.css';
import InternalFormStyle from '../constants/InternalFormStyle';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { Branding } from '../@Types/Branding';
import ColumnForm from './FormTypes/ColumnForm/ColumnForm';
import StepperForm from './FormTypes/StepperForm/StepperForm';
import FormStepTypes, {
    FormStyleTypes,
    FormTypes,
} from '../constants/FormStepTypes';
import { calcValue } from './FormFunctions';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import widgetInstance from '../Utils/AxiosWidget';
import axiosInstance from '../Utils/AxiosAPI';
import ReCAPTCHA from 'react-google-recaptcha';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';
import MaterialProviders from '../Utils/MaterialProviders';
import { useWidthStats } from './FormHooks';
import { AppProps, IdFormContext } from '../App/App';
import CBRFormStepTypes from '../constants/CBRFormStepTypes';
import { useAppDispatch, useAppSelector } from '../hooks';
import { Form } from '../@Types';
import FormContext from '../Contexts/FormContext';
import { getAppState } from '../Utils/store';
import { focusStep } from '../States/SiteSlice';
import { FormStep } from '../@Types/FormStep';
import { CBRFormStep } from '../@Types/CBRFormStep';

const localeMap: any = {
    en: enLocale,
    'en-US': enLocale,
    es: esLocale,
};

export const getLocale = (): any => {
    return (
        localeMap[
            navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : (navigator as any).userLanguage ||
                  navigator.language ||
                  (navigator as any).browserLanguage
        ] ?? localeMap.es
    );
};

export interface StepDependency {
    dependents: (FormStep | CBRFormStep)[];
    value: any | undefined;
    type: FormStepTypes | CBRFormStepTypes | 'ORIGINAL';
    empty?: boolean;
}

export type DependencyStore = Record<string, StepDependency>;

export interface WidthStats {
    currentBreakPoint: number;
    isResponsive: boolean;
    isMobile: boolean;
}

type CustomAppProps = Pick<
    AppProps,
    'customSteps' | 'customSubmitBtns' | 'setSubmit' | 'customConfirmation'
>;

export interface BaseFormProps extends CustomAppProps {
    form: Form;
    branding?: Branding;
    reload: () => void;
    isWidget: boolean;
    apiKey: string | undefined;
    customSubmit: AppProps['customSubmit'];
    containerRef: React.RefObject<HTMLDivElement>;
    scrollToTop?: () => void;
}

export interface FormComponentProps extends CustomAppProps {
    /** Function called when users clicks submit */
    onSubmit: (values: FieldValues) => Promise<any>;
    scrollToTop?: () => void;
}

function FormComponent({
    form,
    apiKey,
    reload,
    isWidget,
    branding,
    setSubmit,
    scrollToTop,
    customSteps,
    containerRef,
    customSubmit,
    customSubmitBtns,
    customConfirmation,
}: BaseFormProps): JSX.Element {
    const { idOrganization, internal, postview } = useAppSelector(
        (state) => state.global
    );

    const formMethods = useForm({
        mode: 'onTouched',
        shouldFocusError: true,
    });
    const idForm = useContext(IdFormContext);
    const recaptchaRef = useRef<any>();
    const dispatch = useAppDispatch();
    const [showConfirmation, setShowConfirmation] = useState<
        undefined | { case: string; url: string }
    >();
    const idFocusStep = useAppSelector((state) => state.site.focusStep);

    useEffect(() => {
        if (idFocusStep) {
            formMethods.setFocus(idFocusStep);
            dispatch(focusStep(undefined));
        }
    }, [idFocusStep]);

    const onSubmit = useCallback(
        async (values: FieldValues): Promise<Record<string, any> | void> => {
            let token: string | null = null;
            if (form.hasCaptcha && !internal && !customSubmit) {
                token = recaptchaRef.current?.getValue();
                if (!token) token = await recaptchaRef?.current.executeAsync();
            }
            const ApiKey = internal ? form.apiKey : apiKey;
            if (!ApiKey && !customSubmit && !customSubmitBtns) return;
            try {
                const state = await dispatch(getAppState({ idForm })).unwrap();
                const newValues: Record<string, any> = {
                    ...state.site.values.global,
                };
                const deleteIds: string[] = [];
                for (const idStep of Object.keys(values)) {
                    newValues[idStep] = calcValue(
                        idStep,
                        form.steps,
                        values,
                        customSteps,
                        deleteIds
                    );
                }
                for (const id of deleteIds) {
                    delete newValues[id];
                }
                if (customSubmit) return await customSubmit(newValues, reload);
                if (customSubmitBtns) return newValues;
                const params = new URLSearchParams();

                if (idOrganization || internal)
                    params.set('idOrganization', idOrganization ?? '');
                if (token) params.set('token', token);

                let url = `/ticket/${ApiKey}`;
                if (params.toString()) {
                    url += `?${params.toString()}`;
                }

                const axios =
                    idOrganization || internal ? widgetInstance : axiosInstance;
                const resp = await axios.post(url, newValues);
                setShowConfirmation(resp?.data);
            } catch (error) {
                console.error(error);
            }
        },
        [customSteps, internal, customSubmit, idOrganization, idForm]
    );
    useWidthStats(containerRef, form, postview, internal);

    const isResponsive = useAppSelector(
        (state) => state.widthStats.isResponsive
    );

    const renderForm = (): JSX.Element => {
        return (
            <React.Fragment>
                {showConfirmation !== undefined && (
                    <ConfirmationDialog
                        confirmation={showConfirmation}
                        onClose={(): void => {
                            reload();
                            setShowConfirmation(undefined);
                        }}
                        customConfirmation={customConfirmation}
                    />
                )}
                {form.hasCaptcha && !internal && !postview && (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LcL22kkAAAAAEotDeAFbRATob-u5vbibbCyWL2p"
                        size={'invisible'}
                        badge={'bottomright'}
                    />
                )}
                <FormProvider {...formMethods}>
                    <FormContext.Provider value={form}>
                        <FormTypeComponent
                            onSubmit={onSubmit}
                            setSubmit={setSubmit}
                            scrollToTop={scrollToTop}
                            customSubmitBtns={customSubmitBtns}
                        />
                    </FormContext.Provider>
                </FormProvider>
            </React.Fragment>
        );
    };

    if (!idOrganization) return <></>;
    //If currently displaying internal or form is standalone
    if (postview || internal || !form.isStandAlone) {
        return (
            <div
                style={{
                    backgroundColor: internal
                        ? 'transparent'
                        : form.style?.backgroundColor ??
                          InternalFormStyle.backgroundColor,
                    minHeight:
                        form.isStandAlone && !isWidget ? '100vh' : '100%',
                }}
            >
                <div className={styles.widgetFormContainer} ref={containerRef}>
                    {renderForm()}
                </div>
            </div>
        );
    } else {
        //Standalone cant have widget
        return (
            <React.Fragment>
                <Navbar
                    logo={branding?.images.logoUrl}
                    color={
                        branding?.colors?.navbarColor ??
                        branding?.colors?.primaryColor
                    }
                />
                <div
                    className={styles.standAloneFormContainer}
                    ref={containerRef}
                    style={{
                        background:
                            form.style?.standAloneBackgroundColor ?? '#ffffff',
                    }}
                >
                    <div
                        className={
                            isResponsive
                                ? styles.fullScreenStandAloneForm
                                : styles.standAloneFormCard
                        }
                        style={{
                            backgroundColor:
                                form.style?.backgroundColor ??
                                InternalFormStyle.backgroundColor,
                        }}
                    >
                        {renderForm()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FormComponent;

function FormTypeComponent(props: FormComponentProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);

    const renderTypes = (): JSX.Element => {
        switch (form.type) {
            case FormTypes.STEPPER: {
                return <StepperForm {...props} />;
            }
            case FormTypes.COLUMN:
            default: {
                return <ColumnForm {...props} />;
            }
        }
    };

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
            return <MaterialProviders>{renderTypes()}</MaterialProviders>;
        default:
            return renderTypes();
    }
}
