import { Form } from '../@Types';
import {
    CBRCommentStep,
    CBRElementStep,
    CBRFormStep,
    CBRIncidentsStep,
    CBRSpaceTypeStep,
    IncidentElementStep,
} from '../@Types/CBRFormStep';
import CBRFormStepTypes from '../constants/CBRFormStepTypes';

export interface Incident {
    idSpaceStep: string;
    idElementStep: string;
    idCommentStep: string;
    num: number;
}

export const calcCbrForm = (
    form: Form,
    formValues: Record<string, any>
): Form => {
    for (const step of Object.values(form.steps)) {
        if ((step as any).type === CBRFormStepTypes.CBR_INCIDENCIAS) {
            const incidentStep = step as any as CBRIncidentsStep;
            const incValue: Incident[] = formValues[incidentStep.id ?? ''];
            if (incValue) {
                for (const incident of incValue) {
                    handleAddIncident(
                        incident,
                        incidentStep,
                        form.steps as any,
                        formValues
                    );
                }
            }
        }
    }
    return form as any;
};

const handleAddIncident = (
    incident: Incident,
    step: CBRIncidentsStep,
    steps: Record<string, CBRFormStep>,
    formValues: Record<string, any>
): void => {
    const spaceStep: CBRSpaceTypeStep = {
        ...step.spaceStep,
        id: incident.idSpaceStep,
        type: CBRFormStepTypes.CBR_TIPO_ESPACIO,
        dependencies: step.dependencies,
    };
    steps[incident.idSpaceStep] = spaceStep;

    const commentStep: CBRCommentStep = {
        ...step.commentStep,
        id: incident.idCommentStep,
        type: CBRFormStepTypes.CBR_COMENTARIO,
    };
    steps[incident.idCommentStep] = commentStep as any;
    addRecursiveElementStep(
        incident.idElementStep,
        step.elementStep,
        null,
        steps,
        step.dependencies?.[0] ?? '',
        formValues
    );
};
const addRecursiveElementStep = (
    idStep: string,
    step: IncidentElementStep,
    idParent: string | null,
    steps: Record<string, CBRFormStep>,
    idProjectDep: string,
    formValues: Record<string, any>
): void => {
    const idSubStep = formValues[idStep]?.subStep;
    const newSubStep = step.subStep ? { ...step.subStep } : undefined;
    const newStep: CBRElementStep = {
        ...step,
        id: idStep,
        type: CBRFormStepTypes.CBR_LOCATIVAS,
        dependencies: [idProjectDep],
        subStep: null,
    };
    if (newSubStep) {
        newStep.subStep = idSubStep;
    }
    if (idParent) {
        newStep.dependencies?.push?.(idParent);
    }
    steps[idStep] = newStep;
    if (newSubStep) {
        addRecursiveElementStep(
            idSubStep,
            newSubStep,
            idStep,
            steps,
            idProjectDep,
            formValues
        );
    }
};
