import { TimePicker } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
import { Time } from '../../@Types/Time';
import MaterialTimePickerStep from './MaterialTimePickerStep/MaterialTimePickerStep';
export interface TimePickerStepProps extends StepProps {
    /** The TimePickerStep to display */
    step: TimePicker;
    /** Default value to display */
    defaultValue?: Time;
}
function TimePickerStep(props: TimePickerStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialTimePickerStep {...props} />;
        }
    }
}
export default TimePickerStep;
