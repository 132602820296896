export enum DraftEntityDataTypes {
    DATE = 'DATE', //Utils
    NESTED = 'NESTED', // Toca renderizar un subdraft
    TICKET = 'TICKET',
    COMPANY = 'COMPANY',
    CONDITION_MET = 'CONDITION_MET', //Utils
    FORM_STEP = 'FORM_STEP',
}

export enum DraftEntityDataMappingTypes {
    ENTITYVALUE_MAPPING = 'ENTITYVALUE_MAPPING',
}
