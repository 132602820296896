import RoundedDatePicker from '../../../Shared/RoundedDatePicker/RoundedDatePicker';
import { DatePickerStepProps } from '../DatePickerStep';
import { useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';
function DatePickerStep({
    step,
    editable,
    defaultValue,
}: DatePickerStepProps): JSX.Element {
    const { formStyle, postview } = useAppSelector((state) => state.global);

    const { ref, value, onChange, error, field } = useFormStep<
        Date | null | undefined
    >(step, {
        defaultValue,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    return (
        <MaterialInputContainer step={step} editable={editable}>
            <RoundedDatePicker
                {...field}
                value={value}
                onChange={onChange}
                pickTime={step.pickTime}
                clearable={!step.required}
                clearLabel="Borrar"
                inputRef={ref}
                cantEdit={!editable || postview}
                secondaryColor={formStyle.secondaryColor}
                contrastColor={formStyle.secondaryContrastColor}
                accentColor={formStyle.textColor}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                textColor={formStyle.textColor}
                errorColor={formStyle.errorColor}
                helperTextColor={formStyle.descriptionTextColor}
                fontWeight={400}
                label={step.label}
                helperText={error?.message ?? step.description}
                error={!!error}
                required={step.required}
            />
        </MaterialInputContainer>
    );
}

export default DatePickerStep;
