import { useContext, useEffect, useState } from 'react';
import styles from './Terms.module.css';
import TermComponent from './Term/Term';
import { useFormContext } from 'react-hook-form';
import FormContext from '../../Contexts/FormContext';
import { useAppSelector } from '../../hooks';
function Terms(): JSX.Element {
    const { postview, internal } = useAppSelector((state) => state.global);
    const isInteralAgent = useAppSelector(
        (state) =>
            state.site.values.global['INTERNAL_CREATING_AGENT'] !== undefined
    );

    const form = useContext(FormContext);
    const {
        formState: { isSubmitting },
    } = useFormContext();
    const [tempError, setTempError] = useState(false);

    useEffect(() => {
        if (!isSubmitting) return;
        setTempError(true);
        setTimeout(() => {
            setTempError(false);
        }, 500);
    }, [isSubmitting]);

    if (
        form.terms &&
        (!internal || (internal && postview)) &&
        !isInteralAgent
    ) {
        return (
            <div
                className={styles.termsContainer}
                style={{
                    width:
                        (form.size.blockSize + form.size.spacingSize) *
                        form.size.blockNum,
                }}
            >
                {form.terms.map((term) => (
                    <TermComponent
                        key={term.id}
                        term={term}
                        tempError={tempError}
                    />
                ))}
            </div>
        );
    }
    return <></>;
}
export default Terms;
