import React, { useContext, useMemo } from 'react';
import FormStepTypes, {
    ApiSelectorOptionTypes,
    ApiSelectorParamTypes,
} from '../../../constants/FormStepTypes';
import StepComponent from '../../Step';
import { ApiSelectorStepProps, StepOption } from '../ApiSelectorStep';
import SmartSelect from '../../SmartSelectStep/MaterialSmartSelectStep/MaterialSmartSelectStep';
import widgetInstance from '../../../Utils/AxiosWidget';
import axiosInstance from '../../../Utils/AxiosAPI';
import InputIcon from '../../../Shared/InputIcon/InputIcon';
import FormContext from '../../../Contexts/FormContext';
import { ApiSelector } from '../../../@Types/FormStep';
import { DependencyStore } from '../../../Form/Form';
import StepFillerContainer from '../../Utils/@StepFiller/StepFiller';
import { recursivelyCalcConditionSteps } from '../../StepHooks';
import { evaluateCondition } from '../../StepFunctions';

function ApiSelectorComponent({
    step,
    editable,
    ...others
}: ApiSelectorStepProps): JSX.Element {
    const form = useContext(FormContext);

    function calcOptionId(option: any): string {
        return option?.[step.idAttribute] ?? '';
    }

    function calcOptionLabel(option: any): string {
        return option?.[step.labelAttribute] ?? '';
    }

    const getApiOptions = useMemo(
        () =>
            async (
                idOrganization: string | undefined,
                step: ApiSelector,
                dependencyStore: DependencyStore
            ): Promise<StepOption[] | null> => {
                function calcOptionId(option: any): string {
                    return option?.[step.idAttribute] ?? '';
                }

                const params = new URLSearchParams({});

                for (const filter of step.queryParams) {
                    switch (filter.type) {
                        case ApiSelectorParamTypes.STEP: {
                            const currentValue =
                                dependencyStore[filter.idStep]?.value;
                            const filterStep = form.steps[filter.idStep];
                            if (currentValue) {
                                if (
                                    filterStep.type ===
                                    FormStepTypes.API_SELECTOR
                                ) {
                                    params.set(
                                        filter.key,
                                        currentValue[step.idAttribute]
                                    );
                                } else if (typeof currentValue === 'string')
                                    params.set(filter.key, currentValue);
                                else
                                    params.set(
                                        filter.key,
                                        currentValue.label ?? currentValue.id
                                    );
                            } else if (filter.required) {
                                return null;
                            }
                            break;
                        }
                        case ApiSelectorParamTypes.VALUE:
                        default:
                            params.set(filter.key, filter.value);
                            break;
                    }
                }
                let url = step.url.replace(
                    'capta.co',
                    process.env.REACT_APP_DOMAIN!
                );
                if (
                    idOrganization &&
                    url.includes(process.env.REACT_APP_DOMAIN!)
                )
                    params.set('idOrganization', idOrganization);
                if (!step.url.includes('?')) url += '?';
                url += params.toString();
                const response = await (idOrganization
                    ? widgetInstance.get(url)
                    : axiosInstance.get(url));
                return response.data.filter(
                    (option: any) =>
                        calcOptionId(option) &&
                        step.options[calcOptionId(option)]?.type !==
                            ApiSelectorOptionTypes.HIDE
                );
            },
        [form]
    );

    return (
        <StepFillerContainer step={step}>
            <SmartSelect
                {...others}
                editable={editable}
                step={step}
                icon={step.icon ? <InputIcon icon={step.icon} /> : undefined}
                getOptions={getApiOptions}
                getOptionSelected={(option, value): boolean =>
                    calcOptionId(option) === calcOptionId(value)
                }
                calcDepError={(steps): string | undefined => {
                    for (const step of steps) {
                        if (step.type === FormStepTypes.API_SELECTOR) {
                            return 'Selecciona un ' + step.label;
                        }
                    }
                    return undefined;
                }}
                filterOptions={(options, dependencies): StepOption[] => {
                    const filteredOptions: StepOption[] = [];
                    for (const value of options) {
                        const option = step.options[calcOptionId(value)];
                        if (option?.type === ApiSelectorOptionTypes.HIDE)
                            continue;
                        if (
                            !option ||
                            !option.condition ||
                            evaluateCondition(option.condition, dependencies)
                        )
                            filteredOptions.push(value);
                    }
                    return filteredOptions;
                }}
                getOptionsConditionsIdSteps={() => {
                    const dependencies = [...(step.dependencies ?? [])];
                    for (const option of Object.values(step.options)) {
                        if (
                            option.type !== ApiSelectorOptionTypes.HIDE &&
                            option.condition
                        ) {
                            dependencies.push(
                                ...recursivelyCalcConditionSteps(
                                    option.condition
                                )
                            );
                        }
                    }
                    return dependencies;
                }}
                getValueString={(value): string => calcOptionLabel(value)}
                renderNestedSteps={(value): JSX.Element => {
                    if (!value) return <></>;
                    const currentOption = step.options[calcOptionId(value)];
                    if (currentOption?.type === ApiSelectorOptionTypes.NESTED) {
                        return (
                            <React.Fragment>
                                {currentOption.steps.map((idStep: string) => {
                                    const subStep = form.steps[idStep];
                                    return (
                                        <StepComponent
                                            editable={editable}
                                            step={subStep}
                                            key={idStep}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        );
                    } else return <></>;
                }}
            />
        </StepFillerContainer>
    );
}
export default ApiSelectorComponent;
