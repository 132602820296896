import { RatingTypes } from '../../constants/FormStepTypes';
import LikeRating from './Ratings/LikeRating';
import SatisfactionRating from './Ratings/SatisfactionRating';
import ScaleRating from './Ratings/ScaleRating';

export interface RatingProps {
    disabled?: boolean;
    isMobile?: boolean;
    value: any;
    onChange: (value: number | null) => void;
    inputRef: any;
    focusColor: string;
    unSelectedColor: string;
}

function Rating({
    type,
    ...others
}: RatingProps & { type: RatingTypes }): JSX.Element {
    switch (type) {
        case RatingTypes.LIKE:
            return <LikeRating {...others} />;
        case RatingTypes.SATISFACTION:
            return <SatisfactionRating {...others} />;
        case RatingTypes.SCALE:
            return <ScaleRating {...others} />;
        default:
            return <div></div>;
    }
}

export default Rating;
