import { useCallback, useContext, useState } from 'react';
import { FormComponentProps } from '../../Form';
import styles from './StepperForm.module.css';
import Terms from '../../Terms/Terms';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Section from '../../Section/Section';
import CustomContext from '../../../Contexts/CustomContext';
import * as SiteReducer from '../../../States/SiteSlice';
import { getAppState } from '../../../Utils/store';
import { IdFormContext } from '../../../App/App';
import CustomBtn from '../../../Shared/CustomBtn/CustomBtn';
import FormContext from '../../../Contexts/FormContext';
import { addPagedValues } from '../../../States/SiteSlice';

function StepperForm({
    onSubmit,
    scrollToTop,
}: FormComponentProps): JSX.Element {
    const [loading, setLoading] = useState<undefined | 'BACK' | 'NEXT'>(
        undefined
    );
    const { postview, editable, formStyle } = useAppSelector(
        (state) => state.global
    );
    const { trigger, getValues, handleSubmit } = useFormContext();
    const { sendLabel } = useContext(CustomContext);
    const form = useContext(FormContext);
    const dispatch = useAppDispatch();
    const previous = useAppSelector((state) => state.site.previousSections);
    const idCurrent = useAppSelector((state) => state.site.idCurrentSection);
    const idForm = useContext(IdFormContext);
    const next = useAppSelector((state) => state.site.nextSections);

    /** Esto se usa para no tener que escuchar todo el tiempo los errores del formulario */
    const handleErrors = handleSubmit(
        () => {},
        (errors) => {
            console.error('Errors:', errors);
        }
    );

    const handlePrevious = useCallback(async (): Promise<void> => {
        dispatch(SiteReducer.handlePrevious({ values: getValues(), form }));
        scrollToTop?.();
    }, []);

    const handleNext = useCallback(async (): Promise<Record<
        string,
        any
    > | void> => {
        setLoading('NEXT');
        const valid = await trigger(undefined, { shouldFocus: true });
        /** Imprimir errores si no es valido */
        if (!valid) {
            handleErrors();
            return setLoading(undefined);
        }
        if (next.length > 0) {
            dispatch(SiteReducer.handleNext({ values: getValues(), form }));
            scrollToTop?.();
            return setLoading(undefined);
        }
        const state = await dispatch(getAppState({ idForm })).unwrap();
        /** put all the values of the previous sections into a single object */
        let values = state.site.previousSections.reduce(
            (v, idSection) => ({
                ...v,
                ...state.site.values.sections[idSection],
            }),
            {}
        );
        /** Add the current section's values */
        values = { ...values, ...getValues() };
        if (idCurrent) {
            addPagedValues(
                form.steps,
                values,
                state.site.values.sections[idCurrent]
            );
        }
        const response = await onSubmit(values);
        setLoading(undefined);
        return response;
    }, [next]);

    if (!idCurrent) return <></>;

    return (
        <div
            className={styles.container}
            style={{ color: formStyle.textColor }}
        >
            {loading && <div className={styles.curtain}></div>}
            <Section key={idCurrent} idSection={idCurrent} />
            {next.length === 0 && <Terms />}
            <div className={styles.btnsContainer}>
                {previous.length > 0 && (
                    <CustomBtn
                        label={form.prevSectionLabel ?? 'Volver'}
                        data-testid="form__back"
                        onClick={handlePrevious}
                        backgroundColor={formStyle.primaryColor}
                        color={formStyle.primaryContrastColor}
                        disabled={!!loading}
                        loading={loading === 'BACK'}
                    />
                )}
                {(next.length > 0 || (!postview && editable)) && (
                    <CustomBtn
                        label={
                            next.length > 0
                                ? form.nextSectionLabel ?? 'Siguiente'
                                : sendLabel ?? 'Enviar'
                        }
                        data-testid="form__submit"
                        onClick={handleNext}
                        backgroundColor={formStyle.primaryColor}
                        color={formStyle.primaryContrastColor}
                        disabled={!!loading}
                        loading={loading === 'NEXT'}
                    />
                )}
            </div>
        </div>
    );
}

export default StepperForm;
