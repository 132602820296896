import React from 'react';
import styles from './Navbar.module.css';
interface NavBarProps {
    /** Url of the current logo of the current organization */
    logo?: string;
    /** The color of the navbar */
    color?: string;
}

function Navbar({ logo, color }: NavBarProps): JSX.Element {
    return (
        <div className={styles.navContainer} style={{ backgroundColor: color }}>
            <img
                src={logo}
                alt="Organization Logo"
                className={styles.navImage}
            />
        </div>
    );
}
export default Navbar;
