import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialSection from './MaterialSection/MaterialSection';
import SectionContext from '../../Contexts/SectionContext';
import { useAppSelector } from '../../hooks';

export interface SectionComponentProps {
    idSection: string;
}
function SectionComponent(props: SectionComponentProps): JSX.Element {
    const formStyle = useAppSelector((state) => state.global.formStyle);
    const renderSectionType = (): JSX.Element => {
        switch (formStyle.type) {
            case FormStyleTypes.MATERIAL:
            default: {
                return <MaterialSection {...props} />;
            }
        }
    };
    return (
        <SectionContext.Provider value={props.idSection}>
            {renderSectionType()}
        </SectionContext.Provider>
    );
}
export default SectionComponent;
