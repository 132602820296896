import { createDispatchHook, createSelectorHook } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import {
    type RootState,
    type AppDispatch,
    type EurekaFormsState,
    defaultRootState,
} from './Utils/store';
import { AnyAction } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { IdFormContext } from './App/App';
import React from 'react';

export const StoreContext = React.createContext<any>({});

const useSelector = createSelectorHook(StoreContext);

const useDispatch = createDispatchHook(StoreContext);

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export const useAppDispatch: () => AppDispatch = (): AppDispatch => {
    const idForm = useContext(IdFormContext);
    const dispatch = useDispatch();

    const appDispatch = (action: AnyAction): AnyAction => {
        action.meta = { idForm };
        return dispatch(action);
    };

    return appDispatch as AppDispatch;
};

export const useAppSelector: TypedUseSelectorHook<RootState> = (
    selector: any,
    options: any
) => {
    const idForm = useContext(IdFormContext);
    return useSelector(
        (state: EurekaFormsState) =>
            selector(state[idForm] ?? defaultRootState),
        options
    );
};

export const selectBreakPoint = (state: RootState): number =>
    state.widthStats.currentBreakPoint;
