import * as libphonenumber from 'libphonenumber-js/max';
import {
    guessCountryByPartialPhoneNumber,
    removeDialCode,
} from 'react-international-phone';
export function isValidPhoneNumber(phone: string): boolean {
    if (!phone.startsWith('+')) phone = '+' + phone;
    return libphonenumber.isValidPhoneNumber(phone);
}

export function isEmptyPhoneNumber(phone: string): boolean {
    if (!phone.startsWith('+')) phone = '+' + phone;
    const { country } = guessCountryByPartialPhoneNumber({
        phone,
    });
    if (
        country &&
        !removeDialCode({
            phone,
            dialCode: country?.dialCode,
        })
    ) {
        return true;
    }
    if (!country) {
        console.error('Country not found for phone number:', phone);
        return true;
    }
    return false;
}
