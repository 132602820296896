import { useAppSelector } from '../../hooks';
import { Separator } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import { StepProps } from '../Step';
import MaterialSeparatorStep from './MaterialSeparatorStep/MaterialSeparatorStep';

export interface SeparatorStepProps extends StepProps {
    /** The TitleStep to display */
    step: Separator;
}
function SeparatorStep(props: SeparatorStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialSeparatorStep {...props} />;
        }
    }
}
export default SeparatorStep;
