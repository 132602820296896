import {
    AnyAction,
    combineReducers,
    configureStore,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import GlobalSlice, { GlobalState } from '../States/GlobalSlice';
import SiteSlice, { SiteState } from '../States/SiteSlice';
import WidthStatsSlice from '../States/WidthStatsSlice';
import { WidthStats } from '../Form/Form';

export interface RootState {
    global: GlobalState;
    site: SiteState;
    widthStats: WidthStats;
}

export const defaultRootState: RootState = {
    global: GlobalSlice.getInitialState(),
    site: SiteSlice.getInitialState(),
    widthStats: WidthStatsSlice.getInitialState(),
};

export type EurekaFormsState = Record<string, RootState>;

const FormReducer = combineReducers({
    global: GlobalSlice.reducer,
    site: SiteSlice.reducer,
    widthStats: WidthStatsSlice.reducer,
});

export function EurekaFormsReducer(
    state: EurekaFormsState = {},
    action: AnyAction
): EurekaFormsState {
    const idForm = action.meta?.idForm;
    if (!idForm) return state;
    if (action.type === 'CLEAR') {
        return { ...state, [idForm]: undefined };
    }
    return { ...state, [idForm]: FormReducer(state[idForm], action) };
}

export const store = configureStore({
    reducer: EurekaFormsReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

export const getAppState = createAsyncThunk(
    'get/state',
    async ({ idForm }: { idForm: string }, thunkAPI) => {
        return (thunkAPI.getState() as EurekaFormsState)[idForm] as RootState;
    }
);
