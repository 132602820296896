import React, { useContext, useEffect, useMemo } from 'react';
import { ClassifierOptionTypes } from '../../../constants/FormStepTypes';
import StepComponent from '../../Step';
import { ClassifierSelectorStepProps } from '../ClassifierSelectorStep';
import { Classifier } from '../../../@Types/Form';
import RoundedSmartSelect from '../../../Shared/RoundedSmartSelect/RoundedSmartSelect';
import FormContext from '../../../Contexts/FormContext';
import { useAppSelector } from '../../../hooks';
import {
    recursivelyCalcConditionSteps,
    selectDependencies,
    useFormStep,
} from '../../StepHooks';
import StepFillerContainer from '../../Utils/@StepFiller/StepFiller';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';
import { calcDefaultValue, evaluateCondition } from '../../StepFunctions';

function ClassifierSelectorStep({
    step,
    editable,
}: ClassifierSelectorStepProps): JSX.Element {
    const { ref, value, onChange, error, field } = useFormStep<{
        value: string;
        label: string;
    }>(step, {
        defaultValue: calcDefaultValue(step),
        sizeChange: true,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    const idDependencies = useMemo(() => {
        const dependencies = [...(step.dependencies ?? [])];
        for (const option of Object.values(step.options)) {
            if (
                option.type !== ClassifierOptionTypes.HIDE &&
                option.condition
            ) {
                dependencies.push(
                    ...recursivelyCalcConditionSteps(option.condition)
                );
            }
        }
        return dependencies;
    }, []);

    const dependencies = useAppSelector((state) =>
        selectDependencies(state, idDependencies)
    );

    const { formStyle, postview, preview } = useAppSelector(
        (state) => state.global
    );
    const form = useContext(FormContext);

    if (!step.idClassifier) return <div></div>;

    const classifier: Classifier | undefined =
        form.classifiers?.[step.idClassifier];

    if (!classifier) return <div></div>;

    const options = useMemo(
        () => {
            return classifier.children
                ?.filter((idClassifier) => {
                    const option = step.options[idClassifier];
                    if (
                        option?.type === ClassifierOptionTypes.HIDE ||
                        !form.classifiers?.[idClassifier]
                    ) {
                        return false;
                    }
                    return (
                        !option ||
                        !option?.condition ||
                        evaluateCondition(option.condition, dependencies)
                    );
                })
                .map((idClassifier) => {
                    const classifier = form.classifiers?.[idClassifier];
                    return {
                        value: classifier?._id,
                        label:
                            classifier?.clientName?.trim?.() && preview
                                ? classifier?.clientName
                                : classifier?.name,
                    };
                });
        },
        idDependencies.map((id) => dependencies[id].value)
    );

    const mapNestedOption = (): void | JSX.Element => {
        if (value) {
            const currentOption = step.options[value.value];
            if (currentOption?.type === ClassifierOptionTypes.NESTED) {
                return (
                    <React.Fragment>
                        {currentOption.steps.map((idStep: string) => {
                            const subStep = form.steps[idStep];
                            return (
                                <StepComponent
                                    step={subStep}
                                    key={idStep}
                                    editable={editable}
                                />
                            );
                        })}
                    </React.Fragment>
                );
            }
        }
    };

    useEffect(() => {
        if (value && !options.find((option) => option.value === value?.value)) {
            onChange('' as any);
        }
    }, [options]);

    return (
        <StepFillerContainer step={step}>
            <MaterialInputContainer step={step} editable={editable}>
                <RoundedSmartSelect
                    {...field}
                    inputRef={ref}
                    value={value}
                    disabled={false}
                    loading={false}
                    options={options}
                    cantEdit={!editable || postview}
                    fullWidth
                    backgroundColor={formStyle.stepBackgroundColor ?? 'white'}
                    label={step.label ? step.label : classifier.name}
                    required={step.required}
                    height={'31px'}
                    searchable={step.searchable}
                    icon={undefined}
                    helperTextColor={formStyle.descriptionTextColor}
                    focusColor={formStyle.primaryColor}
                    outlineColor={formStyle.outlineColor}
                    errorColor={formStyle.errorColor}
                    color={formStyle.textColor}
                    containerMargin="0px"
                    handleUpdate={onChange}
                    getOptionSelected={(option, value): boolean => {
                        return option.value === value?.value;
                    }}
                    helperText={error?.message?.toString() ?? step.description}
                    error={!!error}
                />
            </MaterialInputContainer>
            {mapNestedOption()}
        </StepFillerContainer>
    );
}

export default ClassifierSelectorStep;
