import { SVGProps } from 'react';

const SupplierIcon = (props: SVGProps<SVGSVGElement>): any => (
    <svg
        {...props}
        fill={props.fill ?? '#757575'}
        width="512"
        height="512"
        version="1.1"
        viewBox="0 0 160 160"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path d="M146.227,105.699l-27.514,5.464l-0.974-4.922l-1.858-9.362l-14.284,2.843l1.858,9.362l0.974,4.922l-7.976,1.587   c-0.743-1.627-1.627-3.164-2.622-4.601c-4.37-6.298-11.009-10.738-18.493-12.366c-1.708-0.372-3.466-0.593-5.254-0.663   l-9.884-49.784c-0.271-1.356,0.613-2.682,1.969-2.943l27.514-5.465l0.974,4.922l1.868,9.362l14.284-2.843l-1.858-9.362   l-0.974-4.922l27.504-5.465c1.366-0.271,2.682,0.613,2.953,1.979l13.772,69.301C148.477,104.112,147.593,105.428,146.227,105.699z" />
            <path d="M149.741,108.588l-51.994,10.327c0.356,1.106,0.66,2.24,0.891,3.407c0.232,1.167,0.385,2.331,0.478,3.489l51.994-10.327   c1.905-0.378,3.142-2.229,2.764-4.133C153.496,109.447,151.645,108.209,149.741,108.588z" />
            <path d="M32.325,7.401c-0.554-1.651-2.248-2.682-4.002-2.333c-1.158,0.23-2.062,1.005-2.509,2.005   c-0.508,1.137-1.618,1.89-2.84,2.133L8.982,11.985c-1.646,0.327-2.918,1.739-2.924,3.417c-0.008,2.308,2.064,3.923,4.203,3.498   l14.083-2.797c1.221-0.243,2.535,0.029,3.439,0.886c0.795,0.753,1.927,1.124,3.085,0.894c1.718-0.341,2.881-1.882,2.817-3.579   c4.769-0.432,9.207,2.805,10.161,7.605l15.416,77.618c1.219-0.41,2.473-0.755,3.768-1.012c1.039-0.206,2.077-0.356,3.11-0.452   L50.743,20.538C49.035,11.938,40.899,6.239,32.325,7.401z" />
            <path d="M63.715,101.963c-14.503,2.88-23.925,16.973-21.044,31.475c2.88,14.503,16.972,23.925,31.475,21.044   c14.503-2.881,23.925-16.973,21.044-31.475S78.218,99.082,63.715,101.963z M70.914,138.208c-5.506,1.094-10.876-2.496-11.969-8.003   c-1.094-5.506,2.496-10.876,8.003-11.969c5.506-1.094,10.876,2.496,11.969,8.003C80.01,131.745,76.42,137.115,70.914,138.208z" />
        </g>
    </svg>
);
export default SupplierIcon;
