import { FormSize } from '../@Types/Form';
import {
    ApiSelector,
    CheckBox,
    ClassifierSelector,
    EntityValuePicker,
    FormSelector,
    FormStep,
} from '../@Types/FormStep';
import FormStepTypes, {
    ApiSelectorOptionTypes,
    ClassifierOptionTypes,
    EntityValueDataTypes,
    EntityValueOptionTypes,
    OptionTypes,
} from '../constants/FormStepTypes';
import CBRFormStepTypes from '../constants/CBRFormStepTypes';
import { AYFCityStep, AYFFormStep } from '../@Types/AYFFormStep';
import { CBRElementStep, CBRFormStep } from '../@Types/CBRFormStep';
import AYFFormStepTypes from '../constants/AYFFormStepTypes';
import { getRawText } from '../Utils/DraftFunctions';
import {
    Condition,
    EntityPropertyCondition,
    EntityPropertyExistsCondition,
    EntityTimeCondition,
    EntityValueCondition,
    ExistanceFormStepCondition,
    FormStepCondition,
    TimePickerFormStepCondition,
} from '../@Types/Condition';
import ConditionTypes, {
    ExpressionTypes,
    OperatorTypes,
} from '../constants/ConditionTypes';
import { DependencyStore } from '../Form/Form';
import { Entity } from '../@Types/Entity';
import EntityPropertyTypes from '../constants/EntityPropertyTypes';
import { Time } from '../@Types/Time';

export type FillerSteps =
    | FormSelector
    | ClassifierSelector
    | EntityValuePicker
    | ApiSelector
    | CheckBox;

export function calcFillerSize(
    step: FillerSteps,
    steps: Record<string, FormStep>,
    values: Record<string, unknown>,
    size: FormSize
): number {
    const maxSize = step.maxSize ?? size.blockNum;
    if (maxSize < size.blockNum) {
        const tsize = recursivelyCheckOpenSize(step.id, steps, values);
        const stepSize = maxSize - tsize;
        return (
            size.blockSize * stepSize + size.spacingSize * (stepSize - 1) + 20
        );
    } else {
        return 0;
    }
}

function recursivelyCheckOpenSize(
    idStep: string,
    steps: Record<string, FormStep>,
    values: Record<string, any>
): number {
    const step = steps[idStep];
    const value = values[idStep];

    switch (step.type) {
        case FormStepTypes.SELECTOR: {
            let size = step.size;
            if (value) {
                const currentOption = step.options.find(
                    (option) => option.value === value.value
                );
                if (currentOption?.type === OptionTypes.NESTED) {
                    let optionSize = 0;
                    for (const pStepId of currentOption.steps) {
                        const temp = recursivelyCheckOpenSize(
                            pStepId,
                            steps,
                            values
                        );
                        optionSize += temp;
                    }
                    size += optionSize;
                }
            }
            return size;
        }
        case FormStepTypes.CLASSIFIER_SELECTOR: {
            let size = step.size;
            if (value) {
                const currentOption = step.options[value];
                if (currentOption?.type === ClassifierOptionTypes.NESTED) {
                    let optionSize = 0;
                    for (const pStepId of currentOption.steps) {
                        optionSize += recursivelyCheckOpenSize(
                            pStepId,
                            steps,
                            values
                        );
                    }
                    size += optionSize;
                }
            }
            return size;
        }
        case FormStepTypes.ENTITYVALUEPICKER: {
            let size = step.size;
            if (value) {
                const currentOption = step.options[value];
                if (currentOption?.type === EntityValueOptionTypes.NESTED) {
                    let optionSize = 0;
                    for (const pStepId of currentOption.steps) {
                        optionSize += recursivelyCheckOpenSize(
                            pStepId,
                            steps,
                            values
                        );
                    }
                    size += optionSize;
                }
            }
            return size;
        }
        case FormStepTypes.API_SELECTOR: {
            let size = step.size;
            if (value) {
                const currentOption = step.options[value];
                if (currentOption?.type === ApiSelectorOptionTypes.NESTED) {
                    let optionSize = 0;
                    for (const pStepId of currentOption.steps) {
                        optionSize += recursivelyCheckOpenSize(
                            pStepId,
                            steps,
                            values
                        );
                    }
                    size += optionSize;
                }
            }
            return size;
        }
        case FormStepTypes.CHECKBOX: {
            let size = step.size;
            const stepSteps = value ? step.steps : step.uncheckedSteps;
            let optionSize = 0;
            for (const pStepId of stepSteps ?? []) {
                optionSize += recursivelyCheckOpenSize(pStepId, steps, values);
            }
            size += optionSize;
            return size;
        }
        case FormStepTypes.DATEPICKER:
        case FormStepTypes.TEXTINPUT: {
            return step.size;
        }
        default:
            return 4;
    }
}

export const calcStepWidth = (
    stepSize: 1 | 2 | 3 | 4,
    size: FormSize
): number => {
    return size.blockSize * stepSize + size.spacingSize * (stepSize - 1);
};

export const calcDefaultValue = (step: FormStep | CBRFormStep): any => {
    switch (step.type) {
        case FormStepTypes.CHECKBOX:
            return step.defaultValue ?? false;
        case FormStepTypes.TITLE:
            return undefined;
        case FormStepTypes.TIMEPICKER:
            return step.defaultValue;
        case FormStepTypes.SELECTOR:
            if (step.defaultValue)
                return (
                    step.options.find(
                        (option) => option.value === step.defaultValue
                    ) ?? ''
                );
            return '';
        case FormStepTypes.TEXTINPUT:
        case FormStepTypes.CLASSIFIER_SELECTOR:
            return '';
        case FormStepTypes.TEXTAREA:
            if (step.hasTextEditor) return getRawText();
            else return '';
        case FormStepTypes.RATING:
        case FormStepTypes.DATEPICKER:
        case FormStepTypes.API_SELECTOR:
        case FormStepTypes.ENTITYVALUEPICKER:
            return null;
        case FormStepTypes.MAPPER:
            return { elements: [], page: 0 };
        case FormStepTypes.FILEUPLOAD:
        case CBRFormStepTypes.CBR_INCIDENCIAS:
            return [];
        case FormStepTypes.COLLAPSIBLE:
            return false;
        case FormStepTypes.SEPARATOR:
        default:
            return undefined;
    }
};

export const iterateNestedSteps = (
    idStep: string,
    steps: Record<string, FormStep>,
    iteration: (step: FormStep) => void
): void => {
    const step = steps[idStep];
    if (!step) {
        console.error('Missing Step:', idStep);
        return;
    }
    iteration(step);
    if (step.type === FormStepTypes.MAPPER) {
        for (const idStep of step.rootSteps) {
            iterateNestedSteps(idStep, step.steps, iteration);
        }
    } else {
        for (const idSubStep of calcSubSteps(step.id, steps)) {
            iterateNestedSteps(idSubStep, steps, iteration);
        }
    }
};

/**
 * Utility function to calc the substeps of a step
 * @param step step to calc the substeps
 * @param idModifier optional modifier for the ids of the substeps and all other properties
 * @returns list of the ids of the substeps of the step, if modifier returns modified steps
 */
export const calcSubSteps = (
    idStep: string,
    allSteps: Record<string, FormStep | CBRFormStep | AYFFormStep>,
    idModifier?: (idSubStep: string) => string
): string[] => {
    const step = allSteps[idStep];
    const subSteps: string[] = [];
    if (!step) return [];
    const handleSteps = (steps: string[]): void => {
        for (let i = 0; i < steps.length; i++) {
            const idSubStep = steps[i];
            if (idModifier) steps[i] = idModifier(steps[i]);
            subSteps.push(
                idSubStep,
                ...calcSubSteps(idSubStep, allSteps, idModifier)
            );
        }
    };

    switch (step.type) {
        case FormStepTypes.SELECTOR: {
            for (const option of step.options) {
                if (option.type === OptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        }
        case FormStepTypes.CLASSIFIER_SELECTOR:
            for (const idOption of Object.keys(step.options)) {
                const option = step.options[idOption];
                if (option.type === ClassifierOptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        case FormStepTypes.RATING:
            if (step.nestedSteps) {
                for (const steps of step.nestedSteps) {
                    handleSteps(steps);
                }
            }
            break;
        case FormStepTypes.COLLAPSIBLE: {
            handleSteps(step.steps);
            break;
        }
        case FormStepTypes.CHECKBOX: {
            if (step.steps) handleSteps(step.steps);
            if (step.uncheckedSteps) handleSteps(step.uncheckedSteps);
            break;
        }
        case FormStepTypes.ENTITYVALUEPICKER:
            if (idModifier) {
                for (const path of step.path) {
                    if (path.type === EntityValueDataTypes.STEP) {
                        path.idStep = idModifier(path.idStep);
                    }
                }
                for (const filter of step.filters) {
                    if (filter.type === EntityValueDataTypes.STEP) {
                        filter.idStep = idModifier(filter.idStep);
                    }
                }
            }
            for (const idOption of Object.keys(step.options ?? {})) {
                const option = step.options[idOption];
                if (option.type === EntityValueOptionTypes.NESTED) {
                    handleSteps(option.steps);
                }
            }
            break;
        case CBRFormStepTypes.CBR_LOCATIVAS as any:
            {
                const elementStep: CBRElementStep = step as any;
                if (elementStep.subStep) {
                    if (idModifier)
                        elementStep.subStep = idModifier(elementStep.subStep);
                    subSteps.push(elementStep.subStep);
                }
            }
            break;
        case AYFFormStepTypes.AYF_ICA_CITY as any: {
            const cityStep: AYFCityStep = step as any;
            if (cityStep.idNitStep) {
                if (idModifier)
                    cityStep.idNitStep = idModifier(cityStep.idNitStep);
                subSteps.push(cityStep.idNitStep);
            }
            break;
        }
        default:
            break;
    }
    return subSteps;
};

type BaseEntityValue = {
    [key: string]: any;
};

interface EntityValue extends BaseEntityValue {
    _id: string;
    value: string;
}

export const evaluateCondition = (
    condition: Condition,
    dependencies: DependencyStore,
    entityValue?: {
        entity?: Entity;
        entityValue: {
            _id: string;
            value: string;
        } & { [key: string]: any };
    }
): boolean => {
    switch (condition.type) {
        case ConditionTypes.EXPRESSION: {
            if (condition.expression === ExpressionTypes.OR) {
                for (const subCondition of condition.conditions) {
                    if (evaluateCondition(subCondition, dependencies))
                        return true;
                }
                return false;
            } else {
                for (const subCondition of condition.conditions) {
                    if (!evaluateCondition(subCondition, dependencies))
                        return false;
                }
                return condition.conditions.length > 0;
            }
        }
        case ConditionTypes.FORM_STEP: {
            if (isFormStepExistance(condition)) {
                const boolFactor = condition.operator === OperatorTypes.EXISTS;
                const value = dependencies[condition.idStep].value;
                if (value) return boolFactor;
                return !boolFactor;
            }
            const dependency = dependencies[condition.idStep];
            const value = dependencies[condition.idStep].value;
            switch (condition.stepType) {
                // case FormStepTypes.AGENTPICKER:
                //     return await evaluateAgentCondition(
                //         condition,
                //         value as string[]
                //     );
                case FormStepTypes.CHECKBOX: {
                    const boolFactor =
                        condition.operator === OperatorTypes.EQUAL;
                    return value === condition.value ? boolFactor : !boolFactor;
                }
                case FormStepTypes.TIMEPICKER: {
                    const boolFactor =
                        condition.operator === OperatorTypes.INCLUDES;
                    if (!value) return !boolFactor;
                    const evaluateCondition = (
                        condition: TimePickerFormStepCondition
                    ): boolean => {
                        const property = condition.property as keyof Time;
                        if (property === 'working') {
                            const boolFactor =
                                condition.propertyOperator ===
                                OperatorTypes.EQUAL;
                            if (value.working === condition.value) {
                                return boolFactor;
                            }
                            return !boolFactor;
                        } else {
                            const time = value as Time;
                            switch (condition.propertyOperator) {
                                case OperatorTypes.EQUAL: {
                                    return time[property] === condition.value;
                                }
                                case OperatorTypes.NOTEQUAL: {
                                    return time[property] !== condition.value;
                                }
                                case OperatorTypes.LESS: {
                                    const propValue = time[property];
                                    if (!propValue) return false;
                                    return propValue < condition.value;
                                }
                                case OperatorTypes.MORE: {
                                    const propValue = time[property];
                                    if (!propValue) return false;
                                    return propValue > condition.value;
                                }
                                default: {
                                    return false;
                                }
                            }
                        }
                    };
                    return evaluateCondition(condition)
                        ? boolFactor
                        : !boolFactor;
                }
                case FormStepTypes.DATEPICKER: {
                    if (!(value instanceof Date) || isNaN(value.getTime())) {
                        return condition.operator === OperatorTypes.NOTEQUAL;
                    }
                    const propertyDate = new Date(condition.value);
                    switch (condition.operator) {
                        case OperatorTypes.EQUAL:
                            return (
                                value.toUTCString().split('T')[0] ===
                                propertyDate.toUTCString().split('T')[0]
                            );
                        case OperatorTypes.NOTEQUAL:
                            return (
                                value.toUTCString().split('T')[0] !==
                                propertyDate.toUTCString().split('T')[0]
                            );
                        case OperatorTypes.LESS:
                            return value.getTime() < propertyDate.getTime();
                        case OperatorTypes.MORE:
                            return value.getTime() > propertyDate.getTime();
                        default:
                            return false;
                    }
                }
                case FormStepTypes.SELECTOR: {
                    const val =
                        dependency.type === 'ORIGINAL'
                            ? value
                            : value?.value ?? null;
                    if (condition.operator === OperatorTypes.EQUAL) {
                        return condition.value === val;
                    } else {
                        return !(condition.value === val);
                    }
                }
                case FormStepTypes.TEXTINPUT: {
                    if (condition.operator === OperatorTypes.INCLUDES) {
                        return value
                            ?.toLowerCase()
                            ?.includes(condition.value.toLowerCase());
                    } else if (
                        condition.operator === OperatorTypes.NOTINCLUDES
                    ) {
                        return !value
                            ?.toLowerCase()
                            ?.includes(condition.value.toLowerCase());
                    } else if (condition.operator === OperatorTypes.EQUAL) {
                        return (
                            condition.value.toLowerCase() ===
                            value?.toLowerCase()
                        );
                    } else if (condition.operator === OperatorTypes.NOTEQUAL) {
                        return !(
                            condition.value.toLowerCase() ===
                            value?.toLowerCase()
                        );
                    } else {
                        return false;
                    }
                }
                case FormStepTypes.TEXTAREA: {
                    const words = condition.values;
                    let regx = `\\b(${words[0].toLowerCase()}`;
                    for (let i = 1; i < words.length; i++) {
                        regx += `|${words[i].toLowerCase()}`;
                    }
                    regx += ')\\b';
                    if (condition.operator === OperatorTypes.INCLUDES) {
                        return (
                            value && new RegExp(regx).test(value.toLowerCase())
                        );
                    } else {
                        return (
                            !value ||
                            !new RegExp(regx).test(value.toLowerCase())
                        );
                    }
                }
                case FormStepTypes.RATING: {
                    if (!value || isNaN(value)) {
                        return condition.operator === OperatorTypes.NOTEQUAL;
                    }
                    switch (condition.operator) {
                        case OperatorTypes.EQUAL:
                        case OperatorTypes.NOTEQUAL: {
                            const boolFactor =
                                condition.operator === OperatorTypes.EQUAL;
                            if (value === condition.value) return boolFactor;

                            return !boolFactor;
                        }
                        case OperatorTypes.LESS:
                            return value < condition.value;
                        case OperatorTypes.MORE:
                            return value > condition.value;
                        default:
                            return false;
                    }
                }
                case FormStepTypes.CLASSIFIER_SELECTOR: {
                    const boolFactor =
                        condition.operator === OperatorTypes.EQUAL;
                    if (value?.value === condition.idValue) {
                        //Si op es EQUALS (boolfactor true) evaluará a true
                        //Si op es NOTEQUALS (boolfactor false) evaluará a false
                        return boolFactor;
                    }

                    //Si op es EQUALS (boolfactor true) evaluará a false.
                    //Si op es NOTEQUALS (boolfactor false) evaluará a true
                    return !boolFactor;
                }
                case FormStepTypes.ENTITYVALUEPICKER: {
                    const boolFactor =
                        condition.operator === OperatorTypes.EQUAL;
                    for (const idEntityValue of condition.values) {
                        if (value?._id === idEntityValue) {
                            return boolFactor;
                        }
                    }
                    return !boolFactor;
                }
                default:
                    break;
            }
            break;
        }
        case ConditionTypes.ENTITYVALUE: {
            if (!entityValue || !entityValue.entity) return false;
            return evaluateEntityValueCondition(
                condition,
                entityValue.entity,
                entityValue.entityValue
            );
        }
        default:
            return false;
    }
    return false;
};

function isFormStepExistance(
    condition: FormStepCondition
): condition is ExistanceFormStepCondition {
    return (
        condition.operator === OperatorTypes.EXISTS ||
        condition.operator === OperatorTypes.NOTEXISTS
    );
}

const evaluateEntityValueCondition = (
    condition: EntityValueCondition,
    entity: Entity,
    entityValue: EntityValue
): boolean => {
    switch (condition.operator) {
        case OperatorTypes.EQUAL:
        case OperatorTypes.NOTEQUAL: {
            const boolFactor = condition.operator === OperatorTypes.EQUAL;
            for (const value of condition.values) {
                if (value === entityValue._id) {
                    return boolFactor;
                }
            }
            return !boolFactor;
        }
        case OperatorTypes.INCLUDES: {
            return evaluateEntityPropertyCondition(
                condition,
                entity,
                entityValue
            );
        }
        case OperatorTypes.NOTINCLUDES: {
            return !evaluateEntityPropertyCondition(
                condition,
                entity,
                entityValue
            );
        }
        default:
            break;
    }
    return false;
};

const evaluateEntityPropertyCondition = (
    condition: EntityPropertyCondition,
    entity: Entity,
    entityValue: EntityValue
): boolean => {
    if (isEntityPropertyExistance(condition)) {
        const boolFactor = condition.propertyOperator === OperatorTypes.EXISTS;
        const value = entityValue[condition.idProperty];
        if (value !== undefined) {
            return boolFactor;
        }
        return !boolFactor;
    }

    const property = entity.steps[condition.idProperty];
    if (!property || property.type !== condition.propertyType) {
        return false;
    }

    const value = entityValue[condition.idProperty];
    if (value === undefined) return false;

    switch (condition.propertyType) {
        case EntityPropertyTypes.CHECKBOX: {
            const boolFactor =
                condition.propertyOperator === OperatorTypes.EQUAL;
            if (value === condition.propertyValue) {
                return boolFactor;
            }
            return !boolFactor;
        }
        case EntityPropertyTypes.DATEPICKER: {
            const date = new Date(value);
            if (!(date instanceof Date) || isNaN(date.getTime())) {
                return condition.propertyOperator === OperatorTypes.NOTEQUAL;
            }
            switch (condition.propertyOperator) {
                case OperatorTypes.EQUAL: {
                    const propertyDate = new Date(condition.propertyValue);
                    return date.getTime() === propertyDate.getTime();
                }
                case OperatorTypes.NOTEQUAL: {
                    const propertyDate = new Date(condition.propertyValue);
                    return date.getTime() !== propertyDate.getTime();
                }
                case OperatorTypes.LESS: {
                    const propertyDate = new Date(condition.propertyValue);
                    return date.getTime() < propertyDate.getTime();
                }
                case OperatorTypes.MORE: {
                    const propertyDate = new Date(condition.propertyValue);
                    return date.getTime() > propertyDate.getTime();
                }
                case OperatorTypes.PAST_RELATIVE_LESS:
                case OperatorTypes.PAST_RELATIVE_MORE:
                case OperatorTypes.FUTURE_RELATIVE_LESS:
                case OperatorTypes.FUTURE_RELATIVE_MORE: {
                    const date = getRelativeDate(
                        condition.days,
                        condition.hours,
                        condition.minutes,
                        condition.propertyOperator ===
                            OperatorTypes.PAST_RELATIVE_LESS ||
                            condition.propertyOperator ===
                                OperatorTypes.PAST_RELATIVE_MORE
                    );
                    if (
                        condition.propertyOperator ===
                            OperatorTypes.PAST_RELATIVE_LESS ||
                        condition.propertyOperator ===
                            OperatorTypes.FUTURE_RELATIVE_LESS
                    )
                        return new Date().getTime() > date.getTime();
                    else return new Date().getTime() < date.getTime();
                }
                default:
                    false;
            }
            return false;
        }
        case EntityPropertyTypes.TIMEPICKER: {
            const boolFactor =
                condition.propertyOperator === OperatorTypes.INCLUDES;
            const evaluateCondition = (
                condition: EntityTimeCondition
            ): boolean => {
                const timeProperty = condition.property as keyof Time;
                if (timeProperty === 'working') {
                    const boolFactor =
                        condition.timePropertyOperator === OperatorTypes.EQUAL;
                    if (value.working === condition.value) {
                        return boolFactor;
                    }
                    return !boolFactor;
                } else {
                    const time = value as Time;
                    switch (condition.timePropertyOperator) {
                        case OperatorTypes.EQUAL: {
                            return time[timeProperty] === condition.value;
                        }
                        case OperatorTypes.NOTEQUAL: {
                            return time[timeProperty] !== condition.value;
                        }
                        case OperatorTypes.LESS: {
                            const propValue = time[timeProperty];
                            if (!propValue) return false;
                            return propValue < condition.value;
                        }
                        case OperatorTypes.MORE: {
                            const propValue = time[timeProperty];
                            if (!propValue) return false;
                            return propValue > condition.value;
                        }
                        default: {
                            return false;
                        }
                    }
                }
            };
            return evaluateCondition(condition) ? boolFactor : !boolFactor;
        }
        case EntityPropertyTypes.SELECTOR:
        case EntityPropertyTypes.TEXTAREA:
        case EntityPropertyTypes.TEXTINPUT: {
            if (
                condition.propertyOperator === OperatorTypes.INCLUDES ||
                condition.propertyOperator === OperatorTypes.NOTINCLUDES
            ) {
                const words = condition.propertyValues;
                let regx = `\\b(${words[0].toLowerCase()}`;
                for (let i = 1; i < words.length; i++) {
                    regx += `|${words[i].toLowerCase()}`;
                }
                regx += ')\\b';
                if (condition.propertyOperator === OperatorTypes.INCLUDES) {
                    return new RegExp(regx).test(value.toLowerCase());
                } else {
                    return !new RegExp(regx).test(value.toLowerCase());
                }
                /** For Inputs and Selectors */
            } else if (condition.propertyOperator === OperatorTypes.EQUAL) {
                return (
                    condition.propertyValue.toLowerCase() ===
                    value.toLowerCase()
                );
            } else if (condition.propertyOperator === OperatorTypes.NOTEQUAL) {
                return !(
                    condition.propertyValue.toLowerCase() ===
                    value.toLowerCase()
                );
            }
            return false;
        }
        default:
            break;
    }
    return false;
};

function isEntityPropertyExistance(
    condition: EntityPropertyCondition
): condition is EntityPropertyExistsCondition {
    return (
        condition.propertyOperator === OperatorTypes.EXISTS ||
        condition.propertyOperator === OperatorTypes.NOTEXISTS
    );
}

export function getRelativeDate(
    nDays: number = 0,
    nHours = 0,
    nMinutes = 0,
    subtract = false,
    date = new Date()
): Date {
    const millis = nDays * 86400000 + nHours * 3600000 + nMinutes * 60000;
    return new Date(date.getTime() + (subtract ? -millis : millis));
}
