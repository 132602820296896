import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react';
import { MapperStepProps } from '../MapperStep';
import styles from './MaterialMapperStep.module.css';
import { FieldError, useFormContext } from 'react-hook-form';
import { MapperStyleTypes } from '../../../constants/FormStepTypes';
import RoundedButton from '../../../Shared/RoundedButton/RoundedButton';
import { FormStep } from '../../../@Types/FormStep';
import MapperElementComponent from './Element/MapperElementComponent';
import { addStepsDependencies } from '../../../States/SiteSlice';
import FormContext from '../../../Contexts/FormContext';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import CustomContext from '../../../Contexts/CustomContext';
import { useFormStep } from '../../StepHooks';
import { addMapperStep } from '../../../App/AppFunctions';
import { MapperElement } from '../../../@Types/MapperElement';
import { calcMapperSubSteps } from '../../../Form/FormFunctions';
import { Form } from '../../../@Types';

export interface MapperValue<Type> {
    elements: MapperElement<Type>[];
    page: number;
}

export interface MapperComponentProps<Type> extends MapperStepProps<Type> {
    form: Form;
    loading?: boolean;
    error: FieldError | undefined;
    onChange: (value: MapperValue<Type>) => void;
    handleAddElement: (elementValue?: Type) => void;
    value: MapperValue<Type>;
    inputRef: any;
}

function MapperComponent<Type>({
    form,
    step,
    error,
    value,
    loading,
    inputRef,
    editable,
    customAdd,
    onChange,
    handleAddElement,
    ...others
}: MapperComponentProps<Type>): JSX.Element {
    const { page, elements } = value;

    const handleDeleteElement = useCallback(
        (index: number) => () => {
            const tempElements = [...elements];
            tempElements[index] = {
                ...tempElements[index],
                deleted: true,
            };
            let newPage = 0;
            for (let i = page; i >= 0; i--) {
                const element = elements[i];
                if (!element.deleted) {
                    newPage = i;
                    break;
                }
            }
            onChange({
                elements: tempElements,
                page: page === index ? newPage : page,
            });
        },
        [elements, onChange]
    );

    const inputValue = useMemo(() => JSON.stringify(elements), [elements]);
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const { container } = useMemo(() => {
        switch (step.style?.type) {
            case MapperStyleTypes.LIST:
                return { container: styles.listContainer };
            case MapperStyleTypes.PILL:
            default:
                return { container: styles.pillContainer };
        }
    }, [step]);

    const mapElements = (): JSX.Element => {
        if (step.style.type === MapperStyleTypes.PAGED) {
            const element = elements[page];
            if (!element) return <></>;
            return (
                <FormContext.Provider value={form}>
                    <MapperElementComponent
                        {...others}
                        key={element.id}
                        num={page}
                        element={element}
                        step={step}
                        editable={editable}
                        loading={loading}
                        onChange={onChange}
                        value={value}
                        handleDelete={handleDeleteElement(page)}
                    />
                </FormContext.Provider>
            );
        }
        return (
            <FormContext.Provider value={form}>
                {elements.map((element, index) => (
                    <MapperElementComponent
                        {...others}
                        num={index}
                        key={element.id}
                        element={element}
                        step={step}
                        editable={editable}
                        loading={loading}
                        onChange={onChange}
                        value={value}
                        handleDelete={handleDeleteElement(index)}
                    />
                ))}
            </FormContext.Provider>
        );
    };

    if (step.style.type === MapperStyleTypes.INLINE)
        return <React.Fragment>{mapElements()}</React.Fragment>;

    return (
        <div
            className={container}
            style={{ color: formStyle.textColor }}
            data-testid={step.id}
            id={step.id}
        >
            {step.label && <div className={styles.titleLbl}>{step.label}</div>}
            {step.description && (
                <p
                    className={styles.descriptionPar}
                    style={{
                        margin: step.description
                            ? '10px 0px'
                            : '0px 0px 5px 0px',
                    }}
                >
                    {step.description}
                </p>
            )}
            {mapElements()}
            <input
                id={step.id + '-input'}
                ref={inputRef}
                className={'hidden-input'}
                readOnly
                value={inputValue}
            />
            {step.creatable !== false &&
                (!step.max ||
                    elements.filter(
                        (elem: MapperElement<Type>) => !elem.deleted
                    ).length < step.max) && (
                    <div className={styles.btnContainer}>
                        {!customAdd && (
                            <RoundedButton
                                disabled={!editable || postview}
                                text={
                                    step.addBtnLabel +
                                    (step.required ? ' *' : '')
                                }
                                color={formStyle.primaryContrastColor}
                                backgroundColor={formStyle.primaryColor}
                                fontSize={'1rem'}
                                padding={'5px 15px 5px 15px'}
                                onClick={(): void => {
                                    if (editable && !postview) {
                                        handleAddElement();
                                    }
                                }}
                            />
                        )}
                        {customAdd?.(
                            inputRef as any,
                            !editable || postview,
                            handleAddElement
                        )}
                    </div>
                )}
            {!!error &&
                elements.filter((elem) => !elem.deleted).length === 0 && (
                    <div
                        className={styles.errorMsg}
                        style={{ color: formStyle.errorColor }}
                    >
                        Este campo es obligatorio
                    </div>
                )}
        </div>
    );
}

function MapperStep<Type>(props: MapperStepProps<Type>): JSX.Element {
    const { step, editable, customAdd } = props;

    const form = useContext(FormContext);
    const { postview } = useAppSelector((state) => state.global);
    const { customSteps } = useContext(CustomContext);
    const dispatch = useAppDispatch();
    const { setValue } = useFormContext();

    const { ref, value, onChange, error } = useFormStep<MapperValue<Type>>(
        step,
        {
            defaultValue: { elements: [], page: 0 },
            rules: {
                validate: ({ elements }): boolean =>
                    !step.required ||
                    (step.required &&
                        elements.filter(
                            (elem: MapperElement<Type>) => !elem.deleted
                        ).length > 0),
            },
        }
    );
    const { elements } = value;

    const [localSteps, setLocalSteps] = useState<Record<string, FormStep>>(
        calcMapperSubSteps(step, elements, customSteps)
    );

    //Por alguna razón si se saca esto del componente no se ejecuta el agregar inicial
    useEffect(() => {
        if (elements.length === 0 && !postview && editable && step.creatable) {
            if (!customAdd) handleAddElement();
        }
    }, []);

    const handleAddElement = useCallback(
        (elementValue?: any): void => {
            const { element, steps, mappers } = addMapperStep<Type>(
                step,
                customSteps
            );
            if (elementValue) element.value = elementValue;
            for (const [key, elems] of Object.entries(mappers)) {
                setValue(key, elems);
            }
            const newElements = [...elements, element];
            onChange({
                elements: newElements,
                page:
                    step.style.type === MapperStyleTypes.PAGED
                        ? elements.length - 1
                        : 0,
            });
            setLocalSteps((oldLocalSteps) => ({ ...oldLocalSteps, ...steps }));
            dispatch(
                addStepsDependencies({
                    steps,
                    customSteps,
                    allSteps: { ...form.steps, ...steps },
                })
            );
        },
        [step, form, customSteps, value, onChange]
    );

    const subForm = useMemo(
        () => ({ ...form, steps: { ...form.steps, ...localSteps } }),
        [localSteps]
    );

    return (
        <MapperComponent
            {...props}
            error={error}
            inputRef={ref}
            onChange={onChange}
            value={value}
            handleAddElement={handleAddElement}
            form={subForm}
        />
    );
}

export function CustomMapperStep<Type>(
    props: Omit<MapperComponentProps<Type>, 'form' | 'handleAddElement'>
): JSX.Element {
    const { step, value, onChange } = props;
    const { elements } = value;
    const form = useContext(FormContext);
    const { customSteps } = useContext(CustomContext);
    const dispatch = useAppDispatch();
    const { setValue } = useFormContext();

    const [localSteps, setLocalSteps] = useState<Record<string, FormStep>>(
        calcMapperSubSteps(step, elements, customSteps)
    );

    const handleAddElement = useCallback(
        (elementValue?: any): void => {
            const { element, steps, mappers } = addMapperStep<Type>(
                step,
                customSteps
            );
            if (elementValue) element.value = elementValue;
            for (const [key, elems] of Object.entries(mappers)) {
                setValue(key, elems);
            }
            const newElements = [...elements, element];
            onChange({
                elements: newElements,
                page:
                    step.style.type === MapperStyleTypes.PAGED
                        ? elements.length - 1
                        : 0,
            });
            setLocalSteps((oldLocalSteps) => ({ ...oldLocalSteps, ...steps }));
            dispatch(
                addStepsDependencies({
                    steps,
                    customSteps,
                    allSteps: { ...form.steps, ...steps },
                })
            );
        },
        [step, form, customSteps, elements, onChange]
    );

    const subForm = useMemo(
        () => ({ ...form, steps: { ...form.steps, ...localSteps } }),
        [localSteps]
    );

    return (
        <MapperComponent
            {...props}
            form={subForm}
            onChange={onChange}
            handleAddElement={handleAddElement}
        />
    );
}

export default MapperStep;
