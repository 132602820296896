import { createContext, useCallback, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FillerSteps, calcFillerSize } from '../../StepFunctions';
import FormContext from '../../../Contexts/FormContext';
import styles from './StepFiller.module.css';
import { selectBreakPoint, useAppSelector } from '../../../hooks';
import React from 'react';

export const SizeChangeContext = createContext<(() => void) | undefined>(
    undefined
);

export interface StepFillerContainerProps {
    step: FillerSteps;
    children: React.ReactNode;
}

function StepFiller({ step, children }: StepFillerContainerProps): JSX.Element {
    const { getValues } = useFormContext();
    const form = useContext(FormContext);
    const [fillerSize, setFillerSize] = useState(
        calcFillerSize(step, form.steps, getValues(), form.size)
    );
    const currentBreakPoint = useAppSelector(selectBreakPoint);

    const handleSizeChange = useCallback((): void => {
        setFillerSize(calcFillerSize(step, form.steps, getValues(), form.size));
    }, []);

    if (step.maxSize && step.maxSize < form.size.blockNum) {
        return (
            <div
                className={styles.firstLvlContainer}
                style={{
                    width:
                        currentBreakPoint <= step.size ? '100%' : 'fit-content',
                }}
            >
                <SizeChangeContext.Provider value={handleSizeChange}>
                    {children}
                </SizeChangeContext.Provider>
                {fillerSize > 0 && (
                    <div
                        className={styles.smallSeparator}
                        style={{
                            width: fillerSize,
                        }}
                    ></div>
                )}
            </div>
        );
    } else {
        return (
            <SizeChangeContext.Provider value={handleSizeChange}>
                {children}
                {step.maxSize && <div className={styles.separator}></div>}
            </SizeChangeContext.Provider>
        );
    }
}

function StepFillerContainer({
    step,
    children,
}: StepFillerContainerProps): JSX.Element {
    const handleSizeChange = useContext(SizeChangeContext);
    if (handleSizeChange) return <>{children}</>;
    else return <StepFiller step={step} children={children} />;
}

export default StepFillerContainer;
