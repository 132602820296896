import { calcStepWidth } from '../../StepFunctions';
import { TitleStepProps } from '../TitleStep';
import styles from './MaterialTitleStep.module.css';
import { useAppSelector } from '../../../hooks';
import FormContext from '../../../Contexts/FormContext';
import { useContext } from 'react';
import { useFormStep } from '../../StepHooks';
function TitleStep({ step }: TitleStepProps): JSX.Element {
    const { value } = useFormStep<
        | {
              title: string;
              description: string | null;
          }
        | undefined
    >(step, {
        defaultValue: undefined,
    });

    const title = value?.title ?? step.title;
    const description = value?.description ?? step.description;

    const { formStyle } = useAppSelector((state) => state.global);
    const form = useContext(FormContext);
    const widthStats = useAppSelector((state) => state.widthStats);
    const size = step.size ?? form.size.blockNum;
    return (
        <div
            className={styles.container}
            style={{
                color: formStyle.textColor,
                width:
                    widthStats.currentBreakPoint <= size
                        ? '100%'
                        : calcStepWidth(size, form.size),
            }}
        >
            {title && (
                <div
                    className={styles.titleLbl}
                    style={{
                        textAlign:
                            widthStats.isMobile &&
                            widthStats.currentBreakPoint <= size
                                ? 'center'
                                : 'start',
                    }}
                >
                    {title}
                </div>
            )}
            {description && (
                <p
                    className={styles.descriptionPar}
                    style={{
                        margin: title ? '10px 0px' : '0px 0px 5px 0px',
                    }}
                >
                    {description}
                </p>
            )}
        </div>
    );
}
export default TitleStep;
