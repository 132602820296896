import { DatePicker } from '../../@Types/FormStep';
import { FormStyleTypes } from '../../constants/FormStepTypes';
import MaterialDatePickerStep from './MaterialDatePickerStep/MaterialDatePickerStep';
import { StepProps } from '../Step';
import { useAppSelector } from '../../hooks';
export interface DatePickerStepProps extends StepProps {
    /** The DatePickerStep to display */
    step: DatePicker;
    /** Default value to display */
    defaultValue?: Date | null;
}
function DatePickerStep(props: DatePickerStepProps): JSX.Element {
    const { formStyle } = useAppSelector((state) => state.global);

    switch (formStyle.type) {
        case FormStyleTypes.MATERIAL:
        default: {
            return <MaterialDatePickerStep {...props} />;
        }
    }
}
export default DatePickerStep;
