import RoundedTimePicker from '../../../Shared/RoundedTimePicker/RoundedTimePicker';
import { TimePickerStepProps } from '../TimePickerStep';
import { useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';
import MaterialInputContainer from '../../Utils/MaterialInputContainer/MaterialInputContainer';
import { Time } from '../../../@Types/Time';
function TimePickerStep({
    step,
    editable,
    defaultValue,
}: TimePickerStepProps): JSX.Element {
    const { formStyle, postview } = useAppSelector((state) => state.global);
    const { ref, value, onChange, error, field } = useFormStep<
        Time | undefined
    >(step, {
        defaultValue,
        rules: {
            validate: (value: Time | undefined): boolean | string => {
                if (step.required) {
                    if (
                        !value ||
                        !(
                            value.hours ||
                            value.minutes ||
                            value.days ||
                            value.working !== undefined
                        )
                    ) {
                        return 'Este campo es obligatorio';
                    }
                }
                return true;
            },
        },
    });
    return (
        <MaterialInputContainer step={step} editable={editable}>
            <RoundedTimePicker
                {...field}
                value={value}
                onChange={onChange}
                inputRef={ref}
                cantEdit={!editable || postview}
                errorColor={formStyle.errorColor}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                helperTextColor={formStyle.descriptionTextColor}
                backgroundColor={formStyle.stepBackgroundColor}
                fontWeight={400}
                height={'31px'}
                containerMargin="0px"
                label={step.label}
                helperText={error?.message ?? step.description}
                error={!!error}
                required={step.required}
                pickDays={step.pickDays}
                minDays={step.minDays}
                maxDays={step.maxDays}
                pickHours={step.pickHours}
                minHours={step.minHours}
                maxHours={step.maxHours}
                pickMinutes={step.pickMinutes}
                minMinutes={step.minMinutes}
                maxMinutes={step.maxMinutes}
                working={step.working}
            />
        </MaterialInputContainer>
    );
}

export default TimePickerStep;
