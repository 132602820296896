import { SVGProps } from 'react';

function ProjectIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            {...props}
            fill={props.fill ?? '#757575'}
            height="480pt"
            viewBox="0 -8 480 480"
            width="480pt"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m440 448v-49.960938c10.546875-6.214843 16.726562-17.816406 16-30.039062 0-17.671875-10.742188-32-24-32s-24 14.328125-24 32c-.726562 12.222656 5.453125 23.824219 16 30.039062v49.960938h-56v-243.054688l-48-24v43.054688h24v16h-24v32h24v16h-24v32h24v16h-24v32h24v16h-24v32h24v16h-24v16h-16v-321.96875l-96-27.421875v29.390625h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v16h-16v-448h-144v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v16h-48v16h480v-16zm0 0" />
        </svg>
    );
}
export default ProjectIcon;
