import RoundedTextField from '../../../Shared/RoundedTextField/RoundedTextField';
import styles from './MaterialTextAreaStep.module.css';
import { TextAreaStepProps } from '../TextAreaStep';
import React from 'react';
import { useAppSelector } from '../../../hooks';
import { useFormStep } from '../../StepHooks';

export interface Payload {
    text: string;
    draft: any;
}

function TextAreaStep({
    step,
    editable,
    maxLength,
}: TextAreaStepProps): JSX.Element {
    const { formStyle, postview, partial } = useAppSelector(
        (state) => state.global
    );

    const { ref, value, onChange, error, field } = useFormStep<string>(step, {
        debounce: true,
        defaultValue: '',
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    const canEdit = editable && !postview;

    return (
        <div
            className={styles.container}
            style={{
                paddingBottom:
                    step.required || step.description || !!error
                        ? '0px'
                        : '5px',
            }}
        >
            <div className={styles.erkTextArea}>
                <RoundedTextField
                    {...field}
                    inputRef={ref}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    data-testid={step.id}
                    maxLength={maxLength}
                    label={step.label}
                    required={step.required}
                    cantEdit={!canEdit}
                    fontWeight={400}
                    multiline
                    minRows={postview && partial ? undefined : 4}
                    focusColor={formStyle.primaryColor}
                    outlineColor={formStyle.outlineColor}
                    textColor={formStyle.textColor}
                    errorColor={formStyle.errorColor}
                    backgroundColor={formStyle.stepBackgroundColor}
                    helperTextColor={formStyle.descriptionTextColor}
                    helperText={
                        error?.message ??
                        step.description ??
                        (step.required ? ' ' : null)
                    }
                    error={!!error}
                />
            </div>
            {/* Esto se usa para mostrar los payloads en postview! */}
            <div className={styles.print}>
                {value && (
                    <React.Fragment>
                        <div
                            className={styles.printLbl}
                            style={{
                                color: formStyle.descriptionTextColor,
                            }}
                        >
                            {step.label}
                        </div>
                        <div
                            className={styles.printLine}
                            style={{
                                color: formStyle.stepBackgroundColor,
                                backgroundColor: formStyle.stepBackgroundColor,
                            }}
                        >
                            {step.label}
                        </div>
                    </React.Fragment>
                )}
                {!value && (
                    <div
                        className={styles.emptyPrintLbl}
                        style={{
                            color: formStyle.textColor + '8a',
                        }}
                    >
                        {step.label}
                    </div>
                )}
                <p
                    className={styles.printContainer}
                    style={{
                        backgroundColor: formStyle.stepBackgroundColor,
                        borderColor: formStyle.outlineColor,
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {value}
                </p>
            </div>
        </div>
    );
}

export default TextAreaStep;
