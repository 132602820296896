import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loader.module.css';
interface LoaderProps {
    /** The color of the loader */
    color?: string;
    /** If the loader should show a curtain behind it */
    curtain?: boolean;
    /** The zIndex of the loader */
    zIndex?: number;
    /** The size of the loader */
    size?: number;
    /** The position of the loader */
    position?: any;
}
/**
 * Generic Loader Component with optional curtain.
 */
function Loader({
    color = '#3d5a7f',
    curtain = false,
    zIndex = 20,
    size = 40,
    position = 'absolute',
}: LoaderProps): JSX.Element {
    return (
        <div
            className={styles.loaderContainer + ' flexbox'}
            style={{
                backgroundColor: curtain ? 'rgb(0, 0, 0, 0.4)' : '',
                zIndex,
                position,
            }}
        >
            <div className={styles.loader}>
                <CircularProgress size={size} style={{ color }} />
            </div>
        </div>
    );
}
export default Loader;
